<template>
  <div>
    <div class="flex-end mb-1">

      <AddProcedureModal
        v-if="procedureReadOnlyStatus == 0"
        class="mx-1"
        :is-radiology="isRadiology"
        @procedure="openModal"
      />

      &nbsp;
      <b-button
        size="sm"
        variant="primary"
        @click="printProcedurePrescription()"
      ><i
        class="fa fa-print"
        aria-hidden="true"
      />Print</b-button>
    </div>

    <b-table
      :show-empty="true"
      :empty-text="`Items will be shown here`"
      :empty-filtered-text="`No items to show`"
      :fields="['procedure_name', 'date', { key: 'action', label: 'Actions' }]"
      class="table"
      stacked="md"
      small
      bordered
      hover
      :items="encounter.procedure_prescription==null?[]:encounter.procedure_prescription.filter(x => x.custom_is_imaging_service == isRadiology)"
    >
      <template #cell(action)="row">
        <b-button
          size="sm"
          style="width: 100px"
          variant="danger"
          @click="cancelUnsubmittedprocedure(row.item, $event.target)"
        >
          Cancel
        </b-button>
      </template>
    </b-table>
    <strong>
      <b-row style="margin-left:4px ;margin-top: 4px;">
        Total: <p v-if="encounter.procedure_prescription && encounter.procedure_prescription.length > 0">{{ priceInformation
          != null && priceInformation.total != null ?
            priceInformation.total.toFixed(2) : 0 }} KES</p>
      </b-row>
    </strong>
    <h5><strong>SUBMITTED</strong></h5>
    <hr>
    <b-table
      :fields="fields"
      class="table"
      stacked="md"
      small
      striped
      hover
      :items="procedures"
    >
      <template #cell(creation)="row">
        {{ row.item.creation.slice(0, 16) }}
      </template>
      <template #cell(action)="row">
        <b-button
          size="sm"
          style="width: 100px"
          variant="danger"
          @click="openCancelModal(row.item, $event.target)"
        >
          Cancel
        </b-button>
        <b-button
          v-if="!row.item.proceed_without_pay"
          size="sm"
          class="ml-1"
          variant="primary"
          @click="waiverDialog(row.item.name)"
        >
          Waive deposit
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>

import {
  getOrderedItemPrices, addAProcedure, getProcedures, createPatientEncounter, getProcedureReadOnlyStatus, cancelUnsubmittedProcedure as cancelUnsubmittedProcedureApi, cancelClinicalProcedure,
} from './service'
import AddProcedureModal from './AddProcedureModal.vue'

export default {
  name: 'Procedures',
  components: { AddProcedureModal },
  props: {
    patient: {
      default: '',
      type: String,
      required: true,
    },
    isRadiology: {
      default: false,
    },
    walkin: {
      type: Number,
      default: 0,
      required: false,
    },
    currentEncounter: {
      type: Object,
      default: {},
      required: true,
    },
    serviceUnit: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      fields: ['name', 'procedure_name', 'creation', 'prescribed_by', { key: 'action', label: 'Actions' }],
      procedures: [],
      procedureReadOnlyStatus: '',
      priceInformation: {},
      encounter: {},
    }
  },
  watch: {
    patient() {
      getProcedures(this.patient, this.isRadiology).then(procedures => {
        this.procedures = procedures
      })
      this.getItemPrices()
    },
    encounter() {
      getProcedures(this.patient, this.isRadiology).then(procedures => {
        this.procedures = procedures
      })
      this.getItemPrices()
    },
    currentEncounter(val) {
      this.encounter = val
    },

  },
  mounted() {
    this.encounter = this.currentEncounter
    getProcedures(this.patient).then(procedures => {
      this.procedures = procedures
    })
    getProcedureReadOnlyStatus().then(status => {
      this.procedureReadOnlyStatus = status
    })
  },
  methods: {
    getItemPrices() {
      if (this.encounter && this.encounter.procedure_prescription) {
        const item_code_list = this.encounter.procedure_prescription.map(item => this.docGetValue('Clinical Procedure Template', item.procedure_name, 'item').then(item => ({ item_code: item, qty: 1 })))
        Promise.all(item_code_list)
          .then(lst => {
            const lst2 = lst.map(m => ({ item_code: m.item_code.message.item, qty: 1 }))
            getOrderedItemPrices({ item_code_list: lst2, patient_name: this.patient, service_unit: '' }).then(values => {
              this.priceInformation = values
            })
          })
      }
    },
    printProcedurePrescription() {
      //  this.getItemPrices();
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
        + 'doctype='}${encodeURIComponent('Patient Encounter')
      }&name=${encodeURIComponent(this.encounter.name)
      }&format=Patient Encounter Procedures&_lang=en&trigger_print=1`))
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    waiverDialog(procedure) {
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete everything.', {
        title: 'Are you sure you want to waive deposit requirement',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.api({
              method: 'clinical.api.clinical_procedure.clinical_procedure.waive_clinical_procedure',
              args: {
                procedure,
              },
            }).then(result => this.show_alert('Deposit successfully removed'))
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    openModal(vals) {
      this.getItemPrices()
      const serviceName = this.isRadiology ? 'Radiology' : 'Clinical Procedure'
      const currentDate = new Date()

      // Format time as HH:mm:ss
      const hours = String(currentDate.getHours()).padStart(2, '0')
      const minutes = String(currentDate.getMinutes()).padStart(2, '0')
      const seconds = String(currentDate.getSeconds()).padStart(2, '0')
      const time = `${hours}:${minutes}:${seconds}`

      // Format date as YYYY-MM-DD
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based
      const day = String(currentDate.getDate()).padStart(2, '0')
      const date = `${year}-${month}-${day}`

      const values = { ...vals, date, time }
      values.barcode = serviceName

      if (!this.encounter.name) {
        createPatientEncounter(this.patient, this.walkin).then(val => {
          addAProcedure({ ...values, patient_name: this.patient, encounter: val.name }, this.serviceUnit, this.walkin).then(encounter => {
            console.log('Encounter', encounter)
            this.encounter = encounter
            this.$emit('encounterUpdated', encounter)
          })
        })
      } else {
        addAProcedure({ ...values, patient_name: this.patient, encounter: this.encounter.name }, this.serviceUnit, this.walkin).then(
          encounter => {
            this.$emit('encounterUpdated', encounter)
            this.getItemPrices()
          },
        )
      }
    },
    openCancelModal(item, event) {
      this.show_alert('Are you sure you want to cancel this procedure?',
        () => {
          this.cancel(item, event)
        }, () => { })
    },
    cancelUnsubmittedprocedure(item) {
      cancelUnsubmittedProcedureApi(this.encounter.name, item.name)
        .then(encounter => {
          this.$emit('encounterUpdated', encounter)
        })
    },
    cancel(item, event) {
      cancelClinicalProcedure(item.name)
        .then(() => {
          getProcedures(this.patient).then(procedures => {
            this.procedures = procedures
          })
        })
    },
  },
}
</script>
<style scoped>
.flex-end {
  display: flex;
  justify-content: flex-end;
}

svg {
  width: 14px !important;
}</style>
