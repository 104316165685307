<template>
  <div>
    <b-button
      block
      variant="success btn-sm"
      @click="openModal(contextEncounter, $event.target)"
    >
      Sign Off
    </b-button>

    <b-modal
      :id="infoModal.id"
      :ok-disabled="!validPf || loading"
      :title="infoModal.title"
      @ok="submitEncounterAction()"
      @hide="resetInfoModal"
    >
      <template #modal-title>
        <h3>Submit Patient Encounter</h3>
      </template>
      <strong>Encounter</strong>
      <pre>{{ contextEncounter.name }}</pre>
      <strong>Confirm you are submitting for patient;</strong>
      <h3 style="color:green;text-transform: uppercase;">
        {{ patient.patient_name }}
      </h3>
      <label for="pf-number-submit">Enter your PF number and Submit</label>
      <b-form-input
        id="pf-number-submit"
        v-model="text"
        :state="validPf"
        class="error"
        placeholder="Enter your PF Number"
        @blur="blur()"
        @focus="focus()"
      />
      <div
        v-if="
          contextServiceUnit &&
            contextServiceUnit.service_unit_details.bill_for_every_encounter
        "
        class="to-bill"
      >
        <label
          style="margin-top: 8px"
          for="to-bill"
        >
          <input
            id="to-bill"
            v-model="toBill"
            type="checkbox"
            @change="billEncounterOnclick(this)"
          >
          <span class="label-area">Bill patient for this encounter</span>
        </label>
      </div>

      <div
        v-if="toBill"
        style="border: 1px solid grey;border-radius: 10px;padding: 15px;margin-bottom:5px;"
      >
        <label for="pf-number-submit">Billing Details: (Change amount if appropriate)</label>
        <b-form-input
          id="pf-number-submitt"
          v-model="encounterBillingItemPrice"
          placeholder=""
        />
        <label>Item to be billed: {{ encounterBillingItem }}</label>
      </div>

      <i>All Orders, prescriptions and notes made in this encounter will be
        submitted</i>
      <template #modal-ok>
        Submit
      </template>
    </b-modal>
  </div>
</template>

<script>
import APP_CONTEXT_MIXIN from '@/views/erp/mixins/app-context.mixin.js'
import {
  submitEncounter,
  validatePFNumber,
  getEncounterItemAndPrice,
} from './service'

export default {
  name: 'SignOff',
  mixins: [APP_CONTEXT_MIXIN],
  props: {
    unSubmittedProcedures: { type: Array, default: [], required: false },
    medicationOrder: { type: Object, default: {}, required: false },
    patient: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      infoModal: {
        id: 'info-modal-submit',
        title: '',
        data: {},
        content: {},
      },
      text: '',
      toBill: false,
      validPf: false,
      closeEncounter: false,
      encounter: {},
      loading: false,
      encounterBillingItem: '',
      encounterBillingItemPrice: 0,
    }
  },
  created() {},

  methods: {
    billEncounterOnclick() {
      if (this.toBill) {
        getEncounterItemAndPrice(this.contextEncounter.name, this.contextServiceUnit.service_unit_details.name).then(res => {
          this.encounterBillingItem = res.item_code
          this.encounterBillingItemPrice = res.price
        })
      } else {
        this.encounterBillingItem = ''
        this.encounterBillingItemPrice = 0
      }
    },
    submitEncounterAction(encounter = this.contextEncounter) {
      this.loading = true
      this.$emit('sign-off')
      console.log('========', encounter.patient)
      console.log('=======', this.patient)
      // this.unSubmittedProcedures.forEach(({ name }) => {
      //   console.log("syee",name)
      //   api({
      //     method: "clinical.api.patient_encounter.patient_encounter.submit",
      //     args: {
      //       is_emergency: this.contextPatient.is_emergency,
      //       name,
      //       doctype: "Clinical Procedure",
      //       service_unit: this.contextServiceUnit,
      //     },
      //   }).then((encounter) => {
      //     this.updateEncounter({});
      //     this.contextUpdateEncounter({});
      //   });
      // });
      // submitLabPrescriptions({
      //   service_unit: this.contextServiceUnit.service_unit,
      //   admission_status: this.contextPatient.status,
      //   encounter_name: this.contextEncounter.name,
      // }).then((data) => {});

      // if (encounter.name) {
      // name="", patient="", service_unit = {}, to_bill = true, origin, isWalkIn
      const toBill = this.contextServiceUnit.service_unit_details
        .bill_for_every_encounter
        ? this.toBill
        : false
        // console.log('========', encounter.patient)
        // console.log('=======',this.patient)

      submitEncounter(
        encounter.name || '',
        this.patient.patient || '',
        { service_unit: this.contextServiceUnit.service_unit },
        toBill,
      ).then(res => {
        // console.log("Oh what a night",this.patient.patient)
        this.$emit('encounterUpdated', res)
        this.resetInfoModal()
        this.updateEncounter({})
        this.msgprint(
          {
            message: ('Successfully signed off'),
            indicator: 'green',
          },
          5,
        )
      })
      // submitDoctorsNotes(encounter.name).then((res) => this.resetInfoModal());
      // }

      // if (this.medicationOrder.name) {
      //   submitMedicationOrder(this.medicationOrder.name).then((res) => {
      //     this.$emit("medicationOrderUpdated", {});
      //     this.resetInfoModal();
      //   });
      // }
      this.loading = false
    },
    openModal(item, button) {
      this.infoModal.title = 'Submit Patient Encounter'
      this.infoModal.content = item
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    blur() {
      validatePFNumber(this.text).then(value => {
        this.validPf = value
      })
    },
    focus() {
      validatePFNumber(this.text).then(value => {
        this.validPf = false
      })
    },
    updateEncounter(val) {
      this.contextUpdateEncounter(val)
    },
    resetInfoModal() {
      this.infoModal.content = {}
    },
  },
}
</script>

<style></style>
