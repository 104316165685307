<template>
  <b-container>

    <b-card class="prescriptions mb-1">
      <b-card-header header-tag="header" class="header" role="tab">
        <div v-b-toggle.accordion-2 class="head-toggle w-100">
          <b-row align-h="between" class="w-100" align-v="center">
            <h5 style="margin: 0">
              Prescriptions
            </h5>
            <span><i class="toggler  fa" :class="collapsed2 ? iconUp : iconDown" aria-hidden="true" />
            </span>
          </b-row>
        </div>
      </b-card-header>
      <b-collapse id="accordion-2" v-model="collapsed3" class="mt-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <encounter-medication :walkin="walkin" :encounter="contextEncounter || {}" :patient="patient"
            :is_inpatient="selectedPatient.is_inpatient" @encounterUpdated="encounterUpdated" />
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card class="prescriptions mb-2">
      <b-card-header header-tag="header" class="p-1 header" role="tab">
        <div v-b-toggle.accordion-3 class="head-toggle w-100">
          <b-row class="w-100" align-h="between" align-v="center">
            <h5 style="margin: 0">
              Procedures
            </h5>
            <span><i class="toggler  fa" :class="collapsed2 ? iconUp : iconDown" aria-hidden="true" /> </span>
          </b-row>
        </div>
      </b-card-header>
      <b-collapse id="accordion-3" v-model="collapsed2" class="mt-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <Procedures :walkin="walkin" :service-unit="serviceUnit" :current-encounter="contextEncounter || {}"
            :patient="patient" @encounterUpdated="encounterUpdated" />
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card class="mb-2">
      <b-card-header header-tag="header" class="p-1 header" role="tab">
        <div v-b-toggle.accordion-1 class="head-toggle w-100">
          <b-row class="w-100" align-h="between" align-v="center">
            <h5 style="margin: 0">
              Radiology
            </h5>
            <span><i class="toggler pull-right ml-4 fa" :class="collapsed ? iconUp : iconDown" aria-hidden="true" />
            </span>
          </b-row>
        </div>
      </b-card-header>
      <b-collapse id="accordion-1" v-model="collapsed" class="mt-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <Procedures :walkin="walkin" :is-radiology="1" :service-unit="serviceUnit"
            :current-encounter="contextEncounter || {}" :patient="patient" @encounterUpdated="encounterUpdated" />
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card class="lab_prescriptions mb-2">
      <b-card-header header-tag="header" class="p-1 header" role="tab">
        <div v-b-toggle.accordion-5 class="head-toggle w-100">
          <b-row class="w-100" align-h="between" align-v="center">
            <h5 style="margin: 0">
              Lab Orders
            </h5>
            <span><i class="toggler pull-right ml-4 fa" :class="collapsed5 ? iconUp : iconDown" aria-hidden="true" />
            </span>
          </b-row>
        </div>
      </b-card-header>
      <b-collapse id="accordion-5" v-model="collapsed5" class="mt-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <lab-prescription v-if="!!patient" :service-unit="serviceUnit" :encounter="contextEncounter || {}"
            :patient="patient" :walkin="walkin" @encounterUpdated="encounterUpdated" />
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card class="lab_prescriptions mb-2">
      <b-card-header header-tag="header" class="p-1 header" role="tab">
        <div v-b-toggle.accordion-6 class="head-toggle w-100">
          <b-row align-h="between" class="w-100" align-v="center">
            <h5 style="margin: 0">
              Charge Sheet
            </h5>
            <span><i class="toggler pull-right ml-4 fa" :class="collapsed6 ? iconUp : iconDown" aria-hidden="true" />
            </span>
          </b-row>
        </div>
      </b-card-header>
      <b-collapse id="accordion-6" v-model="collapsed6" class="mt-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <charge-sheet :service-unit="serviceUnit" :encounter="contextEncounter || {}" :patient="patient" :approval="0"
            :walkin="walkin" @encounterUpdated="encounterUpdated" />
        </b-card-body>
      </b-collapse>
    </b-card>

  </b-container>
</template>
<script>
import ChargeSheet from '@/views/erp/billing/components/chargesheet/ChargeSheet.vue'
import APP_CONTEXT_MIXIN from '@/views/erp/mixins/app-context.mixin.js'
import MedicationOrders from './components/MedicationOrders.vue'
import EncounterMedication from './components/EncounterMedication.vue'
import Procedures from './components/RadiologyAndProcedures.vue'
import LabPrescription from './components/LabPrescription.vue'

export default {
  name: 'Orders',
  components: {
    MedicationOrders,
    EncounterMedication,
    Procedures,
    LabPrescription,
    ChargeSheet,
  },
  mixins: [APP_CONTEXT_MIXIN],
  props: {
    patient: {
      type: String,
      default: '',
      required: true,
    },
    walkin: {
      type: Number,
      default: 0,
      required: false,
    },
    encounter: {
      type: Object,
      default: {},
      required: true,
    },

    selectedPatient: {
      type: Object,
      default: {},
      required: true,
    },
    serviceUnit: {
      type: String,
      default: '',
      required: true,
    },
    medicationOrder: Object,
  },
  data() {
    return {
      iconUp: 'fa-angle-up',
      iconDown: 'fa-angle-down',
      collapsed: false,
      collapsed2: false,
      collapsed6: false,
    }
  },
  mounted() {
    this.socket.on(this.encounter.name, (enc) => { 
      alert("updated")
      this.encounterUpdated(enc);
        if(this.encounter.docstatus == 1) {
          this.socket.off(this.encounter.name);
        }
    });

  },
  methods: {
    encounterUpdated(encounter) {
      this.contextUpdateEncounter(encounter)
      this.$emit('encounterUpdated', encounter)
    },
    medicationOrderUpdated(order) {
      this.$emit('medicationOrderUpdated', order)
    },
  },
}
</script>
<style scoped>
.card-body {
  padding: 10px;
  padding-left: 25px;

}

.head-toggle {
  outline: 0;
}

.header {
  background: transparent;
}

.prescriptions {
  margin-top: 16px;
}

.card {
  border: 1px solid lightgray;
  padding: 0px !important;
}

.card:hover {
  box-shadow: none;
}

.card .card-header {
  /* padding: .5rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03); */
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
  padding: 5px !important;
  padding-left: 10px !important;
}

.toggler {
  border: 1px solid #6b5c5c;
  padding-top: 5px;
  border-radius: 61px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}
</style>
