import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getOwnersName = ({ owner }) => api({
  method: 'clinical.api.patient_chart.utilities.get_owners_name',
  args: {
    owner,

  },
})

export const getTimelineFeed = ({ payLoad }) => api({
  method: 'clinical.api.patient_chart.utilities.get_timeline_feed',
  args: {
    filter_payload: payLoad,

  },
})

export const getMyPatientTabPermissionsForFilters = () => api({
  method: 'clinical.clinical.doctype.patient_tab_permissions.patient_tab_permissions.get_patient_tabs_for_filter',
})

export const changePatientMedicalRecord = ({ patient, document }) => api({
  method: 'clinical.api.appointment.appointment.change_patient_medical_record',
  args: {
    patient,
    document,
  },
})
