<template>
  <div>
    <b-button
      size="sm"
      block
      variant="primary"
      @click="transferPatient"
    >
      Transfer Patient
    </b-button>

    <b-modal
      v-model="transferModalVisible"
      title="Transfer Patient"
      @ok="submitTransfer"
    >
      <b-form>
        <b-form-group
          label="Target Medical Department"
          label-for="target_medical_department"
          required
        >
          <!-- Use the component directly in the template -->
          <doctype-link
            :doctype="'Medical Department'"
            :filters="medicalDepartmentFilters"
            @selected="handleMedicalDepartmentSelected"
          />
        </b-form-group>

        <b-form-group
          label="Target Healthcare Service Unit (Optional)"
          label-for="target_service_unit"
        >
          <!-- Use the component directly in the template -->
          <doctype-link
            :doctype="'Healthcare Service Unit'"
            :filters="serviceUnitFilters"
            @selected="handleServiceUnitSelected"
          />
        </b-form-group>

        <b-form-group
          label="Target Bed (Optional if service unit is unspecified)"
          label-for="target_bed"
        >
          <!-- Use the component directly in the template -->
          <doctype-link
            :doctype="'Healthcare Service Unit'"
            :filters="bedFilters"
            @selected="handleBedSelected"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { createInpatientTransfer } from './service'

export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
    service_unit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      transferModalVisible: false,
      selectedMedicalDepartment: null,
      selectedServiceUnit: null,
      selectedBed: null,
      medicalDepartmentFilters: {
        name: ['not like', '%Farewell%'],
      },
      serviceUnitFilters: {
        name: ['not like', '%Farewell%'],
        is_group: 1,
      },
      bedFilters: {
        is_group: 0,
        name: ['like', '%BED%'],
        occupancy_status: 'Vacant',
      },
    }
  },
  methods: {
    transferPatient() {
      // Set the initial values for the modal
      this.selectedMedicalDepartment = null
      this.selectedServiceUnit = null
      this.selectedBed = null
      this.transferModalVisible = true
    },
    submitTransfer() {
      const values = {
        target_medical_department: this.selectedMedicalDepartment,
        target_service_unit: this.selectedServiceUnit,
        target_bed: this.selectedBed,
      }

      createInpatientTransfer({
        patient: this.patient.patient,
        inpatient_record: this.patient.name,
        target_medical_department: values.target_medical_department,
        initiating_service_unit: this.service_unit.service_unit,
        initiating_bed: this.patient.bed,
        target_service_unit: values.target_service_unit,
        target_bed: values.target_bed,
      }).then(() => {
        const payload = {
          serviceUnit: this.service_unit.service_unit, servicePoint: '', search: '', work: 'components/patient/transferpatient',
        }
        this.$store.dispatch('patient/fetchPatients', payload)

        this.show_alert({
          message: 'Transfer Successfully Initiated',
          indicator: 'green',
        }, 5)

        this.transferModalVisible = false
      })
    },
    handleMedicalDepartmentSelected(selectedValue) {
      // Handle the selected value from DoctypeLink component for Medical Department
      this.selectedMedicalDepartment = selectedValue
    },
    handleServiceUnitSelected(selectedValue) {
      // Handle the selected value from DoctypeLink component for Healthcare Service Unit
      this.selectedServiceUnit = selectedValue
    },
    handleBedSelected(selectedValue) {
      // Handle the selected value from DoctypeLink component for Bed
      this.selectedBed = selectedValue
    },
  },
}
</script>
