import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getFormConfiguration = ({ name = '' }) => fetch({
  method: 'clinical.api.forms.form_builder.get_form_configuration',
  args: {
    name,
  },
}).then(({ message }) => message)

export const getFormList = patient => api({
  method: 'clinical.api.forms.form_viewer.get_all_forms_types_per_patient',
  args: {
    patient_name: patient,
  },
})

export const getDoctypeByName = payload => api({
  method: 'clinical.api.forms.form_builder.get_doctype_by_name',
  args: { payload },
})
