<template>
  <b-container>
    <b-row>
      <b-col />
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'PaymentNotification',
  props: {
    isCompressed: { type: Boolean, required: false, default: false },
    patient: { type: String, required: false, default: '2000003' },
  },
  computed: {
    patientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
    selectedPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
  },
  watch:
    {
      //  watch for data change
      patient(newPatient, oldPatient) {
        if (newPatient !== oldPatient) {
          // un sub from old patient
          window.socket.off(oldPatient)

          // subscribe to new patient
          window.socket.on(newPatient, data => {
            this.toast(data)
          })
        }
      },
    },

  mounted() {
    // subscribe to socket broadcast
    window.socket.on(this.patient, data => {
      this.toast(data)
    })
  },

  methods: {

    toast(data) {
      this.$bvToast.toast(data.message, {
        title: data.title,
        autoHideDelay: 5000,
        appendToast: true,
      })
    },
  },
}
</script>

<style scoped>

</style>
