<template>
  <ScrollTopComponent>
    <b-button class="btnx">
      up
    </b-button>
  </ScrollTopComponent>
</template>

<script>
import ScrollTopComponent from './ScrollTopComponent.vue'

export default {
  name: 'ScrollTopArrow',
  components: {
    ScrollTopComponent,
  },
}
</script>

<style>
.btnx {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.55);
    padding-top: 27px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}
</style>
