<template>
  <div style="text-align: left; font-size: 13px !important;">
    <!-- v-html="recordItems" -->

    <div v-if="recordType === 'Vital Signs'">
      <i
        class="fa fa-pencil-square-o"
        aria-hidden="true"
        style="color: grey;float:right;"
        @click="editRecord"
      />
      <div v-if="recordItems && recordItems[0]">
        <vital-signs-item
          :record-items="recordItems"
          :record-type="recordType"
          :record-id="recordId"
        />
      </div>
    </div>

    <div v-else-if="recordType === 'Patient Encounter'">
      <div v-if="recordItems && recordItems[0]">
        <patient-encounter-item
          :record-items="recordItems"
          :record-type="recordType"
          :record-id="recordId"
          :prescription-items="prescriptionItems"
          :procedure-items="procedureItems"
        />
      </div>
    </div>

    <div v-else-if="recordType === 'Kardex'">
      <i
        class="fa fa-pencil-square-o"
        aria-hidden="true"
        style="color: grey;float:right;"
        @click="editRecord"
      />
      <div v-if="recordItems && recordItems[0]">
        <kardex-item
          :record-items="recordItems"
          :record-type="recordType"
          :record-id="recordId"
          :kardex-notes-html-string="kardexNotesHtmlString"
        />
      </div>
    </div>

    <div v-else-if="recordType === 'Doctors Notes'">
      <i
        class="fa fa-pencil-square-o"
        aria-hidden="true"
        style="color: grey;float:right;"
        @click="editRecord"
      />
      <div v-if="recordItems && recordItems[0]">
        <doctors-notes-item
          :record-items="recordItems"
          :record-type="recordType"
          :record-id="recordId"
          :subjective-notes-html-string="subjectiveNotesHtmlString"
          :objective-notes-html-string="objectiveNotesHtmlString"
          :assessment-notes-html-string="assessmentNotesHtmlString"
          :plan-notes-html-string="planNotesHtmlString"
        />
      </div>
    </div>

    <div v-else-if="recordType === 'Form Repository'">

      <div v-if="recordItems && recordItems[0]">

        <FormsItem :form-config="recordItems" />
      </div>
    </div>

    <div v-else-if="recordType === 'Clinical Procedure'">
      <div v-if="recordItems && recordItems[0]">

        <clinical-procedure-item
          :record-items="recordItems"
          :record-type="recordType"
          :record-id="recordId"
        />
      </div>
    </div>

    <div v-else-if="recordType === 'Theatre Schedule'">
      <div v-if="recordItems && recordItems[0]">
        <theatre-schedule-item
          :record-items="recordItems"
          :record-type="recordType"
          :record-id="recordId"
        />
      </div>
    </div>

    <div v-else-if="recordType === 'File'">
      <div v-if="recordItems && recordItems[0]">
        <previous-files-item
          :record-items="recordItems"
          :record-type="recordType"
          :record-id="recordId"
          :subject="subject"
        />
      </div>
    </div>
    <div v-else-if="recordType === 'Patient Appointment'">
      <div v-if="recordItems && recordItems[0]">

        <patient-appointment
          :record-type="recordType"
          :record-id="recordId"
        />
      </div>
    </div>

    <div v-else-if="recordType === 'Lab Test'">
      <i class="fa-solid fa-flask"  style="color: #ffb347;"></i>
      <span> {{ recordType }} - {{ recordId }}</span>
      <div style="overflow-x: auto">
        <div v-html="subject" />
      </div>
    </div>

    <div v-else-if="recordType === 'Inpatient Record'">
      <i class="fa-solid fa-list-check"
      style="color: #ffb347;"
      ></i>
      <span> Discharge Summary - {{ recordId }}</span>
      <div style="overflow-x: auto">
        <discharge :from-timeline="true" />
      </div>
    </div>

    <div v-else>
      <i class="fa-solid fa-square-pen" style="color: #808080;"></i>
      <span> {{ recordType }} - {{ recordId }}</span>
      <div style="overflow-x: auto">
        <div v-html="subject" />
      </div>
    </div>
  </div>
</template>
<script>
import Discharge from '@/views/erp/clinical/working-area/components/patient-chart/components/discharge/Discharge.vue'
import FormsItem from './timeline-items/FormsItem.vue'
import VitalSignsItem from './timeline-items/VitalSignsItem.vue'
import PatientEncounterItem from './timeline-items/PatientEncounterItem.vue'
import ClinicalProcedureItem from './timeline-items/ClinicalProcedureItem.vue'
import TheatreScheduleItem from './timeline-items/theatre-schedule-item/TheatreScheduleItem.vue'
import KardexItem from './timeline-items/KardexItem.vue'
import DoctorsNotesItem from './timeline-items/DoctorsNotesItem.vue'
import PreviousFilesItem from './timeline-items/PreviousFilesItem.vue'
import PatientAppointment from './timeline-items/PatientAppointmentItem.vue'
import { changePatientMedicalRecord, api } from './service'

export default {
  components: {
    FormsItem,
    VitalSignsItem,
    PatientEncounterItem,
    KardexItem,
    DoctorsNotesItem,
    ClinicalProcedureItem,
    TheatreScheduleItem,
    PreviousFilesItem,
    PatientAppointment,
    Discharge,
  },
  props: {
    recordId: {
      type: String,
      default: {},
      required: true,
    },
    recordType: {
      type: String,
      default: {},
      required: true,
    },
    documentName: {
      type: String,
      default: {},
      required: true,
    },
    subject: String,
  },

  data() {
    return {
      recordItems: [],
      prescriptionItems: [],
      procedureItems: [],
      kardexNotesHtmlString: '',
      subjectiveNotesHtmlString: '',
      objectiveNotesHtmlString: '',
      assessmentNotesHtmlString: '',
      planNotesHtmlString: '',
    }
  },

  mounted() {
    // GET Vitals,kardex,encounter RECORD
    api({
      method:
        'clinical.api.patient_chart.utilities.get_record_items',
      args: {
        name: this.recordId,
        doctype: this.recordType,
      },
    }).then(r => {
      const data = r
      if (data) {
        this.recordItems = data
        this.kardexNotesHtmlString = data[0].notes
        this.subjectiveNotesHtmlString = data[0].subjective
        this.objectiveNotesHtmlString = data[0].objective
        this.assessmentNotesHtmlString = data[0].assessment
        this.planNotesHtmlString = data[0].plan
      } else {
        this.show_alert('Not found.')
      }
    })
    // DO A Drug Prescription and clinical procedure call if doctype is encounter
    if (this.recordType == 'Patient Encounter') {
      api({
        method:
          'clinical.api.patient_chart.utilities.get_encounter_prescriptions',
        args: {
          name: this.recordId,
        },
      }).then(r => {
        const data = r
        if (data) {
          this.prescriptionItems = data
        } else {
          this.show_alert('Not found.')
        }
      })
      api({
        method:
          'clinical.api.patient_chart.utilities.get_encounter_procedures',
        args: {
          name: this.recordId,
        },
      }).then(r => {
        const data = r
        if (data) {
          this.procedureItems = data
        } else {
          this.show_alert('Not found.')
        }
      })
    } else {
    }
  },
  methods: {
    editRecord() {
      const me = this
      const dialog = new frappe.ui.Dialog({
        title: 'Reassign medical record to a different patient',
        fields: [
          {
            fieldtype: 'Link',
            fieldname: 'target_patient',
            options: 'Patient',
            label: 'Target Patient',
            reqd: 1,
            onchange() {

            },
          },
        ],
        primary_action_label: ('Change Patient'),
        primary_action: values => {
          changePatientMedicalRecord({
            patient: values.target_patient,
            document: me.documentName,
          }).then(res => {
            this.show_alert({
              message: ('Record successfully changed'),
              indicator: 'green',
            }, 5)

            dialog.hide()
          })
        },
      })
      dialog.show()
    },
  },
}
</script>
<style>
@media only screen and (max-width: 767px) {
  
div {
  font-size: 11px !important;
}
}

</style>
