import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getInpatientMedications = (patient, inpatient_record) => api({
  method: 'clinical.api.patients.get_inpatient_medications',
  args: {
    patient,
    inpatient_record,
  },
})

export const getInpatientClinicalProcedures = (patient, inpatient_record) => api({
  method: 'clinical.api.patients.get_inpatient_clinical_procedures',
  args: {
    patient,
    inpatient_record,
  },
})

export const getInpatientLabTests = (patient, inpatient_record) => api({
  method: 'clinical.api.patients.get_inpatient_lab_tests',
  args: {
    patient,
    inpatient_record,
  },
})

export const getInpatientDischargeNotes = (patient, inpatient_record) => api({
  method: 'clinical.api.patients.get_inpatient_discharge_notes',
  args: {
    patient,
    inpatient_record,
  },
})

export const getGetCauseOfDeath = patient => api({
  method: 'clinical.api.patients.get_cause_of_death',
  args: {
    patient,
  },
})

export const getInpatientDoctorsNotes = (patient, inpatient_record) => api({
  method: 'clinical.api.patients.get_inpatient_doctors_notes',
  args: {
    patient,
    inpatient_record,
  },
})
export const createInpatientTransfer = ({
  patient,
  inpatient_record,
  target_medical_department,
  initiating_service_unit,
  initiating_bed,
  target_service_unit,
  target_bed,
}) => api({
  method: 'clinical.api.patients.create_inpatient_transfer',
  args: {
    patient,
    inpatient_record,
    target_medical_department,
    initiating_service_unit,
    initiating_bed,
    target_service_unit,
    target_bed,
  },
})

export const createPatientEncounter = (patient, is_walk_in) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.create_patient_encounter',
  freeze: false,
  args: {
    patient,
    is_walk_in,
  },
})

export const scheduleDischarge = args => api({
  method: 'clinical.api.patients.schedule_patient_discharge',
  args: { args },
})

export const createDeathNotification = ({
  patient, immediate_cause, underlying_cause, pract,
}) => api({
  method: 'clinical.clinical.doctype.death_notification.death_notification.insert_notification',
  args: {
    patient,
    immediate_cause,
    underlying_cause,
    pract,
  },
})

export const manuallyDischargeAndVacateBed = (inpatient_record, patient) => api({
  method: 'clinical.api.patients.manually_discharge_and_vacate_bed',
  args: {
    inpatient_record,
    patient,
  },
})
