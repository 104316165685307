<template>
  <div>
    <b-button
      size="sm"
      block
      style="margin-right:2px;"
      @click="scheduleAdmission"
    >
      Schedule Admission
    </b-button>
    <b-modal
      v-model="admissionModalVisible"
      title="Patient Admission"
      @ok="submitAdmission"
    >
      <b-form>
        <b-form-group
          label="Medical Department"
          label-for="medical_department"
        >
          <!-- Use the component directly in the template -->
          <doctype-link
            :doctype="'Medical Department'"
            required
            @selected="handleDoctypeLinkSelected"
          />
        </b-form-group>

        <b-form-group
          label="Admission Instruction"
          label-for="admission_instruction"
        >
          <b-form-textarea v-model="admission_instruction" />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import {
  scheduleAdmission as scheduleAdmissionApi,
  createPatientEncounter,
  closeAppointment,
} from '../../service'

export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
    encounter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      admissionModalVisible: false,
      medical_department: null,
      admission_instruction: '',
    }
  },
  methods: {
    scheduleAdmission() {
      // Set the initial values for the modal
      this.medical_department = null
      this.admission_instruction = ''
      this.admissionModalVisible = true
    },
    submitAdmission() {
      // Perform your submit logic for scheduling admission here
      const values = {
        medical_department: this.medical_department,
        admission_instruction: this.admission_instruction,
      }

      if (!this.encounter.name) {
        createPatientEncounter(this.patient.patient)
          .then(encounter => {
            scheduleAdmissionApi({
              ...values,
              patient: this.patient.patient,
              encounter: encounter.name,
              queue_state: this.patient.queue_name,
            })
              .then(() => {
                this.$emit('encounterUpdated', encounter)
                closeAppointment(this.patient.name).then(() => {
                  this.updateList()
                })
                this.admissionModalVisible = false
              })
          })
      } else {
        scheduleAdmissionApi({
          ...values,
          patient: this.patient.patient,
          encounter: this.encounter.name,
          queue_state: this.patient.queue_name,
        })
          .then(() => {
            closeAppointment(this.patient.name).then(() => {
              this.updateList()
            })
            this.admissionModalVisible = false
          })
      }
    },
    handleDoctypeLinkSelected(selectedValue) {
      // Handle the selected value from DoctypeLink component
      this.medical_department = selectedValue
    },
  },
}
</script>
