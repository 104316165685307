import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const commentTheatre = payload => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.add_theatre_schedule_comment',
  freeze: false,
  args: {
    payload,
  },
})
