import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getFormConfiguration = ({ name = '' }) => api({
  method: 'clinical.api.forms.form_builder.get_form_configuration',
  args: {
    name,
  },
})

export const updateDoctypeData = ({ formData }) => api({
  method: 'clinical.api.forms.form_builder.update_doctype_data',
  args: {
    form_data: formData,
  },
})
export const updateFormData = ({ formData }) => api({
  method: 'clinical.api.forms.form_builder.update_form_data',
  args: {
    form_data: formData,
  },
})
