<template>
  <div>

    <span class="document-id">
      <i
        class="fa fa-calendar-day"
        aria-hidden="true"
        style="color: #2196f3;  border-radius: 100;"
      />
      <span> {{ recordType }} - {{ recordId }}</span>

      <br>
      <b>Healthcare Practitioner: </b>{{ recordItems[0].practitioner_name
      }}<br>
      <b>Service Unit: </b>{{ recordItems[0].service_unit
      }}<br>
      <b>Date: </b>{{ recordItems[0].appointment_date
      }}<br>
      <b>Time: </b>{{ recordItems[0].appointment_time
      }}<br>
      <b>Status: </b>{{ recordItems[0].status
      }}<br>
      <br>
      <b v-if="recordItems[0].telemedicine_link">Link: </b>  <a :href="recordItems[0].telemedicine_link">Open appointment link</a>
      <br>

    </span>
  </div>
</template>
<script>
import { api } from '../service'

export default {
  props: {
    recordId: {
      type: String,
      default: '',
      required: true,
    },
    recordType: {
      type: String,
      default: '',
      required: true,
    },
    recordItems: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {}
  },
  mounted() {
    api({
      method:
          'clinical.api.patient_chart.utilities.get_patient_appointment',
      args: {
        name: this.recordId,
      },
    }).then(r => {
      const data = r
      if (data) {
        this.recordItems = data
      } else {
        this.msgprint('Not found.')
      }
    })
  },
}
</script>
