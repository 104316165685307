import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const callPatientIn = queue_state => api({
  method: 'clinical.api.queue_management.queue_logistics.call_patient_in',
  args: {
    queue_state,
  },
})

export const stopPatientAnnouncement = queue_state => api({
  method: 'clinical.api.queue_management.queue_logistics.stop_patient_announcement',
  args: {
    queue_state,
  },
})
