<template>
  <b-container>
    <b-row>
      <b-col
        v-if="false"
        cols="4"
      >
        <b-row class="more-infos">
          <div class="mr-3">
            <b-avatar
              rounded
              size="72px"
            />
          </div>
          <b-col>
            <b-row>
              <span> <b>Status :</b></span>{{ patientDetails.status }}
            </b-row>
            <b-row>
              <span> <b>Gender :</b></span>{{ patientDetails.sex }}
            </b-row>
            <b-row>
              <span> <b>Date of Birth :</b></span>{{ patientDetails.dob }}
            </b-row>
            <b-row>
              <span> <b>Phone :</b></span>{{ patientDetails.phone }}
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <today-summary />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TodaySummary from './components/TodaySummary.vue'

export default {
  name: 'PatientDetails',
  components: { TodaySummary },
  computed: {
    patientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
    selectedPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },

  watch: {
    selectedPatient(activePatient) {
      this.getPatientDetails(activePatient.patient)
    },
  },

  mounted() {
    if (this.selectedPatient && this.selectedPatient.patient) {
      this.getPatientDetails(this.selectedPatient.patient)
    }
  },
  methods: {
    getPatientDetails(patient_name) {
      const payload = { patient_name }
      this.$store.dispatch('patient/fetchSelectedPatientDetails', payload)
    },
  },
}
</script>

<style scoped>
.more-infos {
  border: 0px solid white;
  padding: 4px;
  padding-bottom: 22px;
  border-radius: 12px;
  padding-top: 22px;
  background: lightslategray;
  color: white;
  background-color: #310e68;
  background-image: linear-gradient(315deg, #06a79e 0%, #6f5a7b 74%);
}
.b-avatar {
  border: 0px solid darkgray;
  color: #312b2b75;
  display: none;
}
.ambulance {
  /* float: right; */
}
</style>
