<template>
  <div>
    <div class="space-between">
      <b-button
        v-if="medicationOrderReadOnlyStatus == 0"
        variant="primary"
        @click="fetchItemGroups()"
      >
        <i
          class="fa fa-plus-circle"
          aria-hidden="true"
        /> Add
      </b-button>
         &nbsp;
      <b-button
        variant="primary"
        @click="printOrderSummary()"
      >
        <i
          class="fa fa-print"
          aria-hidden="true"
        />Print
      </b-button>
    </div>
    <b-table
      v-if="draftOrders.length > 0"
      key="1"
      :fields="fields"
      class="table"
      stacked="md"
      small
      hover
      :items="draftOrders"
    >
      <template #cell(action)="row">
        <div>
          <!-- <button @click="info(row.item, $event.target)" class="btn btn-primary btn-sm">Administer</button> -->
          <b-button
            variant="primary"
            @click="printPrescription(row.item)"
          >
            <i
              class="fa fa-print"
              aria-hidden="true"
            />Print
          </b-button>
          <b-button
            :disabled="
              row.item.status === 'Completed' || row.item.status === 'Cancelled'
            "
            class="btn btn-danger btn-sm"
            variant="danger"
            @click="confirmOpen(row.item.name, getDraftOrders)"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-table>
    <h5>Submitted Prescriptions</h5>
    <hr>
    <b-table
      key="12"
      :fields="fields"
      class="table"
      stacked="md"
      small
      striped
      hover
      :items="orders"
    >
      <template #cell(action)="row">
        <div>
        <!-- <button @click="info(row.item, $event.target)" class="btn btn-primary btn-sm">Administer</button> -->
         &nbsp;
          <!-- <b-button @click="printPrescription(row.item)" variant="primary"><i class="fa fa-print" aria-hidden="true"></i>Print</b-button> -->
          <b-button
            :disabled="
              row.item.status === 'Completed' || row.item.status === 'Cancelled'
            "
            class="btn btn-danger btn-sm"
            variant="danger"
            @click="confirmOpen(row.item.name, getOrders)"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  getPractitionerMedicationOrders,
  cancelMedicationOrder,
  createMedicationOrder,
  getUnsubmittedMedicationOrders,
  createPatientEncounter,
  getMedicationOrderReadOnlyStatus,
  checkStockAvailability,
  getAlternativeDrugs,
  api,
} from './service'

export default {
  name: 'MedicationOrders',
  props: {
    patient: {
      type: String,
      default: '',
      required: true,
    },
    encounter: {
      type: Object,
      default: {},
      required: true,
    },
    medicationOrder: Object,
  },
  data: () => ({
    orders: [],
    draftOrders: [],
    fields: [
      'drug',
      'drug_name',
      'status',
      'dosage',
      'dosage_form',
      'start_date',
      'end_date',
      { label: 'Actions', key: 'action' },
    ],
    medicationOrderReadOnlyStatus: '',
    medicationItemGroups: [],
    loading: false,
  }),
  computed: {
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
  },
  watch: {
    patient() {
      getPractitionerMedicationOrders(this.patient).then(orders => {
        this.orders = orders
      })
      this.getDraftOrders()
    },
  },
  mounted() {
    getMedicationOrderReadOnlyStatus().then(status => {
      this.medicationOrderReadOnlyStatus = status
    })
    getPractitionerMedicationOrders(this.patient).then(orders => {
      this.orders = orders
    })
    this.getDraftOrders()
  },
  methods: {
    printOrderSummary() {
      // print medication orders for the inpatient record
    },
    printPrescription(order) {
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
                + 'doctype='}${encodeURIComponent('Inpatient Medication Order')
      }&name=${encodeURIComponent(order.name)
      }&format=Inpatient Medication Order Custom&_lang=en&trigger_print=1`))
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    fetchItemGroups() {
      api({
        // method: "frappe.client.get_list",
        method: 'clinical.api.prescription.prescription.get_is_prescription_item',
        args: {
          // doctype: "Item Group",
          // async: false,
          // filters: { is_prescription_item: 1 },
        },
      }).then(res => {
        this.medicationItemGroups = res // res.map((itemGroup) => itemGroup.name);
        this.openModal()
      })
    },
    openModal() {
      const parent = this
      const fields = [{
        label: 'Start Date',
        fieldname: 'start_date',
        fieldtype: 'Date',
        reqd: true,
        default: frappe.datetime.now_datetime(),
      },
      {
        label: 'Drug',
        fieldname: 'drug_code',
        fieldtype: 'Link',
        options: 'Item',
        reqd: true,
        filters: {
          is_stock_item: 1,
          disabled: 0,
          has_variants: 'No',
          item_group: ['IN', parent.medicationItemGroups],
        },
      },
      {
        label: 'Drug Name',
        fieldname: 'drug_name',
        fieldtype: 'Read Only',
        // fetch_from:'drug_code.name',
      },
      {
        label: 'Dosage',
        fieldname: 'dosage',
        fieldtype: 'Link',
        options: 'Prescription Dosage',
        reqd: true,
      },
      {
        label: 'Period',
        fieldname: 'period',
        fieldtype: 'Link',
        options: 'Prescription Duration',
        reqd: true,
      },
      {
        label: 'Dosage Form',
        fieldname: 'dosage_form',
        fieldtype: 'Link',
        options: 'Dosage Form',
        reqd: true,
      },
      ]
      const me = this
      const dialog = new frappe.ui.Dialog({
        title: 'Add Medication Orders',
        fields,
        primary_action_label: 'Submit',
        primary_action: values => {
          const current = me
          me.loading = true
          dialog.get_primary_btn().attr('disabled', true)
          if (!me.encounter.name) {
            createPatientEncounter(me.patient).then(val => {
              me.$emit('encounterUpdated', val)
              createMedicationOrder({
                ...values,
                patient: me.patient,
                service_unit: this.selectedServiceUnit.service_unit,
                patient_encounter: val.name,
                document: this.medicationOrder.name || '',
              }).then(order => {
                current.$emit('medicationOrderUpdated', order)
                current.getDraftOrders()
                dialog.hide()
              })
            })
          } else {
            createMedicationOrder({
              ...values,
              patient: this.patient,
              service_unit: this.selectedServiceUnit.service_unit,
              patient_encounter: me.encounter.name,
              document: this.medicationOrder.name || '',
            }).then(order => {
              current.getDraftOrders()
              dialog.hide()
              current.$emit('medicationOrderUpdated', order)
            })
          }
        },
      })

      const onDrugChange = event => {
        const value = dialog.get_value('drug_code')
        if (value) {
          this.checkAvailability(value)
        }
      }

      fields.forEach(({ fieldname }) => {
        const field = dialog.get_field(fieldname)
        if (field.$input) {
          field.$input.on('change', onDrugChange)
        }
        field.refresh()
      })
      dialog.fields_dict.drug_code.df.onchange = () => {
        api({
          method: 'frappe.client.get_list',
          args: {
            doctype: 'Item',
            filters: { name: dialog.get_value('drug_code') },
            fields: ['item_name'],
          },
        }).then(res => {
          if (res.length) {
            dialog.set_values({
              drug_name: res[0].item_name,
            })
          } else {
            dialog.set_values({
              drug_name: 'Name unavailable',
            })
          }
        })
        api({
          method: 'clinical.api.orders.prescriptions.get_prescription_defaults',
          args: {
            healthcare_practitioner: me.encounter.practitioner,
            drug_code: dialog.get_value('drug_code'),
          },
        }).then(res => {
          if (res) {
            dialog.set_values({
              dosage: res.dosage,
              period: res.period,
              dosage_form: res.dosage_form,
              // comment: res.comment
            })
          } else {
            // this.show_alert({
            //     message: `Prescription defaults unavailable`,
            //     indicator: 'green'
            // }, 5);
            dialog.set_values({
              dosage: '',
              period: '',
              dosage_form: '',
            })
          }
        })
      }
      dialog.show()
    },
    createMedicationOrder,
    getOrders() {
      getPractitionerMedicationOrders(this.patient).then(orders => {
        this.orders = orders
      })
    },
    getDraftOrders() {
      getUnsubmittedMedicationOrders(this.patient, this.encounter.name).then(
        draftOrders => {
          this.draftOrders = draftOrders
        },
      )
    },
    checkAvailability(item) {
      Promise.all([
        checkStockAvailability({ item_code: item }),
        getAlternativeDrugs(item),
      ]).then(([stocks, alternativeItems]) => {
        const total = stocks.reduce((a, b) => a + b.actual_qty, 0)
        console.log('==========>', total)
        if (total <= 0) {
          const bodyElements = document.getElementsByClassName('modal-body')
          const body = bodyElements[bodyElements.length - 1]
          const node = document.createElement('div')
          node.innerHTML = `Insufficient stocks in the hospital for <strong>${item}</strong>. Consider alternatives listed below or await procurement.`
          node.style.color = 'red'

          const ul = document.createElement('ul')
          alternativeItems.forEach(item => {
            const li = document.createElement('li')
            li.innerHTML = `<span>${item.alternative_item_code}</span>`
            ul.appendChild(li)
          })
          node.appendChild(ul)

          body.appendChild(node)
        }
      })
    },
    confirmOpen(name, callback) {
      const onCancel = () => {
        cancelMedicationOrder(name).then(() => {
          callback()
        })
      }
      frappe.confirm(
        'Are you sure you want to cancel this Order?',
        () => onCancel(),
        console.log,
      )
    },
  },
}
</script>

<style scoped>
.space-between {
    display: flex;
    justify-content: flex-end;
}
</style>
