import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getPatientInfo = patient => api({
  method: 'erpnext.healthcare.doctype.patient.patient.get_patient_detail',
  args: {
    patient,
  },
})

export const submitEncounter = (name = '', patient = '', service_unit = {}, to_bill = true, origin, isWalkIn) => {
  console.log(JSON.stringify({
    name,
    patient,
    doctype: 'Patient Encounter',
    service_unit,
    to_bill: to_bill ? 1 : 0,
    origin,
    is_walk_in: isWalkIn,
  }))
  return api({
    method: 'clinical.api.patient_encounter.patient_encounter.submit',
    args: {
      name,
      patient,
      doctype: 'Patient Encounter',
      service_unit,
      to_bill: to_bill ? 1 : 0,
      origin,
      is_walk_in: isWalkIn,
    },
  })
}

export const submitMedicationOrder = name => api({
  method: 'clinical.api.patient_encounter.patient_encounter.submit',
  args: {
    name,
    doctype: 'Inpatient Medication Order',
  },
})

export const validatePFNumber = number => api({
  method: 'clinical.api.inpatient_drug_administration.medication_entry.validate_employee',
  freeze: false,
  args: {
    number: number.trim(),
  },
})

export const scheduleAdmission = args => api({
  method: 'clinical.api.patient_encounter.patient_encounter.schedule_admission',
  args,
})

export const createPatientEncounter = (patient, is_walk_in) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.create_patient_encounter',
  freeze: false,
  args: {
    patient,
    is_walk_in,
  },
})

export const closeAppointment = appointment_number => api({
  method: 'clinical.api.patient_encounter.patient_encounter.close_appointment',
  args: {
    appointment_number,
  },
})

export const admitPatient = args => api({
  method: 'clinical.api.patient_encounter.patient_encounter.admit_patient',
  args,
})

export const checkAdmissionEligibility = ({
  inpatient_record = '',
  service_unit = '',
}) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.check_admission_eligibility',
  args: {
    inpatient_record,
    service_unit,
  },
})

export const submitLabPrescriptions = ({
  service_unit,
  admission_status,
  encounter_name,
}) => api({
  method: 'clinical.api.orders.prescriptions.submit_and_bill_lab_prescriptions',
  args: {
    service_unit,
    admission_status,
    encounter_name,
  },
})

export const createAppointment = ({
  patient,
  service_unit,
  practitioner,
  department,
  appointment_date,
  appointment_time,
  referring_practitioner,
}) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.create_appointment',
  args: {
    patient,
    service_unit,
    practitioner,
    department,
    appointment_date,
    appointment_time,
    referring_practitioner,
  },
})

export const scheduleDischarge = args => api({
  method: 'clinical.api.patients.schedule_patient_discharge',
  args: { args },
})

export const getAvailabilityData = ({ practitioner, date }) => api({
  method: 'erpnext.healthcare.doctype.patient_appointment.patient_appointment.get_availability_data',
  args: { practitioner, date },
})

export const dischargeOrTransferBilling = inpatient_record => api({
  method: 'billing.billing.api.patient.inpatient_standing_charges_billing.discharge_or_transfer_billing',
  args: inpatient_record,
})

export const submitDoctorsNotes = patientEncounter => api({
  method: 'clinical.api.patient_chart.utilities.submit_doctors_notes',
  args: {
    patient_encounter: patientEncounter,

  },
}).then(({ message }) => message)
