<template>
  <div>
    <b-button
      block
      variant="primary btn-sm"
      @click="transferOutpatientInQueue"
    >
      <i
        class="fa fa-arrows-h"
        aria-hidden="true"
      />Transfer patient
    </b-button>
  </div>
</template>

<script>
import { transferOutpatient } from './service'

export default {
  name: 'OutpatientQueueTransfer',

  props: {
    details: {
      type: Object,
      default: null,
    },
    service_unit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      medical_dept: '',
      hide_doctor_field: 1,
      mandatory_doctor_field: 0,
    }
  },
  // mounted(){

  // },
  computed: {

  },
  watch: {

  },
  created() {
    if (this.service_unit.service_unit_details.is_pw_triage == 1) {
      this.hide_doctor_field = 0
      this.mandatory_doctor_field = 1
    }
  },
  methods: {
    transferOutpatientInQueue() {
      // const parent = this
      const dialog = new frappe.ui.Dialog({
        title: 'Transfer Patient',
        fields: [
          {
            fieldtype: 'Link',
            fieldname: 'target_service_unit',
            options: 'Healthcare Service Unit',
            // filters: {
            //     is_group: 1,
            // },
            label: 'Target Healthcare Service Unit',
            reqd: 1,
          },
          {
            fieldtype: 'Link',
            fieldname: 'target_doctor',
            options: 'Healthcare Practitioner',
            // filters: {
            //     is_group: 1,
            // },
            label: 'Select Preferred Doctor',
            hidden: this.hide_doctor_field,
            reqd: this.mandatory_doctor_field,
          },
        ],
        primary_action_label: ('Transfer'),
        primary_action: values => {
          // console.log(this.details);
          const { queue_name } = this.details
          console.log(queue_name)
          const callback = () => dialog.hide()
          transferOutpatient({
            queue_state: queue_name,
            service_unit: values.target_service_unit,
            target_doctor: values.target_doctor || '',
          }).then(res => {
            if (res == 'Empty') {
              this.msgprint({
                title: ('Alert'),
                indicator: 'red',
                message: ('Ensure that target service unit has a practitioner occupancy'),
              })
            } else {
              const payload = {
                serviceUnit: this.service_unit.service_unit, servicePoint: '', search: '', work: 'patient/components/outpatientqueue',
              }
              this.$store.dispatch('patient/fetchPatients', payload)
              this.show_alert({
                message: ('Transfer Successfull'),
                indicator: 'green',
              }, 5)
            }
            dialog.hide()
          })
        },
      })
      dialog.show()
    },

  },

}
</script>

<style>
.vl {
  border-left: 1px solid grey;
  height: 70px;
  visibility:hidden;
}
</style>
