import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const queuePatientToNextServiceStage = (
  queue_state,
  appointment,
  service_point,
  passed_status,
) => api({
  method: 'clinical.api.queue_management.queue_logistics.queue_patient_to_next_service_stage',
  args: {
    queue_state,
    appointment,
    service_point,
    passed_status,
  },
})

export const getAllQueueStatus = () => api({
  method: 'clinical.api.queue_management.queue_logistics.get_all_queue_status',
  args: {},
})

export const getDraftPatientEncounters = (patient, source = '', service_unit = {}) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.get_draft_patient_encounter',
  args: {
    patient,
    source,
    service_unit,
  },
})
