<template>
  <div>
    <span class="document-id">
      <i
        class="fa fa-folder-open-o"
        aria-hidden="true"
        style="color: #ff7f00; border: 2px solid #ffb347"
      />
      <span> {{ recordType }} - {{ recordId }}</span>
      <div style="overflow-x: auto">
        <div v-html="subject" />
      </div>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    recordItems: {
      type: Object,
      default: null,
    },
    recordId: {
      type: String,
      default: '',
      required: true,
    },
    recordType: {
      type: String,
      default: '',
      required: true,
    },
    subject: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
