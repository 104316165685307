import { render, staticRenderFns } from "./billExtraItems.vue?vue&type=template&id=2f953833&scoped=true&"
import script from "./billExtraItems.vue?vue&type=script&lang=js&"
export * from "./billExtraItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f953833",
  null
  
)

export default component.exports