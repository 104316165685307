import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const scheduleTheatre = payload => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.create_theatre_schedule',
  args: {
    payload,
  },
})
