<template>
  <b-container>
    <b-row
      class=""
      align-h="center"
      v-if="inputDoctype == null"
    >
      <b-col sm="12" md="6" lg="6" class="mb-1">
        <doctype-link
          class="w-100"
          :doctype="'Form Design'"
          :placeholder="'Search Form'"
          @selected="makeSelectDoctypeControl"
        />
      </b-col>

      <b-col sm="12" md="6" lg="6" class="mb-1">
        <b-dropdown
          v-model="selectedDoctype"
          split
          split-variant="outline-secondary"
          class="w-100"
          text="Select Recent Form"
          size="sm"
        >
          <b-dropdown-item
            v-for="(value, index) in recentForms"
            :key="index"
            @click="selectDoctype(value)"
          >
            {{ value }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>

    </b-row>
    <b-modal
      :id="id"
      no-close-on-backdrop
      size="xl"
      :title="selectedDoctype"
    >
      <b-row>
        <form-render-view
          :current-form-name="selectedDoctype"
          :patient="patient"
          :selected-doctype="referenceDoctype"
          :selected-doctype-reference="patient.patient"
        />
      </b-row>

      <template #modal-footer="{ cancel, ok }">
        <span />
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import FormRenderView from '@/views/components/formbuilder/Frm.vue'
import { getFormConfiguration } from './services'

const KEY = 'savedForms'
export default {
  components: {
    FormRenderView,
  },
  props: {
    patient: { type: Object },
    inputDoctype : {type: String}
  },
  data() {
    return {
      selectedDoctype: null,
      configuration: null,
      referenceDoctype: 'Patient',
      values: [],
      id: "23812974"
    }
  },
  computed: {
    recentForms() {
      this.values.filter(i => i != null || i != '')
    },
  },
  watch: {
    selectedDoctype(val) {
      this.getForm(val)
      this.checkAndAddValue(val)
      this.getAllValues()
    },
    inputDoctype (val){
      this.selectedDoctype = val;
    }
  },
  mounted() {
    this.getAllValues()
    this.id = `${Math.random()}`
  },

  methods: {
    makeSelectDoctypeControl(value) {
      this.selectedDoctype = value
      if (value != null) {
        this.$bvModal.show(this.id)
      }
    },
    getForm(name) {
      getFormConfiguration({ name }).then(config => {
        const formStringConfig = config.formdata

        const configObject = JSON.parse(formStringConfig)
        this.configuration = formStringConfig
        console.log(configObject)
        this.formName = config.name
        this.formData = configObject
        this.originalConfig = configObject
      })
    },
    goToRoute(doctype, reference) {
      this.$router.push({ name: 'viewer', params: { doctype, reference } })
    },
    checkAndAddValue(value) {
      // Check if the key exists
      if (!localStorage.getItem(KEY)) {
        // If it doesn't exist, create an array with the value and save it in local storage
        const valuesArray = [value]
        localStorage.setItem(KEY, JSON.stringify(valuesArray))
      } else {
        // If it does exist, check if the value is in the array
        const valuesArray = JSON.parse(localStorage.getItem(KEY))
        if (!valuesArray.includes(value)) {
          valuesArray.push(value)
          localStorage.setItem(KEY, JSON.stringify(valuesArray))
        } else {
          // If the value is in the array, do nothing or handle as necessary
          console.log('Value already exists in the array.')
        }
      }
    },
    getAllValues() {
      // Check if the key exists
      if (localStorage.getItem(KEY)) {
        // If it does exist, retrieve and return all the values
        this.values = JSON.parse(localStorage.getItem(KEY))
      } else {
        // If it does not exist, return an empty array or handle as necessary
        console.log('Key does not exist in local storage.')
        this.values = []
      }
    },
  },
}
</script>

<style scoped>
.render-formx {
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 0px;
  width: 100% !important;
}
</style>
