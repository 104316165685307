<template>
  <div>
    <b-button
      block
      size="sm"
      variant="primary"
      @click="admitPatientClick"
    >
      Admit Patient
    </b-button>

    <b-modal
      v-model="admissionModalVisible"
      title="Admit Patient"
      @ok="submitAdmission"
    >
      <b-form>
        <b-form-group
          label="Service Unit"
          label-for="service_unit"
        >
          <!-- Use the component directly in the template -->
          <doctype-link
            :doctype="'Healthcare Service Unit'"
            :filters="serviceUnitFilters"
            required
            @selected="handleServiceUnitSelected"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  checkAdmissionEligibility, admitPatient,
} from '../../service'

export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
    serviceUnit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      admissionModalVisible: false,
      selectedServiceUnit: null,
      serviceUnitFilters: {
        is_group: 0,
        name: ['like', '%BED%'],
        occupancy_status: 'Vacant',
        disabled: 0,
      },
    }
  },
  methods: {
    admitPatientClick() {
      // Set the initial values for the modal
      this.selectedServiceUnit = null
      this.admissionModalVisible = true
    },
    updateList() {
      this.$emit('updateList', {})
    },
    submitAdmission() {
      const values = {
        service_unit: this.selectedServiceUnit,
      }

      checkAdmissionEligibility({
        inpatient_record: this.patient.name,
        service_unit: values.service_unit,
      }).then(data => {
        if (data === 1) {
          admitPatient({
            ...values,
            inpatient_record: this.patient.name,
          }).then(() => {
            this.updateList()
            this.admissionModalVisible = false
          })
        } else {
          this.msgprint({
            title: 'Notification',
            indicator: 'red',
            message: 'Patient does not have enough funds to cover minimum admission charges for this service unit',
          })
        }
      })
    },
    handleServiceUnitSelected(selectedValue) {
      // Handle the selected value from DoctypeLink component
      this.selectedServiceUnit = selectedValue
    },
  },
}
</script>
