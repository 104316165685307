import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const createPatientEncounter = (patient, is_walk_in) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.create_patient_encounter',
  freeze: false,
  args: {
    patient,
    is_walk_in,
  },
})

export const getDraftPatientEncounters = (patient, source = '', service_unit = {}) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.get_draft_patient_encounter',
  args: {
    patient,
    source,
    service_unit,
  },
})
