import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const transferOutpatient = ({
  queue_state,
  service_unit,
  target_doctor,
}) => api({
  method: 'clinical.api.queue_management.queue_logistics.transfer_outpatient_in_queue',
  args: {
    queue_state,
    service_unit,
    target_doctor,
  },
})
