<template>
  <b-container>
    <b-row>
      <b-col>
        <b-button
          v-if="isCompressed"
          variant="danger"
          size="sm"
          @click="openModal1()"
        ><i class="fa fa-ambulance" /></b-button>

        <p
          variant="danger"
          size="sm"
          @click="openModal1()"
        >.</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { scheduleTheatre } from './service'

export default {
  name: 'TheatreScheduling',
  props: {
    isCompressed: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      elEm: ['EL', 'EM'],
    }
  },
  computed: {
    patientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
    selectedPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },

  watch: {
    selectedPatient(activePatient) {
      this.getPatientDetails(activePatient.patient)
    },
  },

  mounted() {
    if (this.selectedPatient && this.selectedPatient.patient) {
      this.getPatientDetails(this.selectedPatient.patient)
    }
  },
  methods: {
    getPatientDetails(patient_name) {
      const payload = { patient_name }
      this.$store.dispatch('patient/fetchSelectedPatientDetails', payload)
    },
    openModal1() {
      const parent = this
      const dlg = new frappe.ui.Dialog({
        title: 'Schedule For Theatre',
        fields: [
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Patient Information',
          },
          {
            label: 'Patient Number',
            fieldname: 'patient',
            read_only: 1,
            fieldtype: 'Data',
            default: parent.selectedPatient.patient,
          },
          {
            label: 'Age',
            fieldname: 'age',
            read_only: 1,
            fieldtype: 'Data',
            default: parent.selectedPatient.age,
          },
          {
            label: 'Current Ward',
            fieldname: 'current_ward',
            reqd: 1,
            fieldtype: 'Link',
            options: 'Healthcare Service Unit',
          },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'Patient Name',
            fieldname: 'patient_name',
            read_only: 1,
            fieldtype: 'Data',
            default: parent.selectedPatient.patient_name,
          },

          {
            label: 'Gender',
            fieldname: 'gender',
            read_only: 1,
            fieldtype: 'Data',
            default: parent.selectedPatient.gender,
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Patient Medical Info',
          },
          {
            label: 'Diagnosis',
            fieldname: 'diagnosis',
            reqd: 1,
            fieldtype: 'Data',
          },
          {
            label: 'HB',
            fieldname: 'hb',
            reqd: 1,
            fieldtype: 'Check',
          },
          {
            label: 'GXM',
            fieldname: 'gxm',
            reqd: 1,
            fieldtype: 'Check',
          },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'U/E/C',
            fieldname: 'uec',
            reqd: 1,
            fieldtype: 'Text',
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Scheduling Details',
          },
          {
            label: 'Operation',
            fieldname: 'operation',
            reqd: 1,
            fieldtype: 'Link',
            options: 'Clinical Procedure Template',
          },

          {
            label: 'Surgeon',
            fieldname: 'surgeon',
            reqd: 1,
            fieldtype: 'Link',
            options: 'Healthcare Practitioner',
          },
          {
            label: 'Date Time',
            fieldname: 'date',
            fieldtype: 'Datetime',
            reqd: 1,
          },

          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'Theatre',
            fieldname: 'theatre',
            reqd: 1,
            fieldtype: 'Link',
            options: 'Healthcare Service Unit',
          },
          {
            label: 'Department',
            fieldname: 'department',
            reqd: 1,
            fieldtype: 'Link',
            options: 'Department',
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Additional Details',
          },
          {
            label: 'EL/EM',
            fieldname: 'el_em',
            reqd: 1,
            fieldtype: 'Select',
            options: parent.elEm,
          },
          {
            label: 'Consent',
            fieldname: 'consent',
            reqd: 1,
            fieldtype: 'Check',
          },
          {
            label: 'Remarks',
            fieldname: 'remarks',
            reqd: 1,
            fieldtype: 'Text',
          },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'Anae Review',
            fieldname: 'anae_review',
            reqd: 1,
            fieldtype: 'Check',
          },
          {
            label: 'Paid',
            fieldname: 'paid',
            reqd: 0,
            fieldtype: 'Check',
          },

          {
            fieldname: 'section_break_2',
            fieldtype: 'Section Break',
          },

        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          const data = { ...values }
          scheduleTheatre(data).then(() => {
            this.show_alert(
              {
                message: ('Theatre Successfully Scheduled'),
                indicator: 'green',
              },
              5,
            )
            dlg.hide()
          })
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dlg.hide()
        },
      }).show()
      dlg.$wrapper.find('.modal-content').css('width', '1000px')
      dlg.$wrapper.find('.modal-content').css('margin-left', '-200px')
      dlg.$wrapper.find('.modal-content').css('backdrop', 'static')
      dlg.$wrapper.find('.modal-content').css('keyboard', 'false')
    },
  },
}
</script>

<style scoped></style>
