import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getBPData = ({
  patient = '',
}) => api({
  method: 'clinical.api.patient_chart.utilities.get_bp_data',
  args: {
    patient,
  },
})

export const getRespPulseData = ({
  patient = '',
}) => api({
  method: 'clinical.api.patient_chart.utilities.get_resp_pulse_data',
  args: {
    patient,
  },
})

export const getTemperatureData = ({
  patient = '',
}) => api({
  method: 'clinical.api.patient_chart.utilities.get_temperature_data',
  args: {
    patient,
  },
})

export const getBMIData = ({
  patient = '',
}) => api({
  method: 'clinical.api.patient_chart.utilities.get_bmi_data',
  args: {
    patient,
  },
})
