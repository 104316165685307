<template>
  <div>
    <b-row>
      <b-col
        md="6"
        sm="12"
      >
        <div class="mx-auto">
          <b-card
            title="Most recent vitals"
            tag="article"
            class="mb-2 mx-auto"
          >
            <b-card-text>
              <b-row class="mx-auto">
                <b-col class="vital-icon mx-auto">
                  <b-row align-v="center">
                    <i
                      class="fa fa-thermometer-full"
                      style="color: green"
                      aria-hidden="true"
                    />
                    {{ patientDetails.summary.vitals.temperature || "--" }}
                  </b-row>
                  <div class="desc mx-auto">
                    Degrees
                  </div>
                </b-col>

                <b-col class="vital-icon mx-auto">
                  <b-row align-v="center">
                    <i
                      class="fa fa-heartbeat hb"
                      style="color: red"
                      aria-hidden="true"
                    />{{ patientDetails.summary.vitals.pulse || "-- " }}
                  </b-row>
                  <div class="desc mx-auto">
                    Heart beat
                  </div>
                </b-col>

                <b-col class="vital-icon mx-auto">
                  <b-row align-v="center">
                    <i
                      class="fa fa-user"
                      style="color: #099171"
                      aria-hidden="true"
                    />{{
                      Math.floor(patientDetails.summary.vitals.bmi || 0) || "--"
                    }}
                  </b-row>
                  <div class="desc mx-auto">
                    BMI
                  </div>
                </b-col>

                <b-col class="vital-icon mx-auto">
                  <b-row align-v="center">
                    <i
                      class="fa fa-stethoscope"
                      style="color: orange"
                      aria-hidden="true"
                    />{{
                      patientDetails.summary.vitals.respiratory_rate || "--"
                    }}
                  </b-row>
                  <div class="desc mx-auto">
                    Breaths Per Minute
                  </div>
                </b-col>
              </b-row>

              <b-row
                style="font-size: 14px"
                class="mx-2 mt-2"
              >
                <div>
                  <b>BP:</b>
                  {{
                    `${patientDetails.summary.vitals.bp_systolic ||
                      "--"}  /  ${patientDetails.summary.vitals.bp_diastolic ||
                      "--"}`
                  }}
                </div>
                <div style="margin-left:10px;">
                  <b>Height:</b>
                  {{
                    `${patientDetails.summary.vitals.height + "Metres"||
                      "--"}`
                  }}
                </div>
                <div style="margin-left:10px;">
                  <b>Weight:</b>
                  {{
                    `${patientDetails.summary.vitals.weight + "Kgs"||
                      "--"}`
                  }}
                </div>
                <div style="margin-left:10px;">
                  <b>SpO2:</b>
                  {{
                    `${patientDetails.summary.vitals.oxygen_concentration ||
                      "--"}`
                  }}
                </div>

              </b-row>
              <b-row
                style="font-size: 14px"
                class="mx-2 mt-2"
              >

                <b-col class="pull-right">
                  <span
                    class="pull-right"
                    style="color: gray"
                  >
                    <i
                      class="fa fa-clock-o"
                      aria-hidden="true"
                    />{{
                      filterTime(patientDetails.summary.vitals.creation)
                    }}</span>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </div>
        <div class="mx-auto">
          <b-card
            title="Patient Diagnoses"
            tag="article"
            class="mb-2 mx-auto"
          >
            <b-card-text>

              <b-row
                v-if="
                  patientDetails.summary.notes.diagnoses && patientDetails.summary.notes.diagnoses.length
                "
                class="mx-1"
              >
                <b-table
                  ref="selectableTable"
                  style="width: 100%"
                  :empty-text="`No items to show`"
                  :empty-filtered-text="`No items to show`"
                  :show-empty="true"
                  :fields="fields"
                  :items="patientDetails.summary.notes.diagnoses"
                  striped
                  outlined
                  bordered
                  head-row-variant="secondary"
                  :sticky-header="'62vh'"
                  responsive
                  small
                  selectable
                  select-mode="single"
                  @row-selected="onRowSelected"
                />
              </b-row>
            </b-card-text>
          </b-card>
        </div>
        <div class="mx-auto">
          <b-card
            title="Previous Kardex notes"
            tag="article"
            class="mb-2 mx-auto"
          >
            <b-card-text>
              <b-row
                v-if="patientDetails.summary.kardex"
                class="mx-1"
              >
                <span class="pull-left">
                  <i
                    class="fa fa-clock-o"
                    aria-hidden="true"
                  />
                  {{ filterTime(patientDetails.summary.kardex.creation) }}
                </span>
              </b-row>
              <b-row
                v-if="patientDetails.summary.kardex"
                class="mx-1"
              >
                <div v-html="patientDetails.summary.kardex.notes" />
              </b-row>
            </b-card-text>
          </b-card>
        </div>
      </b-col>

      <b-col
        sm="12"
        md="6"
      >
        <b-card
          title="Previous Doctor's notes"
          tag="article"
          class="mb-2 mx-auto"
        >
          <b-card-text>
            <b-container>
              <b-row v-if="patientDetails.summary.notes">
                <span class="pull-left">
                  <i
                    class="fa fa-clock-o"
                    aria-hidden="true"
                  />
                  {{ filterTime(patientDetails.summary.notes.creation) }}
                </span>
              </b-row>
              <b-row>
                <div>
                  <b> Subjective :</b>
                  <span v-html="patientDetails.summary.notes.subjective" />
                </div>
              </b-row>

              <b-row>
                <div>
                  <b> Objective :</b>
                  <span v-html="patientDetails.summary.notes.objective" />
                </div>
              </b-row>

              <b-row>
                <div>
                  <b> Assessment :</b>
                  <span v-html="patientDetails.summary.notes.assessment" />
                </div>
              </b-row>

              <b-row>
                <div>
                  <b> Plan :</b>
                  <span v-html="patientDetails.summary.notes.plan" />
                </div>
              </b-row>
            </b-container>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'TodaySummary',
  mobileView: { type: Boolean, default: false, required: false },
  computed: {
    patientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
    fields() {
      return ['diagnosis_code', 'status']
    },
  },
  methods: {
    filterTime(dateTime) {
      if (dateTime) {
        return dateTime.split('.')[0]
      }
      return '--'
    },
  },
}
</script>

<style scoped>
.tab-content > .tab-pane {
  height: 100px !important;
  overflow: scroll !important;
}
.rounder {
  border: 1px solid lightgray;
  border-radius: 10px;
}
.vital-icon {
  font-size: 26px;
}
.desc {
  font-size: 10px;
}
i {
  margin-right: 7px;
}
.col-auto {
  font-size: 12px;
}
.card {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
@-webkit-keyframes animateHeart {
  0% {
    -webkit-transform: scale(0.8);
  }
  5% {
    -webkit-transform: scale(0.9);
  }
  10% {
    -webkit-transform: scale(0.8);
  }
  15% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(0.8);
  }
}
@keyframes animateHeart {
  0% {
    transform: scale(0.8);
  }
  5% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(0.8);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}

.hb {
  animation: animateHeart 2.5s infinite;
}
</style>
