<template>
  <div>
    <b-button
      block
      size="sm"
      variant="danger "
      @click="$bvModal.show('add_modal')"
    >
      Next Session
    </b-button>
    <div>
      <b-modal
        v-if="isVisible"
        id="add_modal"
        hide-footer
      >
        <template #modal-title>
          Move patient to the next session
        </template>
        <div>
          <b-form-checkbox
            id="checkbox-1"
            v-model="bookNextAppointment"
            name="checkbox-1"
            value="Yes"
            unchecked-value="No"
          >
            Book follow up appointment date
          </b-form-checkbox>
          <b-button
            class="pull-right"
            variant="primary btn-sm"
            @click="endQueueSession"
          >
            Confirm
          </b-button>
        </div>

        <div class="vl" />
        <b-col
          cols="12"
          class="no-background"
        >
          <b-row>
            <b-col>
              <b-alert
                variant="primary"
                show
              >
                Only choose next status under special circumstances, otherwise click "Confirm" and ignore the selection below
              </b-alert>
            </b-col>
          </b-row>
          <b-form-select v-model="selectedStatus">
            <option :value="null">
              Select Queue Status
            </option>
            <option
              v-for="item in queueStatus"
              :key="item.name"
              :value="item.status"
            >
              {{ item.status }}
            </option>
          </b-form-select>

        </b-col>

      </b-modal>
    </div>
    <div>
      <b-modal
        id="add_l_modal"
        no-close-on-backdrop
        size="xl"
        hide-footer
      >
        <template #modal-title>
          Book this patient for the next appointment
        </template>
        <div>
          <new-appointment :patient-from-chart="selectedPatientDetails.name" />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import NewAppointment from '@/views/erp/records/appointment/BookAppointment.vue'
import { queuePatientToNextServiceStage, getAllQueueStatus, getDraftPatientEncounters } from './service'

export default {
  name: 'EndQueueSession',
  components: {
    NewAppointment,
  },
  props: {
    details: {
      type: Object,
      default: null,
    },
    encounterr: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isVisible: 1,
      queueStatus: [],
      selectedStatus: null,
      bookNextAppointment: 'No',
      isEncounterValid: true,
    }
  },
  computed: {
    patient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    selectedPatientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    selectedServicePoint() {
      return this.$store.getters['servicePoint/getSelectedServicePoint'] || (this.servicePoints.length && this.servicePoints[0])
    },
    encounter() {
      return this.$store.getters['encounter/getEncounter']
    },
  },
  watch: {
    patient(value) {
      this.getPatientEncounter(value.patient)
    },
  },
  created() {
    getAllQueueStatus({}).then(res => {
      this.queueStatus = res
    })
    this.getPatientEncounter(this.patient.patient)
  },
  methods: {
    endQueueSession() {
      getDraftPatientEncounters(this.patient.patient, 'end_queue_session', { service_unit: this.selectedServiceUnit.service_unit }).then(res => {
        if (Object.keys(res).length !== 0) {
          // if(res.procedure_prescription.length > 0){

          // }else{
          //   // this.moveToNextSession()
          // }
          // this.msgprint({
          //     title: ('Notification'),
          //     indicator: 'red',
          //     message: ('Please ensure that you have signed off or cancelled any pending orders before proceeding')
          // });
        } else {

        }
        this.moveToNextSession()
      })
    },
    moveToNextSession() {
      if (this.selectedStatus == 'Visit Completed' || this.details.status == 'Awaiting Investigation') {
        if (this.bookNextAppointment == 'Yes') {
          this.$bvModal.show('add_l_modal')
          this.finalMoveToNextSession()
        } else if (this.bookNextAppointment == 'No') {
          this.finalMoveToNextSession()
        }
      } else {
        this.finalMoveToNextSession()
      }
    },
    finalMoveToNextSession() {
      this.isVisible = 0
      queuePatientToNextServiceStage(this.details.queue_name, this.details.name, this.selectedServicePoint.name, this.selectedStatus).then(
        res => {
          const payload = { serviceUnit: this.selectedServiceUnit.service_unit, servicePoint: this.selectedServicePoint.name, search: '' }
          this.$store.dispatch('patient/fetchPatients', payload)

          this.show_alert({
            message: ('Session Terminated'),
            indicator: 'green',
          }, 5)
          this.isVisible = 1
        },
      )
    },

  },

}
</script>

<style>
.vl {
  border-left: 1px solid grey;
  height: 70px;
  visibility:hidden;
}
</style>
