import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const createInpatientTransfer = ({
  patient,
  inpatient_record,
  target_medical_department,
  initiating_service_unit,
  initiating_bed,
  target_service_unit,
  target_bed,
}) => api({
  method: 'clinical.api.patients.create_inpatient_transfer',
  args: {
    patient,
    inpatient_record,
    target_medical_department,
    initiating_service_unit,
    initiating_bed,
    target_service_unit,
    target_bed,
  },
})
