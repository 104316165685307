<template>
  <div style="cursor: pointer">
    <span class="document-id">
      <i
        class="fa fa-clock-o"
        aria-hidden="true"
        style="color: #043927; border: 2px solid #043927"
      />
      <span> {{ recordType }} - {{ recordId }}</span></span>
    <br>
    <b-button
      variant="primary"
      @click="viewTheatreInfo()"
    >
      View More
    </b-button>

  </div>
</template>
<script>
import { commentTheatre } from './service'

export default {
  filters: {
    // format date
    filterDate(value) {
      const year = value.substring(0, 4)
      const month = value.substring(5, 7)
      const day = value.substring(8, 10)
      return `${day}-${month}-${year}`
    },
    filterTime(value) {
      const time = value.substring(11, 16)
      return time
    },

  },
  // data() {
  //   return {};
  // },
  props: {
    recordItems: {
      type: Object,
      default: null,
    },
    recordId: {
      type: String,
      default: '',
      required: true,
    },
    recordType: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      infoModal: {
        item: {},
      },
      comments: [],
      formattedComments: '',
    }
  },
  mounted() {
    this.api({
      method:
          'clinical.api.clinical_procedure.clinical_procedure.get_theatre_schedule',
      args: {
        name: this.recordId,
      },
      callback: r => {
        this.item = r.message[0]
        this.comments = JSON.parse(r.message[0]._comments)
        this.formatComments()
      },
    })
  },
  methods: {
    formatComments() {
      this.comments.forEach(obj => {
        for (const [key, value] of Object.entries(obj)) {
          if (key !== 'name') {
            this.formattedComments += `${key}: ${value}\n`
          }
        }
        this.formattedComments += '\n' // Add an extra newline between objects
      })
    },
    fetchComments(recordId) {
      this.$refs.procedureCommentsModal.show()

      // this.$root.$emit('bv::show::modal', this.infoModal.id);
      // alert(recordId);
    },
    viewTheatreInfo() {
      const parent = this
      const dlg = new frappe.ui.Dialog({
        title: 'Schedule For Theatre',
        fields: [
          {
            label: 'Name',
            fieldname: 'name',
            hidden: 1,
            fieldtype: 'Data',
            default: parent.item.name,
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Patient Information',
          },
          {
            label: 'Patient Number',
            fieldname: 'patient',
            read_only: 1,
            fieldtype: 'Data',
            default: parent.item.patient,
          },
          {
            label: 'Age',
            fieldname: 'age',
            read_only: 1,
            fieldtype: 'Data',
            default: parent.item.age,
          },
          {
            label: 'Current Ward',
            fieldname: 'current_ward',
            read_only: 1,
            fieldtype: 'Link',
            default: parent.item.current_ward,
            options: 'Healthcare Service Unit',
          },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'Patient Name',
            fieldname: 'patient_name',
            read_only: 1,
            fieldtype: 'Data',
            default: parent.item.patient_name,
          },

          {
            label: 'Gender',
            fieldname: 'gender',
            read_only: 1,
            fieldtype: 'Data',
            default: parent.item.gender,
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Patient Medical Info',
          },
          {
            label: 'Diagnosis',
            fieldname: 'diagnosis',
            read_only: 1,
            fieldtype: 'Data',
            default: parent.item.diagnosis,
          },
          {
            label: 'HB',
            fieldname: 'hb',
            read_only: 1,
            default: parent.item.hb,
            fieldtype: 'Check',
          },
          {
            label: 'GXM',
            fieldname: 'gxm',
            read_only: 1,
            default: parent.item.gxm,
            fieldtype: 'Check',
          },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'U/E/C',
            fieldname: 'uec',
            fieldtype: 'Text',
            default: parent.item.uec,
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Scheduling Details',

          },
          {
            label: 'Operation',
            fieldname: 'operation',
            read_only: 1,
            fieldtype: 'Link',
            default: parent.item.operation,
            options: 'Clinical Procedure Template',
          },

          {
            label: 'Surgeon',
            fieldname: 'surgeon',
            read_only: 1,
            fieldtype: 'Link',
            default: parent.item.surgeon,
            options: 'Healthcare Practitioner',
          },
          {
            label: 'Date Time',
            fieldname: 'date',
            read_only: 1,
            fieldtype: 'Datetime',
            default: parent.item.date_time,
            reqd: 1,
          },

          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'Theatre',
            fieldname: 'theatre',
            read_only: 1,
            fieldtype: 'Link',
            default: parent.item.theatre,
            options: 'Healthcare Service Unit',
          },
          {
            label: 'Department',
            fieldname: 'department',
            read_only: 1,
            fieldtype: 'Link',
            default: parent.item.department,
            options: 'Department',
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Additional Details',
          },
          {
            label: 'EL/EM',
            fieldname: 'el_em',
            read_only: 1,
            default: parent.item.elem,
            fieldtype: 'Select',
          },
          {
            label: 'Consent',
            fieldname: 'consent',
            read_only: 1,
            default: parent.item.consent,
            fieldtype: 'Check',
          },
          {
            label: 'Remarks',
            fieldname: 'remarks',
            read_only: 1,
            default: parent.item.remarks,
            fieldtype: 'Text',
          },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'Anae Review',
            fieldname: 'anae_review',
            read_only: 1,
            default: parent.item.anae_review,
            fieldtype: 'Check',
          },
          {
            label: 'Paid',
            fieldname: 'paid',
            read_only: 1,
            default: parent.item.paid,
            fieldtype: 'Check',
          },

          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Comments Details',
          },
          {
            fieldname: 'other_comments',
            fieldtype: 'Text',
            label: 'Participants Comments',
            read_only: 1,
            default: parent.formattedComments,
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: '',
          },
          {
            label: 'Leave a Comment',
            fieldname: 'comment',
            fieldtype: 'Text',
          },

        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          const data = { ...values }
          commentTheatre(data).then(() => {
            this.show_alert(
              {
                message: ('Comment Successfully Added'),
                indicator: 'green',
              },
              5,
            )
            dlg.hide()
          })
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dlg.hide()
        },
      }).show()
      dlg.$wrapper.find('.modal-content').css('width', '1000px')
      dlg.$wrapper.find('.modal-content').css('margin-left', '-200px')
      dlg.$wrapper.find('.modal-content').css('backdrop', 'static')
      dlg.$wrapper.find('.modal-content').css('keyboard', 'false')
    },

  },

}
</script>
