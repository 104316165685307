<template>
  <b-container>
    <b-row
      id="modal-body"
      class="modal-padding render-formx"
    >
      <p style="margin:15px; color:grey">
        Add additional items that need billing, arising from the clinical procedure.
      </p>
    </b-row>
    <b-row>
      <b-button-group style="margin-right:16px;margin-top:4px;">
        <div class="btn-group">
          <button
            style="margin-left:15px;"
            type="button"
            class="btn btn-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Add Item(s)
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              href="#"
              @click="createSalesOrder('Service')"
            >Services</a>
            <a
              class="dropdown-item"
              href="#"
              @click="createSalesOrder('Consumables')"
            >Consumables</a>
          </div>

        </div>
                        &nbsp;
      </b-button-group>
    </b-row>
  </b-container>
</template>

<script>
import { fetchConditionalItemPrice } from '../../service'

export default {
  components: {

  },
  props: {
    patient: {
      type: Object,
      default: ' ',
      required: true,
    },
  },
  data() {
    return {

    }
  },
  watch: {

  },
  mounted() {

  },
  methods: {

    changeListener(dialog, parent, notify) {
      const data = dialog.fields[2].data || []
      let total = 0
      const promises = data.map(value => {
        const { item_code, qty: quantity } = value
        return item_code && fetchConditionalItemPrice({
          item_code,
          customer: parent.patient.customer_number,
        }).then(res => {
          if (res.type === 'Patient') {
            const { price } = res
            const amount = Math.ceil((price * quantity))
            value.rate = price
            value.amount = amount
            total += amount
            value.payer = res.type
            dialog.set_values({
              total,
            })
            if (res.is_nhif === true && notify === true) {
              // frappe.msgprint({
              //     title: ('Note'),
              //     message: ('Patient will be charged for this item due to insufficient insurance balance'),
              //     primary_action: {

              //     }
              // });
            }
          } else if (res.type === 'Copay') {
            const { price } = res
            const amount = Math.ceil((price * ((res.remaining_quantity) * quantity)))
            value.rate = price
            value.amount = amount
            total += amount
            value.payer = `${'Copaid amount: ' + '('}${price * ((res.co_paid_quantity) * quantity)})`
            dialog.set_values({
              total,
            })
          } else if (res.type === 'Insurance') {
            const { price } = res
            const amount = Math.ceil((price * quantity))
            value.rate = price
            value.amount = '0'
            value.payer = `${'Insurance: ' + '('}${amount})`
            dialog.set_values({
              total,
            })
          } else if (res.type === 'Pre-auth Insurance') {
            const { price } = res
            const amount = Math.ceil((price * quantity))
            value.rate = price
            value.amount = '0'
            value.payer = `${'Pre-auth Insurance: ' + '('}${amount})`
            dialog.set_values({
              total,
            })
          } else if (res.type === 'Pre-auth Copay') {
            const { price } = res
            const amount = Math.ceil((price * ((res.remaining_quantity) * quantity)))
            value.rate = price
            value.amount = amount
            total += amount
            value.payer = `${'Pre-auth Copay: ' + '('}${price * ((res.co_paid_quantity) * quantity)})`
            dialog.set_values({
              total,
            })
          }
        })
      })
      Promise.all(promises).then(() => {
        dialog.refresh()
      })
    },

  },
}
</script>

<style scoped>

</style>
