<template>
  <div class="body">
    <div
      v-if="false"
      class="top mb-1"
    >
      <b-button
        size="sm"
        variant="primary"
        @click="toggleCalender"
      >
        {{ view === 'Calender'? 'Go to List': 'Calender View' }}
      </b-button>
    </div>
    <calender-view
      v-if="view === 'Calender'"
      @item-clicked="clickItem"
    />
    <procedure-list v-if="view !== 'Calender'" />
  </div>
</template>

<script>
import CalenderView from './calenderView.vue'
import ProcedureList from './procedureList.vue'

export default {
  components: {
    CalenderView, ProcedureList,
  },
  data() {
    return {
      view: 'List',
    }
  },
  computed: {
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit'].name ? this.$store.getters['serviceUnit/getSelectedServiceUnit'] : (this.serviceUnits.length && this.serviceUnits[0])
    },
    serviceUnits() {
      return this.$store.getters['serviceUnit/getServiceUnits']
    },
    queryParams() {
      return this.$store.getters['procedures/getQueryParams']
    },
  },
  watch: {
    selectedServiceUnit() {
      this.listenSocket(this.selectedServiceUnit)
    },
  },
  mounted() {
    // this.$store.dispatch("procedures/fetchProcedures", {});
    this.listenSocket()
  },
  methods: {
    toggleCalender() {
      this.view = this.view === 'Calender' ? 'List' : 'Calender'
    },
    clickItem(e) {
      this.$store.dispatch('procedures/setQueryParams', { start_date: e.id })
      this.view = 'List'
    },
    listenSocket() {
      const { socket } = window
      this.socket = socket
      socket.on('procedure_scheduling', data => {
        if (this.selectedServiceUnit.service_unit === data.service_unit) {
          this.$store.dispatch('procedures/fetchProcedures', { ...this.queryParams, service_unit: this.selectedServiceUnit.service_unit })
          this.$store.dispatch('procedures/fetchProcedureStatistics', { ...this.queryParams, service_unit: this.selectedServiceUnit.service_unit })
        }
      })
    },
  },

}
</script>

<style scoped>
.top {
  display: flex;
  padding-top: 16px;
  justify-content: flex-end;
}
.body {
  /* padding: 16px; */
  height: 100vh;
  overflow-y: scroll;
}
</style>
