<template>
  <a
    v-show="visible"
    class="bottom-right"
    @click="scrollTop"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'ScrollTopComponent',
  data() {
    return {
      visible: true,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    scrollTop() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    scrollListener(e) {
      console.log(e)
      this.visible = window.scrollY > 150
    },
  },
}
</script>

<style scoped>
.bottom-right {
  position: fixed;
  bottom: 50px;
  right: 20px;
  cursor: pointer;
}
</style>
