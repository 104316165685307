<template>
  <div class="w-100">
    <chartjs-component-line-chart
      class="w-100"
      :height="250"
      :data="data"
      :options="chartjsData.lineChart.options"
      :plugins="plugins"
    />
  </div>
</template>
<script>
import ChartjsComponentLineChart from '@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentLineChart.vue'
import chartjsData from '@/views/charts-and-maps/charts/chartjs/chartjsData'
import {
  getBPData, getRespPulseData, getTemperatureData, getBMIData,
} from './service'

export default {
  components: {
    ChartjsComponentLineChart,
  },
  props: {
    patient: {
      type: String,
      default: ' ',
      required: true,
    },
    data: {
      labels: [],
      datasets: [],
    },

  },
  data() {
    return {
      chartjsData,
      defaultDataset: chartjsData.lineChart.data.datasets[0],
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],

      chartlabel_bp: String,
      chartdata_sbp: String,
      chartdata_dbp: String,

      chartlabel_resp_pulse: String,
      chartdata_heart_rate: String,
      chartdata_resp_rate: String,

      chartlabel_temperature: String,
      chartdata_temperature: String,

      chartlabel_bmi: String,
      chartdata_bmi: String,
      chartdata_height: String,
      chartdata_weight: String,
    }
  },
  mounted() {
    // load the first chart(blood pressure)
    // this.getBPData();
  },
  updated() {
    // load the first chart(blood pressure) when patient is changed
    this.getBPData()
  },
  methods: {
    createBPChart() {
      const labels = this.chartlabel_bp
      const systolic = { ...this.defaultDataset }
      const diastolic = { ...this.defaultDataset }
      diastolic.data = this.chartdata_dbp
      diastolic.label = 'BP Diastolic'
      diastolic.pointHoverBackgroundColor = chartjsData.chartColors.primaryColorShade
      diastolic.backgroundColor = chartjsData.chartColors.primaryColorShade
      diastolic.borderColor = chartjsData.chartColors.primaryColorShade

      systolic.data = this.chartdata_sbp
      systolic.label = 'BP Systolic'
      systolic.pointHoverBackgroundColor = chartjsData.chartColors.successColorShade
      systolic.backgroundColor = chartjsData.chartColors.successColorShade
      systolic.borderColor = chartjsData.chartColors.successColorShade

      this.data = {
        datasets: [systolic, diastolic],
        labels,
      }
    },

    createRespPulseChart() {
      const data = {
        labels: this.chartlabel_resp_pulse,
        datasets: [
          {
            name: 'Heart Rate / Pulse',
            type: 'bar',
            values: this.chartdata_heart_rate,
          },
          {
            name: 'Respiratory Rate',
            type: 'line',
            values: this.chartdata_resp_rate,
          },
        ],
      }

      const chart = new frappe.Chart('#chart', {
        title: 'Respiratory/Pulse Rate',
        data,
        type: 'axis-mixed', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
        height: 250,
        colors: ['#7cd6fd', '#743ee2'],
      })
    },

    createTemperatureChart() {
      const data = {
        labels: this.chartlabel_temperature,
        datasets: [
          {
            name: 'Temperature',
            type: 'bar',
            values: this.chartdata_temperature,
          },
        ],
      }

      const chart = new frappe.Chart('#chart', {
        title: 'Temperature',
        data,
        type: 'axis-mixed', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
        height: 250,
        colors: ['#7cd6fd', '#743ee2'],
      })
    },

    createBMIChart() {
      const data = {
        labels: this.chartlabel_bmi,
        datasets: [
          {
            name: 'BMI',
            type: 'bar',
            values: this.chartdata_bmi,
          },
          {
            name: 'Height',
            type: 'bar',
            values: this.chartdata_height,
          },
          {
            name: 'Weight',
            type: 'bar',
            values: this.chartdata_weight,
          },
        ],
      }

      const chart = new frappe.Chart('#chart', {
        title: 'BMI',
        data,
        type: 'axis-mixed', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
        height: 250,
        colors: ['#7cd6fd', '#ffa3ef', '#743ee2'],
      })
    },

    getBPData() {
      getBPData({
        patient: this.patient,
      }).then(data => {
        this.chartlabel_bp = data.map(item => item.creation)
        this.chartdata_sbp = data.map(item => item.bp_systolic)
        this.chartdata_dbp = data.map(item => item.bp_diastolic)
        // call create chart function
        this.createBPChart()
      })
    },
    getRespPulseData() {
      getRespPulseData({
        patient: this.patient,
      }).then(data => {
        this.chartlabel_resp_pulse = data.map(item => item.creation)
        this.chartdata_heart_rate = data.map(item => item.pulse)
        this.chartdata_resp_rate = data.map(item => item.respiratory_rate)
        // call create chart function
        this.createRespPulseChart()
      })
    },

    getTemperatureData() {
      getTemperatureData({
        patient: this.patient,
      }).then(data => {
        this.chartlabel_temperature = data.map(item => item.creation)
        this.chartdata_temperature = data.map(item => item.temperature)
        // call create chart function
        this.createTemperatureChart()
        console.log(data)
      })
    },

    getBMIData() {
      getBMIData({
        patient: this.patient,
      }).then(data => {
        this.chartlabel_bmi = data.map(item => item.creation)
        this.chartdata_bmi = data.map(item => item.bmi)
        this.chartdata_height = data.map(item => item.height)
        this.chartdata_weight = data.map(item => item.weight)
        // call create chart function
        this.createBMIChart()
        console.log(data)
      })
    },
  },
}
</script>
