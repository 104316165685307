<template>
  <div>
    <span class="document-id">

      <i
        class="fa fa-solid fa-file-pen"
        aria-hidden="true"
        style="color: #56a618; border: 0px solid #3c1361"
      />
      <span> {{ recordType }} - {{ recordId }}</span>
      <div v-html="kardexNotesHtmlString" />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    recordItems: {
      type: Object,
      default: null,
    },
    recordId: {
      type: String,
      default: '',
      required: true,
    },
    recordType: {
      type: String,
      default: '',
      required: true,
    },
    kardexNotesHtmlString: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
