<template>
  <div>
    <b-card
      no-body
      style="border:1px solid lightgrey"
    >
      <b-tabs card>

        <!--------- Book patient modal --------->
        <div>
          <b-modal
            id="book_appointment_modal"
            size="xl"
            hide-footer
          >
            <template #modal-title>
              Book this patient for the next appointment
            </template>
            <div>
              <new-appointment
                :patient-from-chart="selectedPatientDetails.name"
                @passselectedDate="setSelectedDate"
              />
            </div>
          </b-modal>
        </div>

        <!---------- Dicharge form --------->
        <div>
          <b-modal
            id="discharge_patient_modal"
            size="xl"
            hide-footer
          >
            <template #modal-title>
              Patient Dicharge Form
              <p>{{ patient.patient_name }}</p>
            </template>
            <div>
              <div class="row">
                <div class="col">
                  <b-form-select
                    v-model="mortality_status"
                    :options="options"
                    @change="getSelectedMortality"
                  />
                </div>
                <div class="col">

                  <input
                    id="mainCat.merchantId"
                    v-model="bookNextAppointment"
                    type="checkbox"
                    @click="followUpCheckBox"
                  > <label> Book follow up appointment date</label>

                </div>
              </div>
              <div
                v-if="bookNextAppointment"
                class="row"
                style="margin-top:10px;"
              >
                <div class="col">
                  <b-button
                    variant="primary btn-sm"
                    @click="$bvModal.show('book_appointment_modal')"
                  >Book Follow Up Appointment
                  </b-button>
                </div>
                <div class="col">
                  <input
                    v-model="followup_date"
                    type="text"
                    class="form-control"
                    placeholder="Follow Up Date"
                  >
                </div>
              </div>
              <div
                class="row"
                style="margin-top:10px;"
              >
                <div class="col">
                  <b-form-textarea
                    id="textarea"
                    v-model="filled_discharge_notes"
                    placeholder="Discharge Notes"
                    rows="3"
                    max-rows="15"
                  />
                </div>
              </div>
              <div
                class="row"
                style="margin-top:10px;"
              >
                <div class="col" />
                <div class="col">
                  <b-button
                    class="pull-right mr-2 ml-2"
                    variant="primary btn-sm"
                    @click="scheduleDischarge"
                  >Submit
                  </b-button>
                </div>
              </div>
            </div>
          </b-modal>
        </div>

        <!----------Death Notification----------->
        <div>
          <b-modal
            id="death_notification_modal"
            size="xl"
            hide-footer
          >
            <template #modal-title>
              Death Notification
            </template>
            <div>

              <div class="row">
                <div class="col">
                  <label>Deceased Patient</label>
                  <input
                    v-model="patient.patient_name"
                    type="text"
                    class="form-control"
                    placeholder=""
                  >
                </div>
                <div class="col">
                  <label>Practitioner Pronouncing Death</label>
                  <p>{{ encounter.practitioner_name }}</p>
                </div>
              </div>

              <div
                class="row"
                style="margin-top:20px;"
              >
                <div class="col">
                  <b-form-textarea
                    id="textarea"
                    v-model="immediate_cause"
                    placeholder="Immediate Cause"
                    rows="3"
                    max-rows="15"
                  />
                </div>
                <div class="col">
                  <b-form-textarea
                    id="textarea"
                    v-model="underlying_cause"
                    placeholder="Underlying Cause"
                    rows="3"
                    max-rows="15"
                  />
                </div>
              </div>
              <div
                class="row"
                style="margin-top:10px;"
              >
                <div class="col" />
                <div class="col">
                  <b-button
                    class="pull-right mr-2 ml-2"
                    variant="primary btn-sm"
                    @click="sendDeathNotification()"
                  >Submit
                  </b-button>
                </div>
              </div>
            </div>
          </b-modal>

        </div>
        <b-tab
          title="Summary"
          active
        >

          <div
            v-if="!fromTimeline"
            class="row justify-content-end"
          >
            <div class="col-2">
              <b-button
                class="float-end"
                variant="light btn-sm"
                @click="printCustomDischargeSummary()"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                /> Print
              </b-button>
            </div>
          </div>

          <b-row v-if="!fromTimeline">
            <b-button
              v-if="patient.status=='Admitted' && patient.deceased==0"
              id="dropdownbtnitem"
              class="pull-right mr-2 ml-2"
              style="margin-bottom:10px;"
              variant="primary btn-sm"
              @click="$bvModal.show('discharge_patient_modal')"
            >
              Schedule Discharge
            </b-button>
            <!-- <b-button v-if="patient.status=='Discharge Scheduled'"  id="dropdownbtnitem" class="pull-right mr-2 ml-2" style="margin-bottom:10px;" variant="success btn-sm" @click="manuallyDischarge">
                               Discharge and Vacate Bed
            </b-button> -->
            <!-- <b-button v-if="patient.status=='Admission Scheduled'"  id="dropdownbtnitem" class="pull-right mr-2 ml-2" style="margin-bottom:10px;" variant="primary btn-sm" @click="manuallyDischarge">
                               Mark as Discharged
            </b-button> -->
            <b-button
              v-if="patient.deceased==1"
              id="dropdownbtnitem"
              class="pull-right mr-2 ml-2"
              style="margin-bottom:10px;"
              variant="success btn-sm"
              @click="manuallyDischarge"
            >
              Release Body
            </b-button>
          </b-row>

          <div v-if="patient.deceased==0">
            <b><p style="margin-top:20px:;color:#2490ef">Discharge Notes</p></b>

            <b-row style="border-radius: 15px;border: 1px solid #c5c5c5;padding:20px;margin-bottom:10px;margin-left:1px;margin-right:5px;">
              <b-col>

                <p>{{ discharge_notes || "There are no recent notes for this patient" }}</p>

              </b-col>
            </b-row>
          </div>

          <div v-if="patient.deceased==1">
            <b><p style="margin-top:20px:;color:#2490ef">Death Notification:</p></b>

            <b-row style="border-radius: 15px;border: 1px solid #c5c5c5;padding:20px;margin-bottom:10px;margin-left:1px;margin-right:5px;">
              <b-col>

                <p>Immediate Cause: {{ immediate_cause || "There is no immediate cause of death indicated" }}</p>
                <p>Underlying Cause: {{ underlying_cause || "There is no underlying cause of death indicated" }}</p>

              </b-col>
            </b-row>
          </div>

          <b><p style="margin-top:20px:;color:#2490ef">Recent Inpatient Medications</p></b>

          <b-row
            v-for="medication_item in this.medications"
            :key="medication_item.name"
            style="border-radius: 15px;border: 1px solid #c5c5c5;padding:20px;margin-bottom:10px;margin-left:5px;margin-right:5px;"
          >
            <b-col>
              <b><p>{{ medication_item.drug.toString()+" - "+medication_item.instructions.toString() }}</p></b>
              <p />
              <table style="width:100%;">
                <thead>
                  <tr style="color:#a4a433">
                    <th style="font-weight:normal">
                      Route of Administration
                    </th>
                    <th style="font-weight:normal">
                      Duration
                    </th>
                    <th style="font-weight:normal">
                      Frequency
                    </th>
                    <th style="font-weight:normal">
                      Start Date
                    </th>
                  </tr>
                </thead>
                <tr>
                  <td>{{ medication_item.route_of_administration }}</td>
                  <td>{{ medication_item.duration }}</td>
                  <td>{{ medication_item.frequency }}</td>
                  <td>{{ medication_item.start_date.toLocaleString('en-US') }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row
            v-if="medications.length<1"
            style="border-radius: 15px;border: 1px solid #c5c5c5;padding:20px;margin-bottom:10px;margin-left:5px;margin-right:5px;"
          >
            <b-col>
              <p>There are no inpatient medications for this patient yet.</p>
            </b-col>
          </b-row>

          <b><p style="margin-top:20px:;color:#2490ef">Recent Clinical Procedures</p></b>

          <b-row
            v-for="procedure_item in this.my_procedures"
            :key="procedure_item.name"
            style="border-radius: 15px;border: 1px solid #c5c5c5;padding:20px;margin-bottom:10px;margin-left:5px;margin-right:5px;"
          >
            <b-col>
              <b><p>{{ procedure_item.title }}</p></b>
              <div
                v-for="notes in procedure_item.procedure_notes"
                :key="notes.name"
                style="border-radius: 15px;background-color:#eceeee;padding:25px;margin-bottom:10px;margin-left:5px;margin-right:5px;"
              >

                <p>{{ notes.content.replace(/<[^>]*>?/gm, '') }}</p>
                <b><p style="float:right;font-size:12px;">{{ "~ "+notes.owner_name }}</p></b>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="my_procedures.length<1"
            style="border-radius: 15px;border: 1px solid #c5c5c5;padding:20px;margin-bottom:10px;margin-left:5px;margin-right:5px;"
          >
            <b-col>
              <p>There are no clinical procedures for this patient yet.</p>
            </b-col>
          </b-row>

          <b><p style="margin-top:10px:;color:#2490ef">Recent Lab Tests</p></b>
          <b-row
            v-for="test in this.lab_tests"
            :key="test.name"
            style="border-radius: 15px;border: 1px solid #c5c5c5;padding:20px;margin-bottom:10px;margin-left:5px;margin-right:5px;"
          >
            <b-col>
              <b><p>{{ test.lab_test_name }}</p></b>
              <p />
              <table style="width:100%;">
                <thead>
                  <tr style="color:#a4a433">
                    <th style="font-weight:normal">
                      Result Value
                    </th>
                    <th style="font-weight:normal">
                      Result Value
                    </th>
                    <th style="font-weight:normal">
                      Unit Of Measure
                    </th>
                    <th style="font-weight:normal">
                      Normal Range
                    </th>
                  </tr>
                </thead>
                <tr
                  v-for="result in test.results_attachment"
                  :key="result.parent"
                >
                  <td>{{ result.result_value }}</td>
                  <td>{{ result.lab_test_uom }}</td>
                  <td>{{ result.normal_range }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row
            v-if="lab_tests.length<1"
            style="border-radius: 15px;border: 1px solid #c5c5c5;padding:20px;margin-bottom:10px;margin-left:5px;margin-right:5px;"
          >
            <b-col>
              <p>There are no lab tests for this patient yet.</p>
            </b-col>
          </b-row>

          <b><p style="margin-top:20px:;color:#2490ef">Recent Doctors Notes</p></b>
          <b-row style="border-radius: 15px;border: 1px solid #c5c5c5;padding:20px;margin-bottom:10px;margin-left:1px;margin-right:5px;">

            <b-col v-if="doctors_notes.length>0">
              <div style="border-radius: 15px;background-color:#eceeee;padding:25px;margin-bottom:10px;margin-left:5px;margin-right:5px;">
                <b><p>Subjective Notes</p></b>
                <p v-html="doctors_notes[0].subjective" />
              </div>
              <div style="border-radius: 15px;background-color:#eceeee;padding:25px;margin-bottom:10px;margin-left:5px;margin-right:5px;">
                <b><p>Examination Notes</p></b>
                <p v-html="doctors_notes[0].objective" />
              </div>
              <div style="border-radius: 15px;background-color:#eceeee;padding:25px;margin-bottom:10px;margin-left:5px;margin-right:5px;">
                <b><p>Assessment Notes</p></b>
                <p v-html="doctors_notes[0].assessment" />
              </div>
              <div style="border-radius: 15px;background-color:#eceeee;padding:25px;margin-bottom:10px;margin-left:5px;margin-right:5px;">
                <b><p>Plan Notes</p></b>
                <p v-html="doctors_notes[0].plan" />
              </div>
            </b-col>
            <b-col v-else>
              <p>There are no recent doctors notes for this patient.</p>
            </b-col>

          </b-row>

        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import NewAppointment from '@/views/erp/records/appointment/BookAppointment.vue'
import {
  getInpatientMedications, getInpatientClinicalProcedures, getInpatientLabTests, getInpatientDischargeNotes, getGetCauseOfDeath, getInpatientDoctorsNotes, createInpatientTransfer, createPatientEncounter, scheduleDischarge, createDeathNotification, manuallyDischargeAndVacateBed,
} from './services'

// import {
// createPatientEncounter,
// getDraftPatientEncounters,
// } from "../services/patient_chart/api.js";
export default {
  name: 'Discharge',
  components: {
    NewAppointment,
  },
  props: {
    patient: { type: Object },
    fromTimeline: { type: String },
  },
  data() {
    return {
      my_procedures: [],
      medications: [],
      lab_tests: [],
      discharge_notes: '',
      doctors_notes: '',
      filled_discharge_notes: '',
      followup_date: null,
      mortality_status: null,
      deceased_patient: '',
      immediate_cause: '',
      underlying_cause: '',
      options: [
        { value: null, text: 'Select Discharge Status' },
        { value: 'Alive', text: 'Alive' },
        { value: 'Dead', text: 'Deceased' },
      ],
      bookNextAppointment: false,

    }
  },
  computed: {
    patient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    encounter() {
      return this.$store.getters['encounter/getEncounter']
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    selectedPatientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
  },
  watch: {
    patient() {
      this.fetchSummary(this.patient.patient, this.patient.name)
    },
  },

  created() {
    this.deceased_patient = this.patient.patient_name
    this.fetchSummary(this.patient.patient, this.patient.name)
  },
  mounted() {
    console.log('Discharge Mounted')
  },

  methods: {
    getPatientEncounter(patient) {
      if (patient) {
        getDraftPatientEncounters(patient).then(encounter => this.updateEncounter(encounter))
      }
    },
    createEncounter() {
      this.msgprint('Encounter is being created in the background. Meanwhile, please proceed.')
      createPatientEncounter(this.patient.patient).then(encounter => this.updateEncounter(encounter))
    },
    updateEncounter(encounter) {
      this.$store.dispatch('encounter/setEncounter', encounter)
      this.reloadClock()
    },
    followUpCheckBox() {
      if (this.bookNextAppointment == true) {
        this.bookNextAppointment = false
      } else {
        this.bookNextAppointment = true
      }
    },
    onDischargeModalClose() {
      this.$bvModal.hide('discharge_patient_modal')
    },
    onDeathNotificationModalClose() {
      this.$bvModal.show('death_notification_modal')
    },
    printCustomDischargeSummary() {
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
                + 'doctype='}${encodeURIComponent('Inpatient Record')
      }&name=${encodeURIComponent(this.patient.name)
      }&format=${encodeURIComponent('Custom 2 Discharge')
      }&_lang=en&no_letterhead=0&letterhead=MTRH%20Default&settings=%7B%7D&trigger_print=1`))
      //  var w = window.open(frappe.urllib.get_full_url(`app/print/Sales Invoice/${invoiceName}` //+
      //     // "doctype=" + encodeURIComponent('Invoice Finalization') +
      //     // "&name=" + encodeURIComponent(finalInvoiceItem.name) Custom Discharge Summary
      // patient.status !='Admitted' && ));
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    setSelectedDate(date) {
      this.followup_date = date
    },
    fetchSummary(patient, inpatient_record) {
      getInpatientMedications(patient, inpatient_record).then(res => {
        this.medications = res
      })
      getInpatientClinicalProcedures(patient, inpatient_record).then(res => {
        this.my_procedures = res
      })
      getInpatientLabTests(patient, inpatient_record).then(res => {
        this.lab_tests = res
      })
      getInpatientDischargeNotes(patient, inpatient_record).then(res => {
        this.discharge_notes = res
      })
      getGetCauseOfDeath(patient).then(res => {
        // this.discharge_notes = res
        this.immediate_cause = res.immediate_cause
        this.underlying_cause = res.underlying_cause
      })
      getInpatientDoctorsNotes(patient, inpatient_record).then(res => {
        this.doctors_notes = res
      })
    },

    getSelectedMortality(status) {
      if (status == 'Dead') {
        // createPatientEncounter(this.patient.patient).then((val) => {})
        // this.createEncounter();
        if (this.encounter && this.encounter.name != null) {
          this.$bvModal.show('death_notification_modal')
          this.$bvModal.hide('discharge_patient_modal')
        } else {
          // this.createEncounter();
          this.mortality_status = null
          this.$bvModal.hide('discharge_patient_modal')
          this.msgprint({
            title: ('Notification'),
            indicator: 'orange',
            message: ('Create an encounter before proceeding to create a death notification'),
          })
        }
      }
    },
    sendDeathNotification() {
      createDeathNotification({
        patient: this.patient.patient,
        immediate_cause: this.immediate_cause,
        underlying_cause: this.underlying_cause,
        pract: this.encounter.practitioner,
      }).then(res => {
        this.createTransferToFarewell()
        this.$bvModal.hide('death_notification_modal')
      })
    },
    createTransferToFarewell() {
      createInpatientTransfer({
        patient: this.patient.patient,
        inpatient_record: this.patient.name,
        target_medical_department: 'Farewell Home',
        initiating_service_unit: this.selectedServiceUnit.service_unit,
        initiating_bed: this.patient.bed,
      }).then(res => {
        const payload = {
          serviceUnit: this.selectedServiceUnit.service_unit, servicePoint: '', search: '', work: 'patient/component/discharge2',
        }
        this.$store.dispatch('patient/fetchPatients', payload)
        this.msgprint({
          title: ('Notification'),
          indicator: 'green',
          message: ('Transfer to farewell home successfully initiated'),
        })
        dialog.hide()
      })
    },
    scheduleDischarge() {
      const args = {
        patient: this.patient.patient,
        discharge_encounter: this.encounter.name,
        discharge_practitioner: this.encounter.practitioner,
        discharge_ordered_date: frappe.datetime.now_datetime(),
        discharge_ordered_datetime: frappe.datetime.now_datetime(),
        followup_date: this.followup_date,
        discharge_instructions: '',
        discharge_note: this.filled_discharge_notes,
      }
      scheduleDischarge(args).then(r => {
        if ((this.followup_date == null && this.bookNextAppointment == true) || this.filled_discharge_notes == '') {
          this.show_alert({
            message: ('Ensure next appointment has been booked and the discharge notes filled'),
            indicator: 'red',
          },
          5)
        } else {
          this.fetchSummary(this.patient.patient, this.patient.name)
          this.patient.status = 'Discharge Scheduled'
          const payload = {
            serviceUnit: this.selectedServiceUnit.service_unit, servicePoint: '', search: '', work: 'patient/component/discharge',
          }
          this.$store.dispatch('patient/fetchPatients', payload)
          this.show_alert({
            message: ('Discharge Scheduled'),
            indicator: 'green',
          },
          5)
        }
      })
    },
    manuallyDischarge() {
      manuallyDischargeAndVacateBed(this.patient.name, this.patient.patient).then(res => {
        this.msgprint({
          title: ('Notification'),
          indicator: 'green',
          message: ('Patient successfully discharged and bed vacated'),
        })
      })
    },
  },

}

</script>

<style scoped>

 custom-control-label:after{
     background-color:red;
}
.card {
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%);
    transition: 0.0s;
    min-height: calc(100vh - 265px);
}

.tab-content-height {
  min-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: calc(100vh - 225px);
}
.tab-content-height-expanded {
  min-height: 200px;
  overflow-y: scroll;
  scrollbar-width: thin;
  overflow-x: hidden;
  height: calc(100vh - 225px);
}
.spinner-grow-sm {
  width: 7px;
  height: 7px;
  margin-bottom: 3px;
}
.spinner-grow {
  display: inline-block;
  width: 7px;
  height: 7px;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 1;
  -webkit-animation: spinner-grow 0.75s linear 1;
  animation: spinner-grow 0.75s linear 1;
}
.scroll-to-top {
  position: absolute;
  right: 34px;
  bottom: 24px;
  z-index: 1000;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.1);
  font-size: large;
}

#button {
  display: inline-block;
  background-color: #ff9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  opacity: 1;
  visibility: hidden;
  z-index: 1000;
}
.primary {
  color: #ff9800;
}
</style>
