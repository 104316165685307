<template>
  <div>

    <b-row align-h="end">
      <!-- <b-button @click="fetchItemGroups()" variant="primary"><i class="fa fa-plus-circle" aria-hidden="true"></i> Add</b-button>
            &nbsp; -->
      <b-button
        v-b-modal.modal-1
        size="sm"
        variant="primary"
        class="mr-1 mb-1"
      ><i
        class="fa fa-plus-circle"
        aria-hidden="true"
      /> Add</b-button>
            &nbsp;

      <b-button
        v-if="is_inpatient == 0 && sessionUser == 'admindoctor@mtrh.go.ke'"
        v-b-modal.modal-1
        class="mr-1 mb-1"
        size="sm"
        variant="primary"
      ><i
        class="fa fa-plus-circle"
        aria-hidden="true"
      /> Add
        Multiple</b-button>
            &nbsp;

      <b-button
        class="mb-1"
        size="sm"
        variant="primary"
        @click="printPrescription()"
      ><i
        class="fa fa-print"
        aria-hidden="true"
      />Print</b-button>
    </b-row>
    <b-modal
      id="modal-1"
      ok-title="Submit"
      size="lg"
      title="Add a Prescription"
      @ok="submitPrescription()"
    >
      <!-- {{ patient }} -->
      <prescription @fields-change="changeFields" />
    </b-modal>

    <b-table
      :show-empty="true"
      :empty-text="`Items will be shown here`"
      :empty-filtered-text="`No items to show`"
      small
      :v-if="!!(encounter.doctors_drug_prescription && encounter.doctors_drug_prescription.length > 0)"
      :fields="fields"
      class="table"
      stacked="md"
      bordered
      :items="encounter.doctors_drug_prescription"
    >
      <template #cell(action)="row">
        <b-button
          size="sm"
          class="sm"
          variant="danger"
          @click="removePrescriptionAction(row.item.name)"
        > Cancel
        </b-button>
      </template>
    </b-table>
    <br>
    <hr>
    <div>
      <strong>PREVIOUS</strong>
    </div>
    <b-table
      :show-empty="true"
      :empty-text="`Items will be shown here`"
      :empty-filtered-text="`No items to show`"
      small
      :fields="fields"
      class="table"
      stacked="md"
      striped
      hover
      :items="prescriptions"
    >
      <template #cell(action)="row">
        <b-button
          v-if="!row.item.is_stopped"
          size="sm"
          variant="danger"
          @click="removePrescriptionAction(row.item.name)"
        > Stop </b-button>
      </template>
    </b-table>
    <div class="mt-1">
      <strong>STOPPED</strong>
      <b-table
        :fields="fields"
        class="table"
        stacked="md"
        small
        striped
        hover
        :items="stopped"
      >
        <template #cell(action)="row">
          <b-button
            v-if="!row.item.is_stopped"
            size="sm"
            class="sm"
            variant="danger"
            @click="removePrescriptionAction(row.item.name)"
          > Stop </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {
  getOrderedItemPrices, getDoctorsPrescriptions, removePrescription, api, createDoctorsPrescription, getDrugMappings, getDrugGroups,
} from './service'
import Prescription from './PrescriptionModal2.vue'

export default {
  name: 'EncounterMedication',
  components: {
    Prescription,
  },
  props: {
    patient: {
      default: '',
      type: String,
      required: true,
    },
    walkin: {
      type: Number,
      default: 0,
      required: false,
    },
    is_inpatient: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      prescriptions: [],
      stopped: [],
      fields: ['drug_name', { key: 'doctor_dose', label: 'Dosage' }, 'route_of_administration', 'duration', 'instructions', { key: 'creation', label: 'Ordered on', formatter: (value, key, item) => this.formatDate(item.creation, 'yyyy-MM-dd HH:mm:ss.SSS') }, { label: 'Actions', key: 'action' }],
      medicationItemGroups: [],
      loading: false,
      priceInformation: [],
      itemGroups: [],
      prescriptionValues: {},
    }
  },
  computed: {
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    sessionUser() {
      return JSON.parse(localStorage.getItem('userData')).email
    },
    encounter() {
      return this.$store.getters['encounter/getEncounter']
    },
  },
  watch: {
    patient() {
      getDoctorsPrescriptions(this.patient).then(values => {
        this.prescriptions = values.filter(pres => !pres.is_stopped)
        this.stopped = values.filter(pres => pres.is_stopped)
      })
    },
  },
  mounted() {
    getDoctorsPrescriptions(this.patient).then(prescriptions => {
      this.prescriptions = prescriptions.filter(pres => !pres.is_stopped)
      this.stopped = prescriptions.filter(pres => pres.is_stopped)
    })
    getDrugGroups().then(data => {
      this.itemGroups = data
    })
  },
  methods: {
    createMultipleModal() {
      let dialog
      const parent = this
      const fields = [{
        label: 'Item',
        fieldname: 'item_code',
        fieldtype: 'Link',
        options: 'Item',
        reqd: true,
        in_list_view: 1,
        filters: type === 'Service' ? {
          is_stock_item: 0, is_private_wing: 0, disabled: 0, has_variants: 0, item_group: ['NOT IN', parent.excludedGroups],
        } : 'ServicePW' ? {
          is_stock_item: 0, is_private_wing: 1, disabled: 0, has_variants: 0, item_group: ['NOT IN', parent.excludedGroups],
        } : {
          is_stock_item: 1, disabled: 0, has_variants: 0, item_group: ['NOT IN', parent.excludedGroups],
        },
        onchange(e) {
          const notify = true
          parent.changeListener(dialog, parent, notify)
        },
      },
      ]
    },
    changeFields(item) {
      this.prescriptionValues = item
    },
    openNewModal() {

    },
    onChange(dialog) {
      const drug = dialog.fields_dict.drug_name.value
      const dose = dialog.fields_dict.doctor_dose.value
      const duration = dialog.fields_dict.duration.value
      const frequency = dialog.fields_dict.frequency.value
      const administration = dialog.fields_dict.route_of_administration.value
      const instructions = `${administration} ${drug} ${dose} for ${duration} ${frequency}`
      dialog.fields_dict.instructions.set_value(instructions)
      dialog.fields_dict.instructions.refresh()
    },
    calculateTotals() {
      let totals = 0
      this.encounter.drug_prescription.forEach((item, index) => {
        totals += item.total_price
      })
      return totals.toFixed(2)
    },
    getItemPrices() {
      const item_code_list = this.encounter.drug_prescription.map(item => ({ item_code: item.item_code }))
      getOrderedItemPrices({ item_code_list, patient_name: this.patient, service_unit }).then(values => {
        this.priceInformation = values
      })
    },
    printPrescription() {
      const me = this
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
                + 'doctype='}${encodeURIComponent('Patient Encounter')
      }&name=${encodeURIComponent(me.encounter.name)
      }&format=Encounter Prescription Print Format&_lang=en&trigger_print=1`))
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    fetchItemGroups() {
      api({
        method: 'clinical.api.prescription.prescription.get_is_prescription_item',
        args: {
          // doctype: 'Item Group',
          // async: false,
          // filters: { is_prescription_item: 1 }
        },

      }).then(res => {
        this.medicationItemGroups = res
        this.openModal()
      })
    },
    openModal() {
      const parent = this
      const fields = [{
        label: 'Start Date',
        fieldname: 'start_date',
        fieldtype: 'Date',
        reqd: true,
        default: frappe.datetime.get_today(),
      }, {
        label: 'Drug',
        fieldname: 'drug',
        fieldtype: 'Link',
        options: 'Item',
        reqd: true,
        filters: { item_group: ['in', parent.itemGroups], has_variants: 1 },
      }, {
        label: 'Drug Name',
        fieldname: 'drug_name',
        fetch_from: 'drug.item_name',
        fieldtype: 'Data',
        reqd: true,
        read_only: 1,
      }, {
        label: 'Dose',
        fieldname: 'doctor_dose',
        fieldtype: 'Data',
        reqd: true,
      }, {
        label: 'Formulation',
        fieldname: 'dose',
        fieldtype: 'Select',
        options: [],
        reqd: true,
      }, {
        label: 'Route of Administration',
        fieldname: 'route_of_administration',
        fieldtype: 'Link',
        options: 'Dosage Form',
      }, {
        label: 'Period',
        fieldname: 'duration',
        options: 'Prescription Duration',
        fieldtype: 'Link',
        reqd: true,
      },
      {
        label: 'Frequency',
        fieldname: 'frequency',
        fieldtype: 'Link',
        options: 'Prescription Dosage',
        reqd: true,
      },
      {
        label: 'Instructions',
        fieldname: 'instructions',
        fieldtype: 'Small Text',
      },
      ]

      const me = this
      const dialog = new frappe.ui.Dialog({
        title: 'Add a Prescription',
        fields,
        primary_action_label: 'Submit',
        primary_action: values => {
          me.loading = true
          //   dialog.get_primary_btn().attr("disabled", true);
          if (me.walkin === 0 && !me.selectedServiceUnit.service_unit) {
            return alert('Please Select a Service unit from the sidebar')
          }
          createDoctorsPrescription({
            ...values, patient_name: me.patient, encounter: me.encounter.name, patient: me.patient, service_unit: me.selectedServiceUnit.service_unit,
          }).then(
            encounter => {
              me.updateEncounter(encounter)
              alert(JSON.stringify(encounter.doctors_drug_prescription))
              me.prescriptions = encounter.doctors_drug_prescription;

              me.loading = false
              this.show_alert({
                message: 'Prescription Saved',
                indicator: 'green',
              }, 5)
            },
          )
        },
      })

      dialog.fields_dict.drug.df.onchange = e => {
        if (dialog.fields_dict.drug.value) {
          getDrugMappings({ drug: dialog.fields_dict.drug.value })
            .then(data => {
              const options = data.map(({ strength, dosage_form }) => `${strength}~${dosage_form}`)
              dialog.set_df_property('dose', 'options', options)
              if (options.length) {
                dialog.set_value('dose', options[0])
                dialog.refresh()
              }
            })
          this.docGetValue('Item', dialog.fields_dict.drug.value, 'item_name').then(({ message }) => {
            dialog.set_value('drug_name', message.item_name)
            dialog.refresh()
          })
          // console.log(val);
        }
        this.onChange(dialog)
      }
      dialog.fields_dict.dose.df.onchange = () => {
        const { value } = dialog.fields_dict.dose
        const dosageForm = value.split('~')[1]
        // di
        dialog.fields_dict.route_of_administration.set_value(dosageForm)
        dialog.fields_dict.route_of_administration.refresh()
        this.onChange(dialog)
      }
      dialog.fields_dict.frequency.df.onchange = () => {
        this.onChange(dialog)
      }
      dialog.fields_dict.duration.df.onchange = () => {
        this.onChange(dialog)
      }
      dialog.show()
    },
    removePrescriptionAction(name) {
      removePrescription(this.encounter.name, name)
        .then(encounter => {
          this.$emit('encounterUpdated', encounter)
          getDoctorsPrescriptions(this.patient).then(values => {
            this.prescriptions = values
          })
        })
    },
    async submitPrescription() {
      const me = this
      const values = this.prescriptionValues
      values.is_walkin = me.walkin === 1
      me.loading = true
      if (me.walkin === 0 && !me.selectedServiceUnit.service_unit) {
        return alert('Please Select a Service unit from the sidebar')
      }

      if (this.$route.params.id && me.walkin === 1 && this.patient =='') {
        this.patient = this.$route.params.id
      }
      createDoctorsPrescription({
        ...values, is_discharge_medication: values.is_discharge_medication === 'Yes' ? 1 : undefined, patient_name: me.patient, encounter: me.encounter.name, patient: me.patient, service_unit: me.selectedServiceUnit.service_unit,
      }).then(
        encounter => {
          me.updateEncounter(encounter)
          me.loading = false
          this.show_alert('Prescription Saved')
        },
      )
    },
    updateEncounter(encounter) {
      this.$emit('encounterUpdated', encounter)
    },
  },
}
</script>

<style scoped>
.flex-end {
    display: flex;
    justify-content: flex-end;
}

svg {
    width: 14px !important;
}</style>
