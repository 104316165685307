import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const rejectPatientTransfer = ({ inpatient_transfer, status }) => api({
  method: 'clinical.api.patients.update_inpatient_transfer',
  args: {
    inpatient_transfer,
    status,
  },
})
