import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const scheduleProcedure = args => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.schedule_procedure',
  args,
})

export const getOrderedItemPrices = ({
  item_code_list,
  patient_name,
  service_unit,
}) => api({
  method: 'billing.billing.utils.item_prices.get_patient_ordered_item_prices',
  args: { item_code_list, patient_name, service_unit },
})

export const editClinicalProcedure = args => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.edit_clinical_procedure',
  freeze: false,
  args,
})

export const commentTheatre = payload => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.add_theatre_schedule_comment',
  freeze: false,
  args: {
    payload,
  },
})

export const getClinicalProcedure = (procedure, args) => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.get_procedure',
  freeze: false,
  args: {
    procedure_name: procedure,
  },
})

export const getPatientNameAndCustomerNumber = patient => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.get_patient_name_and_number',
  freeze: false,
  args: {
    patient,
  },
})

export const addComment = args => api({
  method: 'clinical.api.orders.prescriptions.add_comment',
  args,
})

export const getDraftPatientEncounters = (patient, source = '', service_unit = {}) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.get_draft_patient_encounter',
  args: {
    patient,
    source,
    service_unit,
  },
})

export const createPatientEncounter = (patient, is_walk_in) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.create_patient_encounter',
  freeze: false,
  args: {
    patient,
    is_walk_in,
  },
})

export const startOrFinishprocedure = (procedure, status = 'Start') => api({
  method: 'clinical.api.orders.prescriptions.start_finish_procedure',
  args: {
    procedure,
    status,
  },
})

export const createProcedureChargesDraft = (procedure, procedure_template) => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.create_procedure_charges_draft',
  args: {
    procedure,
    procedure_template,
  },
})

export const submitAndBillProcedure = (document, name, is_emergency, origin, service_unit) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.submit_clinical_procedure',
  args: {
    document,
    name,
    is_emergency,
    origin,
    service_unit,
  },
})

export const fetchConditionalItemPrice = ({
  item_code,
  customer,
  patient,
  warehouse = '',
  qty = 1,
}) => api({
  method: 'billing.billing.api.sales_orders.sales_order_endpoint.fetch_conditional_item_price',
  args: {
    item_code,
    customer,
    patient,
    warehouse,
    qty,
  },
})
