<template>
  <div>
    <!-- {{ procedures }} -->

    <b-modal
      v-model="editModalVisible"
      title="Edit Clinical Procedure"
      @ok="submitEditProcedure"
    >
      <b-form>
        <b-form-group
          label="Clinical Procedure"
          label-for="edit_procedure"
        >
          <doctype-link
            :doctype="'Clinical Procedure Template'"
            :placeholder="edit_procedure || 'Search clinical procedure'"
            @selected="res=> edit_procedure = res"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-table
      :empty-text="`Procedures will be listed here `"
      :show-empty="true"
      class="table"
      :fields="fields"
      stacked="md"
      small
      bordered
      hover
      :items="procedures"
    >
      <template #cell(creation)="row">
        {{ row.item.creation | dateFormat }}
      </template>
      <template #cell(patient)="row">
        {{ row.item.patient }} <b-badge
          style="border:1px solid gray"
          pill
          variant="primary"
        >{{ row.item.age + "(" +
          row.item.gender.charAt(0) + ")" }}</b-badge>
      </template>
      <template #cell(action)="row">

        <b-dropdown
          id="dropdown-1"
          split
          split-variant="outline-secondary"
          size="sm"
          text="Actions"
        >
          <b-dropdown-item
            :variant="row.item.variant || 'primary'"
            :disabled="false"
            style="min-width: 100px"
            @click="click({ item: row, event: $event.target })"
          >
            {{ row.item.buttonText || actionText }}</b-dropdown-item>
          <b-dropdown-item @click="openEditModal(row.item)">
            Edit Procedure
          </b-dropdown-item>

          <b-dropdown-item
            :disabled="row.item.disabled"
            @click="reschedule({ item: row, event: $event.target })"
          >Reschedule</b-dropdown-item>
          <b-dropdown-item @click="checkCost(row.item)">
            Display Costs
          </b-dropdown-item>

        </b-dropdown>
        <!-- <b-button size="sm" :variant="row.item.variant || 'primary'" :disabled="row.item.disabled"
          @click="click({ item: row, event: $event.target })" style="min-width: 100px">
          {{ row.item.buttonText || actionText }}
        </b-button>
        <b-button size="sm" @click="openEditModal(row.item)">
          Edit Procedure
        </b-button> -->
      </template>
      <!-- <template #cell(reschedule)="row">
        <b-button
        :variant="'primary'"
          style="min-width: 100px"
          @click="reschedule({item: row, event: $event.target})"
          :disabled="row.item.disabled"
        >
          Reschedule
        </b-button>
      </template> -->

      <template #cell(Costs)="row">
        <b-button
          size="sm"
          variant="primary"
          style="min-width: 100px"
          @click="checkCost(row.item)"
        >
          Cost
        </b-button>
      </template>
      <template #cell(Theatre)="row">
        <b-button
          v-show="row.item.is_theatre"
          variant="success"
          style="min-width: 100px"
          @click="viewTheatreInfo(row.item)"
        >View More
        </b-button>
      </template>
      <template #cell(More)="row">
        <b-dropdown
          text="Actions"
          variant="primary"
        >
          <b-dropdown-item
            :disabled="row.item.disabled"
            @click="reschedule({ item: row, event: $event.target })"
          >Reschedule</b-dropdown-item>
          <b-dropdown-item @click="checkCost(row.item)">
            Display Costs
          </b-dropdown-item>

        </b-dropdown>
      </template>
    </b-table>
    <div class="pagination">
      <b-pagination
        v-model="paginationMeta.page"
        variant="primary"
        :total-rows="paginationMeta.total_count"
        :per-page="paginationMeta.per_page"
        first-number
        last-number
        @change="setPage"
      />
    </div>
  </div>
</template>
<script>
import { getOrderedItemPrices, editClinicalProcedure, commentTheatre } from '../../service'

export default {
  name: 'ProcedureTable',
  filters: {
    // Filter definitions
    dateFormat(date) {
      const current_datetime = new Date(date)
      return `${current_datetime.getFullYear()}-${current_datetime.getMonth() + 1}-${current_datetime.getDate()} ${
        current_datetime.getHours()}:${current_datetime.getMinutes()}:${current_datetime.getSeconds()}`
    },
  },
  props: {
    procedures: Array,
    fields: Array,
    actionText: String,
  },
  data() {
    return {
      editModalVisible: false,
      edit_procedure: null,
      edit_item: null,
    }
  },
  computed: {
    queryParams() {
      return this.$store.getters['procedures/getQueryParams']
    },
    paginationMeta() {
      return this.$store.getters['procedures/getPaginationMeta']
    },

    // getPaginationMeta
  },
  methods: {

    openEditModal(item) {
      this.edit_procedure = item.procedure_template
      this.edit_item = item
      this.editModalVisible = true
    },
    cancelEditProcedure() {
      this.editModalVisible = false
    },
    submitEditProcedure() {
      const apiData = {
        name: this.edit_item.name,
        template: this.edit_procedure,
      }

      editClinicalProcedure(apiData)
        .then(() => {
          this.edit_item.procedure_template = this.edit_procedure
          this.msgprint('Procedure updated successfully')
        })
        .catch(error => {
          console.error('Error updating clinical procedure:', error)
        })
        .finally(() => {
          // Hide the edit modal after the operation is complete
          this.editModalVisible = false
        })
    },
    viewTheatreInfo(item) {
      const parent = this
      const dlg = new frappe.ui.Dialog({
        title: 'Schedule For Theatre',
        fields: [
          {
            label: 'Name',
            fieldname: 'name',
            hidden: 1,
            fieldtype: 'Data',
            default: item.theatre_info[0].name,
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Patient Information',
          },
          {
            label: 'Patient Number',
            fieldname: 'patient',
            read_only: 1,
            fieldtype: 'Data',
            default: item.theatre_info[0].patient,
          },
          {
            label: 'Age',
            fieldname: 'age',
            read_only: 1,
            fieldtype: 'Data',
            default: item.theatre_info[0].age,
          },
          {
            label: 'Current Ward',
            fieldname: 'current_ward',
            read_only: 1,
            fieldtype: 'Link',
            default: item.theatre_info[0].current_ward,
            options: 'Healthcare Service Unit',
          },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'Patient Name',
            fieldname: 'patient_name',
            read_only: 1,
            fieldtype: 'Data',
            default: item.theatre_info[0].patient_name,
          },

          {
            label: 'Gender',
            fieldname: 'gender',
            read_only: 1,
            fieldtype: 'Data',
            default: item.theatre_info[0].gender,
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Patient Medical Info',
          },
          {
            label: 'Diagnosis',
            fieldname: 'diagnosis',
            read_only: 1,
            fieldtype: 'Data',
            default: item.theatre_info[0].diagnosis,
          },
          {
            label: 'HB',
            fieldname: 'hb',
            read_only: 1,
            default: item.theatre_info[0].hb,
            fieldtype: 'Check',
          },
          {
            label: 'GXM',
            fieldname: 'gxm',
            read_only: 1,
            default: item.theatre_info[0].gxm,
            fieldtype: 'Check',
          },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'U/E/C',
            fieldname: 'uec',
            fieldtype: 'Text',
            default: item.theatre_info[0].uec,
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Scheduling Details',

          },
          {
            label: 'Operation',
            fieldname: 'operation',
            read_only: 1,
            fieldtype: 'Link',
            default: item.theatre_info[0].operation,
            options: 'Clinical Procedure Template',
          },

          {
            label: 'Surgeon',
            fieldname: 'surgeon',
            read_only: 1,
            fieldtype: 'Link',
            default: item.theatre_info[0].surgeon,
            options: 'Healthcare Practitioner',
          },
          {
            label: 'Date Time',
            fieldname: 'date',
            read_only: 1,
            fieldtype: 'Datetime',
            default: item.theatre_info[0].date_time,
            reqd: 1,
          },

          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'Theatre',
            fieldname: 'theatre',
            read_only: 1,
            fieldtype: 'Link',
            default: item.theatre_info[0].theatre,
            options: 'Healthcare Service Unit',
          },
          {
            label: 'Department',
            fieldname: 'department',
            read_only: 1,
            fieldtype: 'Link',
            default: item.theatre_info[0].department,
            options: 'Department',
          },
          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Additional Details',
          },
          {
            label: 'EL/EM',
            fieldname: 'el_em',
            read_only: 1,
            default: item.theatre_info[0].elem,
            fieldtype: 'Select',
          },
          {
            label: 'Consent',
            fieldname: 'consent',
            read_only: 1,
            default: item.theatre_info[0].consent,
            fieldtype: 'Check',
          },
          {
            label: 'Remarks',
            fieldname: 'remarks',
            read_only: 1,
            default: item.theatre_info[0].remarks,
            fieldtype: 'Text',
          },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'Anae Review',
            fieldname: 'anae_review',
            read_only: 1,
            default: item.theatre_info[0].anae_review,
            fieldtype: 'Check',
          },
          {
            label: 'Paid',
            fieldname: 'paid',
            read_only: 1,
            default: item.theatre_info[0].paid,
            fieldtype: 'Check',
          },

          {
            fieldname: 'section_break',
            fieldtype: 'Section Break',
            label: 'Comments Details',
          },
          {
            label: 'Comments',
            fieldname: 'comment',
            fieldtype: 'Text',
          },

        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          const data = { ...values }
          commentTheatre(data).then(() => {
            this.msgpring(
              {
                message: ('Comment Successfully Added'),
                indicator: 'green',
              },
              5,
            )
            dlg.hide()
          })
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dlg.hide()
        },
      }).show()
      dlg.$wrapper.find('.modal-content').css('width', '1000px')
      dlg.$wrapper.find('.modal-content').css('margin-left', '-200px')
      dlg.$wrapper.find('.modal-content').css('backdrop', 'static')
      dlg.$wrapper.find('.modal-content').css('keyboard', 'false')
    },
    checkCost(procedureItem) {
      this.docGetValue('Clinical Procedure Template', procedureItem.procedure_template, 'item').then(item => {
        getOrderedItemPrices({ item_code_list: [{ item_code: item.message.item, qty: 1 }], patient_name: procedureItem.patient, service_unit: procedureItem.service_unit }).then(values => {
          const msgText = `Cost for ${procedureItem.procedure_template} is <b>${values.total} KES </b>`
          this.msgprint({ title: ((procedureItem.procedure_template)), indicator: 'green', message: msgText })
        })
      })
    },
    click(event) {
      this.$emit('buttonClicked', event)
    },
    reschedule(event) {
      this.$emit('rescheduleClicked', event)
    },
    setPage(page) {
      this.$store.dispatch('procedures/setQueryParams', { page })
    },
  },
}
</script>
<style scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
}

.page-item.active .page-link {
  background-color: #2590ef;
  border-color: #2590ef;
}
</style>
