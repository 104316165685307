<template>
  <div>
    <b-button
      id="dropdownbtnitem"
      block
      style="margin-bottom:15px;"
      class="pull-right mr-2 ml-2"
      variant="success btn-sm"
      @click="approveRejectInpatientTransfer"
    >
      Approve/Reject Transfer

    </b-button>
  </div>
</template>

<script>
import { rejectPatientTransfer } from './service'

export default {
  name: 'ApproveInpatientTransfer',

  props: {
    patient: {
      type: Object,
      default: null,
    },
    service_unit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      target_medical_dept: this.patient.target_medical_department,
      target_su: this.patient.target_service_unit,
      target_bd: this.patient.target_bed,
      is_farewell_transfer: this.patient.is_farewell_transfer,
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {
    approveRejectInpatientTransfer() {
      const parent = this
      let type = ''
      let target_bed = parent.target_bd
      let target_bed_label = 'Target Bed'
      let admit_label = 'Admit to Bed'
      if (parent.is_farewell_transfer == 1) {
        type = '%FRIDGE%'
        target_bed = ''
        target_bed_label = 'Target Fridge'
        admit_label = 'Admit to Fridge'
      } else {
        type = '%BED%'
      }
      const dialog = new frappe.ui.Dialog({
        title: 'Act On Patient Transfer Request',
        fields: [
          {
            fieldtype: 'Read Only',
            fieldname: 'target_medical_department',
            default: parent.target_medical_dept,
            label: 'Target Medical Department',
          },
          {
            fieldtype: 'Read Only',
            fieldname: 'target_service_unit',
            default: parent.target_su,
            label: 'Target Service Unit',
          },
          {
            fieldtype: 'Read Only',
            fieldname: 'target_bed',
            default: target_bed,
            label: target_bed_label,
          },
          {
            fieldtype: 'Link',
            fieldname: 'service_unit',
            options: 'Healthcare Service Unit',
            filters: {
              is_group: 0,
              name: ['like', type],
              occupancy_status: 'Vacant',
            },
            label: admit_label,
          },
        ],
        primary_action_label: ('Admit'),
        primary_action: values => {
          this.dispatchTransfer(values, 'Approve', dialog)

          this.show_alert({
            message: ('Transfer has been approved'),
            indicator: 'green',
          }, 5)

          // bill standing charges
          // this.billInpatientStandingCharges();
        },
        secondary_action_label: '<button style="color:white;background-color:#ec645e;border-radius:8px;border:2px solid #ec645e;height:30px;">Reject</button>',
        secondary_action: values => {
          rejectPatientTransfer({
            inpatient_transfer: this.patient.name,
            status: 'Transfer Rejected',
          }).then(res => {
            this.show_alert({
              message: ('Transfer has been rejected'),
              indicator: 'red',
            }, 5)

            // reload page
            const payload = { serviceUnit: this.service_unit.service_unit, servicePoint: '', search: '' }
            this.$store.dispatch('patient/fetchPatients', payload)
            dialog.hide()
          })
        },
      })

      dialog.$body.find('button[data-fieldtype="Button"]').addClass('btn-secondary')

      dialog.show()
    },
    dispatchTransfer(values, status, dialog) {
      const callback = () => {
        // reload page
        const payload = {
          serviceUnit: this.service_unit.service_unit, servicePoint: '', search: '', work: 'patient/component/approvepatients',
        }
        this.$store.dispatch('patient/fetchPatients', payload)
        dialog.hide()
      }

      const transfer_payload = {
        patient: this.patient,
        ...values,
        inpatient_record: this.patient.inpatient_record,
        leave_from: this.patient.initiating_bed,
        inpatient_transfer: this.patient.name,
        action_type: status,
        callback,
      }
      this.$store.dispatch('patient/transferPatient', transfer_payload)
    },

  },

}
</script>

<style>
.vl {
  border-left: 1px solid grey;
  height: 70px;
  visibility:hidden;
}
</style>
