<template>
  <div v-if="filters.form_name">
    <list-page
      :doctype="doctype"
      :fields="fields"
      :title="currentForm"
      :filters="filters"
      @selected="selected"
    />

    <b-modal
      id="modal-12"
      title="BootstrapVue"
    >
      <SpecificForm :form-id="form" />
    </b-modal>
  </div>
</template>

<script>
import ListPage from '@/views/components/doctype-list/ListComponent.vue'
import SpecificForm from './SpecificForm.vue'

export default {
  name: 'SpecificForm',
  components: { ListPage, SpecificForm },
  props: {
    form: String,
    patient: String,
  },
  data() {
    return {
      doctype: 'Form Repository',
      subTitle: '',
      fields: ['name', 'form_content', 'creation'],
      filters: {},
      currentForm: null,
    }
  },
  computed: {

    patientData() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },

  mounted() {
    this.currentForm = this.form
    this.filters = { form_name: this.currentForm, reference_doctype_id: this.patientData.patient }
  },

  methods: {
    selected(value) {
      if (value && value.length) {
        const id = this.currentForm
        const form = value[0].name
        this.formId = form
        this.$emit('selectForm', form)
      }
    },
  },
}
</script>

<style></style>
