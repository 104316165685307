<template>
  <div>
    <!-- Button to trigger the modal -->
    <b-button
      variant="primary"
      size="sm"
      @click="showModal"
    ><i
      class="fa fa-plus-circle"
      aria-hidden="true"
    />
      Add</b-button>

    <!-- Modal -->
    <b-modal
      v-model="modalVisible"
      title="Lab Test Form"
      @ok="submitForm"
    >
      <b-form @submit.stop.prevent="handleSubmit">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Lab Test"
              label-for="lab_test_code"
            >
              <doctype-link
                v-model="formData.lab_test_code"
                :doctype="'Lab Test Template'"
                required
                @selected="handleLabTestCodeChange"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Priority"
              label-for="lab_test_priority"
            >
              <b-form-select
                id="lab_test_priority"
                v-model="formData.lab_test_priority"
                name="lab_test_priority"
                :options="['Normal', 'High']"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Comment/Clinical summary"
              label-for="lab_test_comment"
            >
              <b-form-textarea
                id="lab_test_comment"
                v-model="formData.lab_test_comment"
                name="lab_test_comment"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Point Of Care Test"
              label-for="point_of_care_lab_test"
            >
              <b-form-input
                id="point_of_care_lab_test"
                v-model="formData.point_of_care_lab_test"
                name="point_of_care_lab_test"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          v-show="false"
          type="submit"
          variant="primary"
        >
          Submit
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  getTemplatePOCStatus,

} from './service'

export default {
  data() {
    return {
      formData: {
        lab_test_code: '',
        lab_test_priority: 'Normal',
        lab_test_comment: '',
        point_of_care_lab_test: '',
      },
      modalVisible: false,
    }
  },
  methods: {
    showModal() {
      this.modalVisible = true
    },
    handleLabTestCodeChange(val) {
      this.formData.lab_test_code = val
      getTemplatePOCStatus(this.formData.lab_test_code).then(r => {
        this.formData.point_of_care_lab_test = r === 0 ? 'This is a Regular Test' : 'This is a Point Of Care Test'
      })
    },
    handleSubmit() {
      // Handle form submission logic here
      // You can perform additional validations before emitting the data
      this.$emit('form-submitted', this.formData)
      this.modalVisible = false // Close the modal after submission
    },
    submitForm() {
      // This method is triggered when the OK button in the modal is clicked
      // You can use it to perform additional actions if needed
      // For now, we'll just submit the form
      this.handleSubmit()
    },
  },
}
</script>

  <style>
svg {
    width: 14px !important;
}
  </style>
