<template>
  <div
    style="cursor: pointer"
    @click="fetchComments(recordId)"
  >
    <span class="document-id">
      <i
        class="fa fa-scissors"
        aria-hidden="true"
        style="color: #043927;"
      />
      <span> {{ recordType }} - {{ recordId }}</span>
      <p style="color:rgb(164, 164, 51);">Click here to get more information about the procedure</p>
      <b>Healthcare Practitioner: </b>{{ recordItems[0].practitioner_name
      }}<br>
      <b>Clinical Procedure: {{ recordItems[0].title }} </b>
      <b-table
        v-if="recordItems.length > 0"
        class="table"
        :fields="['procedure_template', 'start_date', 'start_time']"
        stacked="md"
        small
        bordered
        :items="recordItems"
      />
      <b v-if="recordItems[0].report_legend !=null">Procedure Results: </b><br>
      <p v-if="recordItems[0].report_legend !=null">{{ recordItems[0].report_legend.replace(/<[^>]*>?/gm, '') }}</p>

    </span>
    <b-modal
      :id="infoModal.id"
      ref="procedureCommentsModal"
      scrollable
      size="lg"
      :title="`${recordId} Notes`"
    >
      <div
        v-for="(comment, index) of comments"
        :key="index"
        class="comment"
      >
        <strong>{{ comment.comment_by }} {{ comment.creation | filterDate }}
          {{ comment.creation | filterTime }}</strong>
        <div
          class="comment-view"
          v-html="comment.content"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { api } from '../service'

export default {
  filters: {
    // format date
    filterDate(value) {
      const year = value.substring(0, 4)
      const month = value.substring(5, 7)
      const day = value.substring(8, 10)
      return `${day}-${month}-${year}`
    },
    filterTime(value) {
      const time = value.substring(11, 16)
      return time
    },
  },
  // data() {
  //   return {};
  // },
  props: {
    recordItems: {
      type: Object,
      default: null,
    },
    recordId: {
      type: String,
      default: '',
      required: true,
    },
    recordType: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      infoModal: {
        id: 'procedure-modal-timeline',
        title: '',
        data: {},
        content: {},
      },
      comments: [],
    }
  },
  mounted() {
    api({
      method: 'frappe.client.get_list',
      args: {
        doctype: 'Comment',
        filters: {
          reference_doctype: 'Clinical Procedure',
          reference_name: this.recordId,
        },
        fields: ['name', 'content', 'creation', 'comment_by'],
      },
    }).then(res => {
      if (res.length) {
        this.comments = res
      } else {
      }
    })
  },
  methods: {
    fetchComments(recordId) {
      this.$refs.procedureCommentsModal.show()

      // this.$root.$emit('bv::show::modal', this.infoModal.id);
      // alert(recordId);
    },
  },
}
</script>
