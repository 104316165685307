<template>
  <div class="body">

    <!--<calendar-view
      :show-date="showDate"
      locale="en"
      :items="items"
      @click-item="clickItem"
      :class="`theme-gcal`"
      class="theme-default holiday-us-traditional holiday-us-official"
    >
      <template #header="{ headerProps }">
        <calendar-view-header :header-props="headerProps" @input="setShowDate" />
      </template>
    </calendar-view>-->
  </div>
</template>

<script>
// import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar';
// import "vue-simple-calendar/dist/style.css"
export default {
  name: 'CalenderView',
  components: {
    // CalendarView,
    // CalendarViewHeader,
  },
  data: () => ({
    showDate: new Date(),
    headerProps: {
      locale: 'en',
    },
  }),
  computed: {
    themeOptions() {
      return {
        top: '2.6em',
        height: '2.1em',
        border: '0px',
        previousYearLabel: '\uE5CB\uE5CB',
        previousPeriodLabel: '\uE5CB',
        nextPeriodLabel: '\uE5CC',
        nextYearLabel: '\uE5CC\uE5CC',
        currentPeriodLabel: 'Today',
      }
    },
    items() {
      const statistics = this.$store.getters['procedures/getProcedureStatistics']
      const items = statistics.map(({ date, total }, index) => {
        const colors = ['#f0e0ff', '#e7e7ff', '#ffe0e1', '#ffe7d0', '#e0f0e0']
        const isToday = moment().format('YYYY-MM-DD') === date
        const random = isToday ? 'transparent' : colors[Math.floor(Math.random() * colors.length)]

        return ({
          classes: ['custom-date-class-red'],
          id: date,
          title: total,
          startDate: moment(date, 'YYYY-MM-DD').toDate(),
          style: `background-color: ${random}; border: none;`,
        })
      })
      return items
    },
  },
  methods: {
    setShowDate(d) {
      this.showDate = d
    },
    clickItem(e) {
      this.$emit('item-clicked', e)
    },
    getRandomEvent(index) {
      const startDay = 10
      const endDay = startDay
      const d = new Date()
      const i = {
        id: index.toString(),
        title: 10,
        startDate: new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), startDay)),
        endDate: new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), endDay)),
        classes: ['custom-date-class-red'],
        style: {
          backgroundColor: '#0000ff',
        },
      }
      return i
    },
  },
}
</script>

<style>
.cv-item.custom-date-class-red {
  background-color: rgba(0, 0, 0, 0.4);
  height: 75%;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-radius: 0;
  font-size: 68px;

  /* color: lightgray; */
}

.theme-default .cv-header,
.theme-default .cv-header-day {
  background-color: #f0f0f0;
}

.theme-default .cv-header .periodLabel {
  font-size: 1.5em;
}

.theme-default .cv-header button {
  color: #7f7f7f;
}

.theme-default .cv-header button:disabled {
  color: #ccc;
  background-color: #f7f7f7;
}

/* Grid */

.theme-default .cv-weeknumber {
  background-color: #e0e0e0;
  border-color: #ccc;
  color: #808080;
}

.theme-default .cv-weeknumber span {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.theme-default .cv-day.past {
  background-color: #fafafa;
}

.theme-default .cv-day.outsideOfMonth {
  background-color: #f7f7f7;
}

.theme-default .cv-day.today {
  background-color: transparent;
}

.theme-default .cv-day[aria-selected='true'] {
  background-color: #ffc;
}

/* Events */

.theme-default .cv-item {
  border-color: #e0e0f0;
  top: 20px !important;
  /* border-radius: 0.5em; */
  background-color: #e7e7ff;
  text-overflow: ellipsis;
}

.theme-default .cv-item.purple {
  background-color: #f0e0ff;
  border-color: #e7d7f7;
}

.theme-default .cv-item.orange {
  background-color: #ffe7d0;
  border-color: #f7e0c7;
}

.theme-default .cv-item.continued::before,
.theme-default .cv-item.toBeContinued::after {
  content: ' \21e2 ';
  color: #999;
}

.theme-default .cv-item.toBeContinued {
  border-right-style: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.theme-default .cv-item.isHovered.hasUrl {
  text-decoration: underline;
}

.theme-default .cv-item.continued {
  border-left-style: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.cv-item.span3,
.cv-item.span4,
.cv-item.span5,
.cv-item.span6,
.cv-item.span7 {
  text-align: center;
}

/* Event Times */

.theme-default .cv-item .startTime,
.theme-default .cv-item .endTime {
  font-weight: bold;
  color: #666;
}

/* Drag and drop */

.theme-default .cv-day.draghover {
  box-shadow: inset 0 0 0.2em 0.2em yellow;
}
</style>
