<template>
  <div>
    <b-button
      class="pull-right mr-2 ml-2"
      variant="light btn-sm"
      @click="$bvModal.show('add_la_modall')"
    >
      <i
        class="fa fa-user-plus"
        aria-hidden="true"
      /> Referral In
    </b-button>
    <div>
      <b-modal
        v-if="isVisible"
        id="add_la_modall"
        hide-footer
      >
        <template #modal-title>
          Set this patient appointment as referral in
        </template>
        <div>
          <b-button
            class="pull-right"
            variant="primary btn-sm"
            @click="setReferral"
          >
            Confirm
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { setReferralIn } from './service'

export default {
  name: 'SetReferralIn',
  props: {
    details: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: 1,
    }
  },
  computed: {
    patient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    selectedPatientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
  },
  created() {},
  methods: {
    setReferral() {
      this.isVisible = 0
      setReferralIn(this.details.appointment).then(
        res => {
          this.show_alert({
            message: ('Referral In successfully set'),
            indicator: 'green',
          }, 5)
          this.isVisible = 1
        },
      )
    },
  },

}
</script>

<style>

</style>
