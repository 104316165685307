import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getDoctorsPrescriptions = patient => api({
  method: 'clinical.api.orders.prescriptions.get_doctors_prescriptions',
  args: {
    patient,
  },
})

export const removePrescription = (encounter_name, prescription_name) => api({
  method: 'clinical.api.orders.prescriptions.remove_prescription',
  args: {
    encounter_name,
    prescription_name,
  },
})

export const createDoctorsPrescription = (order, order_list, type) => api({
  method: 'clinical.api.orders.prescriptions.make_doctors_prescription',
  args: {
    order,
    order_list,
    type,
  },
})

export const getDrugMappings = args => api({
  method: 'clinical.api.orders.prescriptions.get_drug_options',
  args,
})

export const getDrugGroups = (args = {}) => api({
  method: 'clinical.api.orders.prescriptions.get_drug_item_groups',
  args,
}).then(data => {
  console.warn(data)
  return data
})

export const getOrderedItemPrices = ({
  item_code_list,
  patient_name,
  service_unit,
}) => api({
  method: 'billing.billing.utils.item_prices.get_patient_ordered_item_prices',
  args: { item_code_list, patient_name, service_unit },
})

export const getMatchedItems = args => api({
  method: 'clinical.api.orders.prescriptions.get_drug_options',
  args,
})

export const addALabPrescription = ({
  encounter_name,
  lab_test_code,
  lab_test_comment,
  lab_test_priority,
}) => api({
  method: 'clinical.api.orders.prescriptions.add_lab_prescription',
  args: {
    encounter_name,
    lab_test_code,
    lab_test_comment,
    lab_test_priority,
  },
})

export const getTemplatePOCStatus = lab_test_code => api({
  method: 'clinical.api.orders.prescriptions.get_template_poc_status',
  args: {
    lab_test_code,
  },
})

export const getNormalRange = lab_test_code => api({
  method: 'clinical.api.orders.prescriptions.get_normal_range',
  args: {
    lab_test_code,
  },
})
export const getRangeNote = (lab_test_code, result_value) => api({
  method: 'clinical.api.orders.prescriptions.get_range_note',
  args: {
    lab_test_code,
    result_value,
  },
})

export const getLabPrescriptions = patient => api({
  method: 'clinical.api.orders.prescriptions.get_lab_prescriptions',
  args: {
    patient,
  },
})

export const createPatientEncounter = (patient, is_walk_in) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.create_patient_encounter',
  freeze: false,
  args: {
    patient,
    is_walk_in,
  },
})

export const postPOCLabTestResults = poc_lab_test_results => api({
  method: 'clinical.api.orders.prescriptions.post_poc_lab_test_results',
  args: {
    poc_lab_test_results,
  },
})
export const cancelLabPrescription = (encounter_name, prescription_name) => api({
  method: 'clinical.api.orders.prescriptions.cancel_lab_prescription',
  args: {
    encounter_name,
    prescription_name,
  },
})

export const deleteLabPrescription = (encounter_name, prescription_name) => api({
  method: 'clinical.api.orders.prescriptions.delete_lab_prescription',
  args: {
    encounter_name,
    prescription_name,
  },
})

export const getCancelledLabPrescriptions = patient => api({
  method: 'clinical.api.orders.prescriptions.get_cancelled_lab_prescriptions',
  args: {
    patient,
  },
})

export const getPractitionerMedicationOrders = patient => api({
  method: 'clinical.api.inpatient_medication_orders.inpatient_medication_orders.get_doctor_medication_orders',
  args: {
    patient,
    patient_encounter_status: '1',
  },
})

export const getUnsubmittedMedicationOrders = (
  patient,
  patient_encounter = '',
) => api({
  method: 'clinical.api.inpatient_medication_orders.inpatient_medication_orders.get_doctor_medication_orders',
  args: {
    patient,
    patient_encounter_status: '0',
    patient_encounter,
  },
})

export const cancelMedicationOrder = name => api({
  method: 'clinical.api.inpatient_medication_orders.inpatient_medication_orders.cancel_inpatient_medication_order',
  args: {
    name,
  },
})

export const createMedicationOrder = order => api({
  method: 'clinical.api.inpatient_medication_orders.inpatient_medication_orders.create_inpatient_medication_order',
  args: {
    order,
  },
})

export const getMedicationOrderReadOnlyStatus = () => api({
  method: 'clinical.clinical.doctype.patient_tab_permissions.patient_tab_permissions.get_medication_order_read_only_status',
})

export const checkStockAvailability = args => api({
  method: 'erpnext.stock.dashboard.item_dashboard.get_data',
  args,
})

export const getAlternativeDrugs = drug_name => api({
  method: 'clinical.api.prescription.prescription.get_drug_item_alternates',
  args: {
    drug_name,
  },
})

export const addAProcedure = (procedure, ward, is_walk_in) => api({
  method: 'clinical.api.orders.prescriptions.add_clinical_procedure',
  args: {
    procedure,
    ward,
    is_walk_in,
  },
})

export const getProcedures = (patient, is_imaging_service) => api({
  method: 'clinical.api.orders.prescriptions.get_procedures',
  args: {
    patient,
    is_imaging_service,
  },
})

export const getUnsubmittedProcedures = (patient, practitioner) => api({
  method: 'clinical.api.orders.prescriptions.get_unsubmitted_procedures',
  args: {
    patient,
    practitioner,
  },
})

export const getProcedureReadOnlyStatus = () => api({
  freeze: false,
  method: 'clinical.clinical.doctype.patient_tab_permissions.patient_tab_permissions.get_procedure_read_only_status',
})

export const cancelUnsubmittedProcedure = (encounter, procedure_name) => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.cancel_unsubmitted_procedure',
  args: { encounter, procedure_name },
})

export const cancelClinicalProcedure = procedure_name => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.cancel_clinical_procedure',
  args: { procedure_name },
})

export const getPatientMedicationOrders = patient => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.fetch_medication_orders',
  freeze: false,
  args: {
    entry: { patient },
  },
})

export const createMedicationEntry = ({ order, ward }) => api({
  method: 'clinical.api.inpatient_drug_administration.medication_entry.create_medication_entry',
  args: {
    order,
    ward,
  },
})

export const validatePFNumber = number => api({
  method: 'clinical.api.inpatient_drug_administration.medication_entry.validate_employee',
  freeze: false,
  args: {
    number: number.trim(),
  },
})

export const ignoreDrugAdministration = args => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.ignore_drug_administration',
  args,
}).then(({ message }) => message)

export const undoDrugAdministration = args => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.undo_drug_administration',
  args,
}).then(({ message }) => message)
