<template>
  <div>
    <i
      class="fa fa-heartbeat"
      aria-hidden="true"
      style="color: green; border-radius: 100;"
    />
    <span> {{ recordType }} - {{ recordId }}</span>
    <span class="document-id">
      <br>
      <b>Temperature: </b>{{ recordItems[0].temperature }}<br>
      <b>Pulse: </b>{{ recordItems[0].pulse }}<br>
      <b>Respiratory Rate: </b>{{ recordItems[0].respiratory_rate }}<br>
      <b>Weight: </b>{{ recordItems[0].weight }} Kgs<br>
      <b>Height: </b>{{ recordItems[0].height }} Metres<br>
      <b>BP: </b>{{ recordItems[0].bp_systolic }} / {{ recordItems[0].bp_diastolic }} <br>
      <b>Oxygen Concentration: </b>{{ recordItems[0].oxygen_concentration }}<br>
      <b>Vital Signs Note: </b><span v-html="recordItems[0].vital_signs_note" /><br>
      <b>BMI: </b>{{ recordItems[0].bmi }}<br>
      <b>Nutrition Note: </b><span v-html="recordItems[0].nutrition_note" /><br>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    recordItems: {
      type: Object,
      default: null,
    },
    recordId: {
      type: String,
      default: {},
      required: true,
    },
    recordType: {
      type: String,
      default: {},
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
