<template>
  <b-container
    class="p-0"
    style="width: 100% !important"
  >
    <PatientInputForm
      :patient="patient.patient"
      style="width: 100% !important"
    />
  </b-container>
</template>

<script>

import PatientInputForm from './PatientInputForm.vue'

export default {
  components: {
    PatientInputForm,
  },
  props: {
    patient: { type: Object },
    filters: { type: Object, default: {} },
  },
  computed: {
    encounter() {
      return this.$store.getters['encounter/getEncounter']
    },
  },
  mounted() {
    this.filters.reference_doctype_id = this.patient.patient
    this.filters.reference_doctype = 'Patient'
  },

  methods: {

    update(value) {
      this.$set(this.filters, 'form_name', null)
      this.$set(this.filters, 'form_name', value)
    },

  },
}
</script>

<style>

</style>
