<template>
  <b-container
    v-show="false"
    style="width:100%"
  >
    <b-row class="p-2">
      <b-col
        v-if="encounter && encounter.name"
        cols="12"
        align="center"
      >
        <p style="color:black;font-size:12px">
          <i
            class="fa fa-clock-o  mobile-hidden"
            aria-hidden="true"
          />
          <span class="mobile-hidden">  {{ message }}</span>
        </p>
      </b-col>
      <b-col v-else>
        {{ message }}
        <b-button
          variant="success"
          hidden
          size="sm"
          @click="createEncounter()"
        >
          <i
            class="fa fa-plus-circle"
            aria-hidden="true"
          />
          <span class="mobile-hidden">Create Encounter</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  createPatientEncounter,
  getDraftPatientEncounters,
} from './service'

export default {
  name: 'Encounter',
  data() {
    return {
      message: '',
      hideEncounterButton: 1,
    }
  },

  computed: {
    encounter() {
      return this.$store.getters['encounter/getEncounter']
    },
    patient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },
  watch: {
    patient(val) {
      // alert("Changed")
      if (val && val.patient) {
        this.getPatientEncounter(val.patient)
      } else {
        this.updateEncounter(null)
      }
    },
    encounter(val) {
      if (val) {
        this.setContext({
          key: 'encounter',
          value: val.name,
        })
        setInterval(() => {
          this.reloadClock()
        }, 1000)
      }

      this.reloadClock()
    },
  },
  created() {
    setInterval(() => {
      this.reloadClock()
    }, 1000)
  },
  mounted() {
    if (this.patient != null && this.patient.patient != null) {
      this.getPatientEncounter(this.patient.patient)
    }
  },
  methods: {
    createEncounter() {
      this.show_alert('Encounter is being created in the background. Meanwhile, please proceed.')
      createPatientEncounter(this.patient.patient).then(encounter => this.updateEncounter(encounter))
    },
    updateEncounter(encounter) {
      this.$store.dispatch('encounter/setEncounter', encounter)
      this.reloadClock()
    },
    getPatientEncounter(patient) {
      if (patient) {
        getDraftPatientEncounters(patient).then(encounter => this.updateEncounter(encounter))
      }
    },
    setContext(payload) {
      this.$store.dispatch('context/setContextItem', payload)
    },
    getEncounterDateTime() {
      if (
        this.encounter
        && this.encounter.encounter_date
        && this.encounter.encounter_time
      ) {
        const date = this.encounter.encounter_date.replaceAll('-', '/')
        const time = this.encounter.encounter_time.split('.')[0]
        const combinedDateTime = `${date} ${time}`

        return new Date(combinedDateTime)
      }
      return null
    },
    reloadClock() {
      const appointmentDateTime = this.getEncounterDateTime()

      if (appointmentDateTime) {
        const today = new Date()
        today.setHours(today.getHours() - 3)

        const diffMs = today - appointmentDateTime // milliseconds between now & Christmas
        const diffDays = Math.floor(diffMs / 86400000) || 0 // days
        const diffHrs = Math.floor((diffMs % 86400000) / 3600000) || 0 // hours
        const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) || 0 // minutes
        const diffSecs = Math.round((((diffMs % 86400000) % 3600000) % 60000) / 1000) || 0

        this.message = `${diffDays
        } d ${
          diffHrs
        } hr ${
          diffMins
        } min ${
          diffSecs
        }s`
      } else {
        this.message = ''
      }
    },
  },
}
</script>

<style scoped>
@media only screen and (max-width: 767px) {

    .mobile-hidden{
        display: none;
    }

}
</style>
