<template>
  <div class="mx-1">
    <div
      v-if="false"
      class="actions"
    >
      <b-button
        variant="primary"
        class="button"
        @click="$router.go(-1)"
      >
        <b-icon
          icon="chevron-left"
          level="2"
        /><strong>Back To List</strong></b-button>
      <b-button
        style="margin-right: 2px"
        variant="primary"
        @click="verifyMpesaPayment"
      >Verify Mpesa
      </b-button>
    </div>

    <div
      style="width: 100%"
      class="cardx"
    >
      <patient-info
        v-if="!!patient"
        :service-unit="selectedServiceUnit"
        :un-submitted-procedures="[]"
        :medication-order="{}"
        :encounter="contextEncounter"
        :full-screen-work-area="false"
        :patient="patient"
        :hide-actions="true"
        @medicationOrderUpdated="medicationOrderUpdated"
        @encounterUpdated="encounterUpdated"
        @updateList="updateList"
        @sign-off="onSignOff"
      />
    </div>
    <div
      class="cardx"
      style="border: 1px solid lightgray ; border-radius: 8px;"
    >
      <b-tabs class="mx-1">

        <b-tab
          class="mt-1"
          style=" overflow-y: scroll"
          title="Procedure Administration"
          active
        >
          <b-card-text>
            <b-row
              class="mt-1"
              align-h="between"
            >
              <strong>Status: {{ procedure.status }}<b /> </strong>
              <div>
                <b-button
                  v-if="procedure.status !== 'Completed'"
                  size="sm"
                  class="mr-1"
                  :pressed="false"
                  variant="success"
                  @click="printNotes"
                >Print Notes</b-button>
                <b-button
                  v-if="notes && procedure.status !== 'Completed'"
                  size="sm"
                  variant="primary"
                  @click="saveNotes"
                >Save Notes</b-button>
              </div>
            </b-row>
            <div>
              Procedure Template:
              <b-form-select
                v-model="selectedTemplate"
                :options="options"
                size="sm"
                class="mt-1"
                @change="getFormattedNotes()"
              />
              <div class="mt-1">
                NOTES: <strong>{{ selected }}</strong>
              </div>
            </div>
            <!--End Templating-->
            <div
              v-if="procedure.status === 'Completed'"
              v-html="procedure.notes"
            />
            <div
              v-for="(comment, index) of procedure.comments || []"
              :key="index"
              class="comment"
            >
              <strong>{{ comment.by }}</strong>
              <div
                class="comment-view"
                v-html="comment.comment"
              />
            </div>
            <!-- <strong v-if="procedure.status !== 'Completed'">Add Procedure Notes</strong> -->
            <vue-editor
              v-if="procedure.status !== 'Completed'"
              v-model="notes"
              :value="notes"
            />

          </b-card-text>
        </b-tab>
        <b-tab title="Timeline">
          <b-card-body>
            <timeline
              v-if="!!patient.patient"
              :patient="patient"
              :source-page="'Clinical Procedure'"
            />
          </b-card-body>
        </b-tab>
        <b-tab title="Orders">
          <b-card-body>
            <orders
              v-if="!!patient.patient"
              :service-unit="selectedServiceUnit"
              :selected-patient="patient"
              :medication-order="medicationOrder"
              :encounter="encounter"
              :patient="patient.patient"
              @medicationOrderUpdated="medicationOrderUpdated"
              @encounterUpdated="encounterUpdated"
            />
          </b-card-body>
        </b-tab>
        <b-tab title="Forms">
          <form-select
            :patient="patient"
            :action="''"
          />
        </b-tab>
        <b-tab title="Extra Items">
          <bill-etra-items :patient="patient" />
        </b-tab>
      </b-tabs>
    </div>

  </div>
</template>
<script>
import PatientInfo from '@/views/erp/clinical/working-area/components/patient-info/PatientInfo.vue'
import FormSelect from '@/views/erp/clinical/working-area/components/patient-chart/components/forms/FormsTab.vue'
import APP_CONTEXT_MIXIN from '@/views/erp/mixins/app-context.mixin'
import Orders from '@/views/erp/clinical/working-area/components/patient-chart/components/orders/Orders.vue'
import Timeline from '@/views/erp/clinical/working-area/components/patient-chart/components/timeline/Timeline.vue'
import { VueEditor, Quill } from 'vue2-editor'
import BillEtraItems from './billExtraItems.vue'
import {
  getClinicalProcedure,
  getPatientNameAndCustomerNumber,
  api,
  addComment,
  getDraftPatientEncounters,
  createPatientEncounter,
  startOrFinishprocedure,
  createProcedureChargesDraft,
  submitAndBillProcedure,
} from '../../service'

export default {
  name: 'ProcedureAdministration',
  components: {
    PatientInfo,
    Orders,
    FormSelect,
    BillEtraItems,
    Timeline,
    VueEditor,
  },
  mixins: [APP_CONTEXT_MIXIN],
  props: {
    procedureName: String,
  },
  data() {
    return {
      procedure: {},
      notes: '',
      medicationOrder: {},
      encounter: {},
      options: [],
      selectedTemplate: '',
      topOffSet: 0,
      maxtopOffSet: 0,
    }
  },
  computed: {
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    patient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },
  watch: {
    patient() {
      getDraftPatientEncounters(this.patient.patient).then(encounter => {
        if (!encounter.name) {
          createPatientEncounter(this.patient.patient).then(encounter => {
            this.contextUpdateEncounter(encounter)
          })
        } else {
          this.contextUpdateEncounter(encounter)
        }
      })
    },
    notes(al) {
      this.notes = al
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollListener)
  },
  mounted() {
    const procedure = this.$route.params.name || this.procedureName
    getClinicalProcedure(procedure).then(response => {
      this.procedure = response
      this.getOptions(response.procedure_template)
      if (response.patient) {
        getPatientNameAndCustomerNumber(response.patient).then(res => {
          const payload = { patient_name: response.patient }
          this.$store.dispatch('patient/setSelectedPatient', {
            patient_name: res.patient_name,
            patient: response.patient,
            name: response.patient,
            service_unit: this.selectedServiceUnit.service_unit,
            status: this.procedure.status,
            customer_number: res.customer_number,
            warehouse: response.warehouse,
          })
          this.$store.dispatch('patient/fetchSelectedPatientDetails', payload)
        })
      }
    })
    if (this.patient.patient) {
      getDraftPatientEncounters(this.patient.patient).then(encounter => {
        this.contextUpdateEncounter(encounter)
      })
    }
  },
  methods: {
    verifyMpesaPayment() {
      frappe.require('/assets/frontend/js/mpesa_dialog.js', () => {
        frappe.db
          .get_value('Patient', this.patient.patient, 'customer')
          .then(({ message }) => {
            const { customer } = message
            const callback = () => { }
            mpesaDialog(customer, this.patient.patient, callback)
          })
      })
    },
    getOptions(clinical_procedure_template) {
      api({
        method:
          'clinical.api.clinical_procedure.procedure_report.clinical_procedure_report_templates',
        args: {
          clinical_procedure_template,
        },
      }).then(r => {
        this.options = ['', ...r]
      })
    },
    getFormattedNotes() {
      api({
        method: 'clinical.api.clinical_procedure.procedure_report.make_report',
        freeze: true,
        freeze_message: 'Please wait as we prepare the template for you',
        args: {
          clinical_procedure: this.procedure.name,
          report_template: this.selectedTemplate,
        },
      }).then(r => {
        // const closing = new RegExp('</div>', 'g')
        this.notes = r.replace(/^<div[^>]*>|<\/div>$/g, '')
        console.log('===========>', this.notes)
      })
    },
    printNotes() {
      const clinical_procedure = this.procedure.name
      const w = window.open(
        frappe.urllib.get_full_url(
          `${'/printview?'
          + 'doctype='}${
            encodeURIComponent('Clinical Procedure')
          }&name=${
            encodeURIComponent(clinical_procedure)
          }&format=${
            encodeURIComponent('Clinical Procedure Notes')
          }&_lang=en&no_letterhead=0&letterhead=MTRH%20Default&settings=%7B%7D&trigger_print=1`,
        ),
      )
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    updateList(list) {
      console.log('>>>>>>>>>>>>>>>>', list)
    },
    addNotes(procedure, notes) {
      addNotesToProcedure(procedure, notes).then(doc => { })
    },
    encounterUpdated(encounter) {
      this.contextUpdateEncounter(encounter)
    },
    saveNotes() {
      if (!this.encounter.name) {
        createPatientEncounter(this.patient.patient).then(encounter => {
          this.contextUpdateEncounter(encounter)
        })
      }
      addComment({
        doctype: 'Clinical Procedure',
        name: this.procedure.name,
        text: this.notes,
      }).then(comments => {
        console.log(comments)
        this.procedure.comments = comments
        this.notes = ''
      })
    },
    onSignOff() {
      // console.log('>>>>>>>>', 'Finish procedure')
      startOrFinishprocedure(this.procedure.name, 'Finish').then(res => {
        // this.$router.push("/app/clinical-procedures");
        submitAndBillProcedure(
          this.procedure,
          this.procedure.name,
          0,
          'Clinical Page',
          this.selectedServiceUnit.service_unit,
        ).then(res => {
          // create procedure charges draft document
          createProcedureChargesDraft(
            this.procedure.name,
            this.procedure.procedure_template,
          ).then(doc => { })
        })
      })
    },
    medicationOrderUpdated(order) {
      this.medicationOrder = order
    },
  },
}
</script>
<style  scoped>
.top-menu {
  padding: 0px !important;
}

.section-margin-space {
  margin-top: 3%;
}

.cardx {
  box-shadow: 5 5px 5px 5 rgb(0 0 0 / 20%) !important;
  transition: 0.3s;
  border-radius: 7px !important;

}

.area-background {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.highest-card {
  z-index: 1;
}

.notification {
  width: 100%;
}

.comment {
  padding: 8px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #fff;
}

/* prettier-ignore */
.comment-view div.ql-editor {
  min-height: 0;
  padding: 8px;
}

.ql-snow .ql-editor {
  min-height: 100px;
}

.save {
  float: right;
  margin-top: 16px;
  min-width: 91px;
}

.actions {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.button {
  display: flex;
  margin-top: 16px;
}
</style>
