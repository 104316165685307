<template>
  <div>
    <span class="document-id">
      <i
        class="fa fa-stethoscope"
        aria-hidden="true"
        style="color: #ad2b4a;"
      />
      <span> {{ recordType }} - {{ recordId }}</span>

      <br>
      <b>Healthcare Practitioner: </b>{{ recordItems[0].practitioner_name
      }}<br>
      <b>Drug Prescription: </b>
      <div style="overflow-x: auto">
        <b-table
          v-if="prescriptionItems.length > 0"
          class="table"
          stacked="md"
          :fields="[
            'drug_name',
            'dosage',
            'period',
            'interval',
            'interval_uom',
          ]"
          small
          bordered
          :items="prescriptionItems"
        />
      </div>
      <b>Procedure Prescription: </b>
      <b-table
        v-if="procedureItems.length > 0"
        class="table"
        :fields="['procedure', 'procedure_name', 'practitioner']"
        stacked="md"
        small
        bordered
        :items="procedureItems"
      />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    recordItems: {
      type: Object,
      default: null,
    },
    recordId: {
      type: String,
      default: '',
      required: true,
    },
    recordType: {
      type: String,
      default: '',
      required: true,
    },
    prescriptionItems: {
      type: Object,
      default: null,
    },
    procedureItems: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {}
  },
}
</script>
