<template>
    <div>
        <b-row class="w-100 mb-1">
            <doctype-link class="w-100" :doctype="doctype" :placeholder="`Search ${doctype}`" @selected="onSearchSelect" />

        </b-row>
        <b-table   small :items="transformedDataList" :fields="fieldsWithCount" stacked="sm" variant="secondary"
            style=" height: 70vh;width: 100%" :empty-text="`No items to show`" :empty-filtered-text="`No items to show`"
            :show-empty="true" striped outlined bordered head-row-variant="secondary"  responsive
            selectable select-mode="single" @row-selected="onRowSelected">
            <template v-slot:cell(No.)="row">
                {{ row.index + 1 }}
            </template>
            <template v-slot:cell(form_content)="row">
                <b-form-checkbox switch v-model="row.detailsShowing" @change="row.toggleDetails">
                    Check Details
                </b-form-checkbox>
            </template>
            <template #row-details="row">
                <b-row class="w-100" style="font-size: 12px;" >
                    <b-table class="w-100" small stacked
                        :items="[removePrefixAndNumericSuffix(JSON.parse(row.item.form_content))]">
                        <template v-slot:cell()="rox">
                            <div v-if="!Array.isArray(rox.value)" v-html="rox.value"></div>
                            <b-table v-else small stacked :items="rox.value"></b-table>
                        </template>
                    </b-table>
                </b-row></template>
        </b-table>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"
            @input="fetchData" class="mb-1"></b-pagination>

    </div>
</template>
  
<script>
export default {
    props: {
        fields: {
            type: Array,
            required: true,
            default: ["name"]
        },
        filters: {
            type: Object,
            required: true,
            default: {}
        },
        doctype: {
            type: String,
            required: true,
        },
    },
    computed: {
        transformedDataList() {
            // Map values array to an array of objects with keys based on fields
            return this.dataList.map((row) => {
                const transformedRow = {};
                this.fields.forEach((field, index) => {
                    const key = typeof field === 'object' ? field.key : field
                    transformedRow[key] = row[index];
                });
                return transformedRow;
            });
        },
        fieldsWithCount() {
            return [{ key: "No.", label: "" }, ...this.fields]
        }
    },
    data() {
        return {
            currentPage: 1,
            perPage: 20, // Adjust as needed
            totalRows: 0,
            dataList: [],
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        onSearchSelect(result) {
            this.$emit('selected', [{ name: result }])
        },
        onRowSelected(rowItems) {
            this.$emit('selected', rowItems)
        },
        fetchData() {
            const fields = this.fields.map(field => {
                return typeof field === 'object' ? field.key : field
            })
            this.docDocCount(this.doctype, this.filters).then((results) => {
                this.totalRows = results.message;
                const offset = (this.currentPage - 1) * this.perPage;
                this.getDocPaginatedList(this.doctype, this.filters, fields, offset, this.perPage).then((data) => {
                    this.dataList = data.message.values;
                });
            });
        },
        removePrefixAndNumericSuffix(obj) {
            const result = {};

            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    let newKey = key.replace(/^DC-/, ''); // Remove prefix
                    newKey = newKey.replace(/-\d+$/, ''); // Remove numeric suffix

                    result[newKey] = obj[key];
                }
            }

            return result;
        }
    },
};
</script>
  
<style scoped>
/* Add your component styles here if needed */
</style>
  