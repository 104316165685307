<template>
  <div>
    <div class="flex-end">
      <AddModal
        v-if="procedureReadOnlyStatus == 0"
        class="mx-2"
        @form-submitted="formSubmitted"
      />

      &nbsp;
      <b-button
        size="sm"
        variant="primary"
        @click="printLabPrescription()"
      ><i
        class="fa fa-print"
        aria-hidden="true"
      />Print</b-button>
    </div>
    <h5 style="color:#24a0ed">
      <strong>DRAFT</strong>
    </h5>
    <hr>
    <b-table
      v-if="currentEncounter.lab_test_prescription"
      :show-empty="true"
      :empty-text="`Items will be shown here`"
      :empty-filtered-text="`No items to show`"
      :fields="['lab_test_name', 'creation', 'lab_test_comment', { label: 'Actions', key: 'action' }]"
      class="table"
      stacked="md"
      small
      bordered
      hover
      :items="currentEncounter.lab_test_prescription"
    >
      <!-- <template #cell(amount)>
      {{ priceInformation[row.item.lab_test_code].amount }}
    </template> -->
      <template #cell(creation)="row">
        {{ row.item.creation.slice(0, 16) }}
      </template>
      <template #cell(action)="row">
        <b-button
          size="sm"
          variant="danger"
          @click="deleteLabPrescription(row.item.parent, row.item.name)"
        > Remove
        </b-button>
      </template>

    </b-table>
    <strong>
      <b-row style="margin-left:4px;margin-top: 4px;">
        Total: <p v-if="currentEncounter.lab_test_prescription && currentEncounter.lab_test_prescription.length > 0">{{
          priceInformation.total.toFixed(2) || 0 }} KES</p>
      </b-row>
    </strong>
    <h5
      style="color:#5cb85c"
      class="mt-1"
    >
      <strong>SUBMITTED</strong>
    </h5>
    <hr>
    <b-table
      :show-empty="true"
      :empty-text="`Items will be shown here`"
      :empty-filtered-text="`No items to show`"
      :fields="fields"
      class="table"
      stacked="md"
      small
      striped
      hover
      :items="labprescriptions"
    >
      <template #cell(creation)="row">
        {{ row.item.creation.slice(0, 16) }}
      </template>

      <template #cell(action)="row">
        <b-button-group size="sm">
          <b-button
            size="sm"
            variant="danger"
            @click="cancelLabPrescription(row.item.parent, row.item.name)"
          > Cancel
          </b-button>
          <b-button
            v-if="row.item.point_of_care_lab_test == 1 || row.item.point_of_care_lab_test == 2"
            size="sm"
            variant="primary"
            @click="postResults(row.item.lab_test_code, row.item.name)"
          > Post Results </b-button>
        </b-button-group>

      </template>

    </b-table>

    <!-- <h5 style="color:#d9534f"><strong>Cancelled Lab Orders</strong></h5>
    <hr />
    <b-table
      :fields="fieldss"
      class="table"
      stacked="md"
      small
      striped
      hover
      :items="cancelledlabprescriptions"
    >
     <template #cell(creation)="row">
            {{ row.item.creation.slice(0, 16)}}
          </template>

    </b-table> -->

  </div>
</template>
<script>

import {
  addALabPrescription,
  getTemplatePOCStatus,
  getNormalRange,
  getRangeNote,
  getLabPrescriptions,
  createPatientEncounter,
  postPOCLabTestResults,
  cancelLabPrescription,
  deleteLabPrescription,
  getCancelledLabPrescriptions,
  getOrderedItemPrices,
} from './service'
import AddModal from './AddLabModal.vue'

export default {
  name: 'LabPrescription',
  components: {
    AddModal,
  },
  props: {
    patient: {
      default: '',
      type: String,
      required: true,
    },
    encounter: {
      type: Object,
      default: {},
      required: true,
    },
    serviceUnit: {
      type: String,
      default: '',
      required: true,
    },
    walkin: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      fields: ['lab_test_name', 'prescribed_by', 'creation', 'lab_test_comment', { label: 'Actions', key: 'action' }],
      fieldss: ['lab_test_name', 'prescribed_by', 'creation', 'lab_test_comment'],
      labprescriptions: [],
      cancelledlabprescriptions: [],
      procedureReadOnlyStatus: '',
      priceInformation: { total: 0 },
    }
  },
  computed: {
    currentEncounter() {
      return this.$store.getters['encounter/getEncounter']
    },
    selectedPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },
  watch: {
    selectedPatient() {
      this.getItemPrices()
      getLabPrescriptions(this.patient || this.selectedPatient.patient).then(labprescriptions => {
        this.labprescriptions = labprescriptions
      })
      getCancelledLabPrescriptions(this.patient || this.selectedPatient.patient).then(cancelledlabprescriptions => {
        this.cancelledlabprescriptions = cancelledlabprescriptions
      })
    },
    currentEncounter() {
      this.getItemPrices()
      getLabPrescriptions(this.patient || this.selectedPatient.patient).then(labprescriptions => {
        this.labprescriptions = labprescriptions
      })
      getCancelledLabPrescriptions(this.patient || this.selectedPatient.patient).then(cancelledlabprescriptions => {
        this.cancelledlabprescriptions = cancelledlabprescriptions
      })
    },
  },
  mounted() {
    if (this.currentEncounter.name == null && this.encounter != null && this.encounter.name) {
      this.UpdateEncounter(this.encounter)
    }
    getLabPrescriptions(this.patient || this.selectedPatient.patient).then(labprescriptions => {
      this.labprescriptions = labprescriptions
    })
    getCancelledLabPrescriptions(this.patient || this.selectedPatient.patient).then(cancelledlabprescriptions => {
      this.cancelledlabprescriptions = cancelledlabprescriptions
    })
    this.getItemPrices()
  },
  methods: {
    getItemPrices() {
      if (this.encounter && this.encounter.lab_test_prescription && this.encounter.lab_test_prescription.length > 0) {
        const item_code_list = this.encounter.lab_test_prescription.map(item => ({ item_code: item.lab_test_code, qty: 1 }))
        getOrderedItemPrices({ item_code_list, patient_name: this.patient || this.selectedPatient.patient, service_unit: '' }).then(values => {
          // console.log("fetching prices",values)
          this.priceInformation = values
        })
      }
    },
    printLabPrescription() {
      //  this.getItemPrices();
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
        + 'doctype='}${encodeURIComponent('Patient Encounter')
      }&name=${encodeURIComponent(this.encounter.name)
      }&format=Patient Encounter Labs&_lang=en&trigger_print=1`))
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    UpdateEncounter(encounter) {
      this.$store.dispatch('encounter/setEncounter', encounter)
    },
    formSubmitted(values) {
      if (!this.currentEncounter.name) {
        createPatientEncounter(this.patient || this.selectedPatient.patient, this.walkin).then(val => {
          addALabPrescription({
            encounter_name: val.name,
            lab_test_code: values.lab_test_code,
            lab_test_comment: values.lab_test_comment,
            lab_test_priority: values.lab_test_priority,
          }).then(encounter => {
            createPatientEncounter(this.patient || this.selectedPatient.patient, this.walkin).then(val => {
              this.UpdateEncounter(val)
            })
          })
        })
      } else {
        addALabPrescription({
          encounter_name: this.currentEncounter.name,
          lab_test_code: values.lab_test_code,
          point_of_care_lab_test: values.point_of_care_lab_test,
          lab_test_comment: values.lab_test_comment,
          lab_test_priority: values.lab_test_priority,
        }).then(encounter => {
          this.UpdateEncounter(encounter)
          this.getItemPrices()
        })
      }
    },
    postResults(lab_test_code, name) {
      let dialog
      const fields = [
        {
          label: 'Result Value',
          fieldname: 'result_value',
          fieldtype: 'Data',
          reqd: true,
          onchange(e) {
            getNormalRange(lab_test_code).then(r => {
              dialog.set_values({
                normal_range: r,
              })
              getRangeNote(lab_test_code, dialog.get_value('result_value')).then(r => {
                if (r === 'Results are within the normal range') {
                  dialog.set_values({
                    range_note: r,
                  })
                } else {
                  dialog.set_values({
                    range_note: `<p style="color:red">${r}</p>`,

                  })
                }
              })
            })
          },
        },
        {
          label: 'UOM',
          fieldname: 'lab_test_uom',
          fieldtype: 'Link',
          options: 'Lab Test UOM',
          reqd: true,
        },
        {
          label: 'Normal Range',
          fieldname: 'normal_range',
          fieldtype: 'Data',
          reqd: true,
        },
        {
          fieldname: 'range_note',
          fieldtype: 'Read Only',
        },
        {
          label: 'Comment',
          fieldname: 'lab_result_comment',
          fieldtype: 'Text',
        },
      ]
      dialog = new frappe.ui.Dialog({
        title: 'Post Lab Test Results',
        fields,
        primary_action_label: 'Submit',
        primary_action: values => {
          postPOCLabTestResults({
            lab_test_name: lab_test_code,
            prescription_name: name,
            result_value: values.result_value,
            lab_test_uom: values.lab_test_uom,
            normal_range: values.normal_range,
            lab_result_comment: values.lab_result_comment,
          }).then(data => {
            dialog.hide()
            if (data === 'Paid') {
              this.show_alert(
                {
                  message: ('Results successfully posted'),
                  indicator: 'green',
                },
                5,
              )
            } else {
              this.msgprint({
                title: ('Notification'),
                indicator: 'red',
                message: ('Results cannot be posted until payment is made'),
              })
            }

            getLabPrescriptions(this.patient || this.selectedPatient.patient).then(labprescriptions => {
              this.labprescriptions = labprescriptions
            })
          })
        },
      })
      dialog.show()
    },
    cancelLabPrescription(parent, name) {
      let dialog
      const dialogContext = this
      dialog = new frappe.ui.Dialog({
        title: 'Confirm cancellation of this lab test',
        primary_action_label: 'Confirm',
        primary_action: values => {
          cancelLabPrescription(parent, name)
            .then(encounter => {
              this.UpdateEncounter(encounter)
              // run fetch cancelled lab prescriptions
              getCancelledLabPrescriptions(dialogContext.patient || dialogContext.selectedPatient.patient).then(cancelledlabprescriptions => {
                dialogContext.cancelledlabprescriptions = cancelledlabprescriptions
                // run fetch lab prescriptions
                getLabPrescriptions(dialogContext.patient || dialogContext.selectedPatient.patient).then(labprescriptions => {
                  dialogContext.labprescriptions = labprescriptions
                })
              })
            })
          dialog.hide()
        },
      })
      dialog.show()
    },
    deleteLabPrescription(parent, name) {
      deleteLabPrescription(parent, name)
        .then(encounter => this.UpdateEncounter(encounter))
      dialog.hide()
    },
  },
}
</script>
<style scoped>
.flex-end {
  display: flex;
  justify-content: flex-end;
}

svg {
  width: 14px !important;
}
</style>
