<template>
  <div>
    <b-button
      block
      variant="primary btn-sm"
      @click="$bvModal.show('add_la_modal')"
    >
      <i
        class="fa fa-volume-up"
        aria-hidden="true"
      /> Call Patient
    </b-button>
    <div>
      <b-modal
        v-if="isVisible"
        id="add_la_modal"
        hide-footer
      >
        <template #modal-title>
          Call this patient for service
        </template>
        <div>
          <b-button
            class="pull-right"
            variant="primary btn-sm"
            @click="callPatient"
          >
            Confirm
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { callPatientIn, stopPatientAnnouncement } from './service'

export default {
  name: 'CallPatientInQueue',
  props: {
    details: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: 1,
    }
  },
  computed: {
    patient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    selectedPatientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
  },
  created() {},
  methods: {
    callPatient() {
      this.isVisible = 0
      callPatientIn(this.details.queue_name).then(
        res => {
          setTimeout(this.stopAnnouncement, 20000)
          this.show_alert({
            message: ('Patient call announced'),
            indicator: 'green',
          }, 5)
          this.isVisible = 1
        },
      )
    },
    stopAnnouncement() {
      stopPatientAnnouncement(this.details.queue_name).then(
        res => {
          this.show_alert({
            message: ('Patient announcement Stopped'),
            indicator: 'warning',
          }, 5)
        },
      )
    },
  },

}
</script>

<style>

</style>
