<template>
  <b-container>
    <div
      class=""
      style="width:100%;font-size: smaller;"
    >
      <b-row style="width:100%">
        <b-row
          class="w-100"
          style="width:100%"
        >
          <b-row
            style="width: 100%"
            class="px-0 "
            align-h="end"
          >
            <b-button
              size="sm"
              variant="primary"
              class=""
              @click="saveDoctypeToDb()"
            >
              <i class="fa fa-save" /> Update
            </b-button>
            <b-button
              v-if="false"
              size="sm"
              variant="primary"
              class="mx-1"
              @click="modalShow = !modalShow"
            >
              <i class="fa-street-view" /> View
            </b-button>
          </b-row>

          <b-modal
            id="modal-1112"
            v-model="modalShow"
            size="xl"
            title="Preview"
          >
            <b-row
              v-if="formInputData != null && Object.keys(formInputData).length"
              style="width: 100%"
              class="px-1"
            >
              <FormRenderer
                v-model="formInputData"
                :parent="parent"
                :form-configuration="formData"
              />
            </b-row>
          </b-modal>
          <b-row
            v-show="selected == 'A'"
            style="width: 100%"
            class="px-1"
          >
            <FormRenderer
              v-model="formInputData"
              :parent="parent"
              :form-configuration="formData"
            />
          </b-row>
        </b-row>
      </b-row>

      <b-row v-if="response && response.reference_doctype_id && selected == 'B'">
        <list-component
          :filters="{ reference_doctype_id: response.reference_doctype_id }"
          :doctype="'Form Repository'"
        />
      </b-row>

      <b-modal
        id="bv-modal-example"
        hide-footer
        size="xl"
      >
        <template #modal-title>
          Using <code>$bvModal</code> Methods
        </template>

        <FormRenderer
          v-model="formInputData"
          :parent="parent"
          :form-configuration="formData"
        />
        <b-button
          class="mt-3"
          block
          @click="$bvModal.hide('bv-modal-example')"
        >
          Close Me
        </b-button>
      </b-modal>
      <b-modal
        id="modal-id-1"
        ref="save_modal"
        size="sm"
        hide-footer
      >
        <template #modal-title>
          Confirm save form data
        </template>
        <b-row
          id="modal-body"
          class="modal-padding"
        >
          <b-col
            ref="doctype"
            :cols="12"
            class="ref-field-input"
          >
            <doctype-link
              :doctype="'Healthcare Service Unit'"
              :placeholder="'Doctype'"
              @selected="makeSelectDoctypeControl"
            />
          </b-col>

          <b-col
            v-show="selectedDoctype"
            id="ref-field"
            ref="docId"
            :cols="12"
            class="ref-field-input"
          />

          <b-col
            id="form"
            :cols="12"
            class="ref-field-input"
          />
        </b-row>

        <b-button
          variant="primary"
          class="mt-3 "
          block
          @click="save()"
        >
          Save
        </b-button>
      </b-modal>
    </div>
  </b-container>
</template>
<script>

import { FormRenderer } from 'vue-frappe-formbuilder-health'
import ListComponent from '@/views/components/doctype-list/ListComponent.vue'
import {
  getFormConfiguration,
  updateDoctypeData,
  updateFormData,

} from './service'

export default {
  name: 'FormRenderViewx',
  components: {
    ListComponent,
    FormRenderer,
  },
  props: {
    selectedDoctype: { type: String },
    selectedDoctypeReference: { type: String },
    currentFormName: { type: String, default: null },
    sendDataToTimeline: { type: Boolean, default: false },
    patient: { type: Object },
    dataInput: { type: Object },
    response: { type: Object },
    isReadonly: { type: Boolean, default: false },
    parent: { type: String },
  },

  data() {
    return {
      some_data: 'This is a vue demo',
      date: null,
      previewMode: false,
      modalShow: false,
      title: '',
      department: '',
      tableName: '',
      configuration: {},
      formData: null,
      formName: null,
      selectedItem: null,
      formInputData: null,
      originalConfig: null,
      allFormConfigurationData: null,
      changeLog: [],
      filters: { reference_doctype_id: this.response.reference_doctype_id },
      items: [],
      selected: 'A',

    }
  },
  watch: {
    dataInput(newVal) {
      this.setValues(newVal)
      // this.formInputData = newVal
    },

    previewMode(newVal, oldVal) {
      if (newVal) {
        const conf = this.configuration
        keys = Object.keys(newVal)
        keys.forEach(key => {
          this.formInputData[`${key}`] = newVal[`${key}`]
        })

        this.formData = conf
      }
    },
    selectedDoctype(doctype) {
      if (doctype) {
        this.makeDoctypeItemControl(doctype)
      }
    },
    currentFormName(name) {
      if (name) {
        this.getForm(name)
      }
    },
  },

  created() {
    if (!this.currentFormName) {
      this.getForm(formName)
    } else {
      this.getForm(this.currentFormName)
    }
  },

  mounted() {
    this.$formEvent.$on('submit', value => { })
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'modal-id-1') {
        this.makeSelectDoctypeControl()
      }
    })
    const context = this
    setTimeout(() => {
      context.populate()
    }, 1000)
  },
  methods: {
    openTable() {
      alert('Hellow')
    },
    clearData() {
      const val = {}
      const keys = Object.keys(this.formInputData)
      keys.forEach(key => {
        val[key] = ''
      })

      this.setValues(val)
    },
    populate() {
      this.setValues(this.dataInput)
    },

    setValues(val) {
      this.$set(this, 'formInputData', val)
    },
    getFormData() {
      console.log(JSON.stringify(this.formData))
      const data = this.formInputData
      const returnedTarget = { ...data }
      this.items.unshift(returnedTarget)
    },
    getFormInputData() { },
    exportData() {
      this.showModal()
    },
    previewForm() {
      this.previewMode = !this.previewMode
    },
    showModal() {
      this.$refs.save_modal.show()
    },
    clear() {
      this.formData = null
      this.formData = this.originalConfig
    },
    makeSelectDoctypeControl(value) {
      this.selectedDoctype = value
    },
    get_new_frm(_frm) {
      const doctype = 'Patient'
      const page = $('#form')

      const layout = new frappe.ui.form.Layout({
        parent: page,
        doctype,
        doctype_layout: null,
        frm: {},
        with_dashboard: false,
        card_layout: true,
      })
      layout.make()
      console.log(layout)

      return frm
    },

    makeDoctypeItemControl(doctype) {
      const div = $('#ref-field')
      div.empty()
      const me = this
      const customer_field = frappe.ui.form.make_control({
        df: {
          label: ('Reference Id'),
          fieldtype: 'Link',
          fieldname: 'itemControl',
          options: doctype,
          placeholder: ('Reference Id'),
          onchange() {
            if (this.value) {
              me.selectedDoctypeReference = this.value
            }
          },
        },
        parent: this.$refs.docId,
        render_input: true,
      })
      customer_field.toggle_label(false)
      $('#modal-body')
        .find('.input-max-width')
        .removeClass('input-max-width')
    },

    saveForm(formData) {
      this.hideModal()
      saveFormData(formData).then(saved => {
        this.show_alert(`Form Saved ${saved.name}`, 5)
        this.formData = null
        this.setValues({})
        this.selectedDoctype = null
        this.selectedDoctypeReference = null
        this.sendToTimeline(
          this.patient.patient,
          'Form Repository',
          saved.name,
          saved.owner,
          saved.form_name,
        )
      })
    },

    getForm(name) {
      getFormConfiguration({ name }).then(config => {
        const formStringConfig = config.formdata
        const configObject = JSON.parse(formStringConfig)

        this.formName = config.name
        this.formData = configObject
        this.originalConfig = configObject
      })
    },

    hideModal() {
      this.$refs.save_modal.hide()
    },
    navigateToList() {
      const formName = frappe.get_route()[2]
      this.tableName = formName.split('-')[1]
      // frappe.set_route("List", this.tableName);
    },

    sendToTimeline(
      patient,
      reference_doctype,
      reference_name,
      reference_owner,
      description,
    ) {
      if (!this.sendDataToTimeline) {
        return
      }
      const formData = {
        reference_doctype,
        reference_name,
        doctype: 'Patient Medical Record',
        patient,
        description,
        status: 'Open',
        reference_owner,
      }

      saveFormData({ formData }).then(saved => {
        alert(JSON.stringify(saved))
        this.show_alert('Timeline updated', 5)
      })
    },
    save() {
      let form_content = this.formInputData
      form_content = JSON.stringify(form_content)
      this.allFormConfigurationData = this.response
      const form_name = this.formName
      const reference_doctype = this.allFormConfigurationData.owner_doctype
        || this.selectedDoctype
        || this.formData.formConfig.mappedDoctype
      let reference_doctype_id = null
      if (this.allFormConfigurationData.context_reference) {
        reference_doctype_id = this.context[this.allFormConfigurationData.context_owner_name]
          || this.selectedDoctypeReference
          || this.savedDocument.name
      } else {
        reference_doctype_id = this.allFormConfigurationData.owner_doctype_reference
          || this.selectedDoctypeReference
          || this.savedDocument.name
      }

      const { name } = this.response

      const doctype = 'Form Repository'
      const formData = {
        doctype,
        name,
        form_content,
        form_name,
        mapped_doctype_name: this.mappedDoctypeName,
        mapped_doctype: this.formData.formConfig.mappedDoctype,
        reference_doctype,
        reference_doctype_id,
        completion_status: 'Completed',
        completed: 1,
      }

      updateFormData({ formData }).then(saved => {
        this.show_alert(`Form updated_ ${saved.name}`, 5)
        this.sendToTimeline(
          reference_doctype_id,
          'Form Repository',
          saved.name,
          saved.owner,
          saved.form_name,
        )
      })
    },

    async saveDoctypeToDb() {
      if (this.formData.formConfig.mappedDoctype) {
        console.log('FRM', 'mapped doctype')
        const keys = Object.keys(this.formInputData)
        const formData = { doctype: this.formData.formConfig.mappedDoctype, name: this.response.mapped_doctype_name }

        keys.forEach(key => {
          if (this.formData.controls[key]) {
            const control = this.formData.controls[key]
            if (
              control
              && (control.type === 'radio'
                || control.type === 'dropDown'
                || control.type === 'checkbox')
            ) {
              if (control.items.length) {
                const erpValueObject = control.items.find(
                  item => item.value === this.formInputData[key],
                )
                const field = this.formData.controls[key].mappedField
                if (field) {
                  formData[field] = erpValueObject.erpValue
                }
              }
            } else {
              const field = this.formData.controls[key].mappedField
              if (field) {
                formData[field] = this.formInputData[key]
              }
            }
          } else if (Array.isArray(this.formInputData[key])) {
            formData[key] = this.formInputData[key]
          }
        })

        const result = await this.docSetValue('Form Repository', this.selectedDoctypeReference, 'form_content', JSON.stringify(this.formInputData))

        if (result != null) {
          this.show_alert('Form Updated')
          this.savedDocument = saved
        }

        // updateDoctypeData({ formData }).then((saved) => {
        //   this.show_alert("Saved", 3);
        //   this.savedDocument = saved;
        //   this.save();
        // });
      } else {
        console.log('FRM', 'getFormData')
        this.getFormData()
      }
    },
  },
}
</script>
<style scoped>

.row {
  margin: 0px !important
}

.spacer {
  margin-top: 10px;
}

.space-right {
  margin-right: 10px !important;
  padding-right: 10px;
}

.spacer-left {
  margin-left: 10px !important;
  padding-left: 10px;
}

.save-btn {
  color: white;
  margin-top: 2%;
}

.spacer-left-5 {
  margin-left: 5px !important;
  padding-left: 5px;
}

.render-form {
  padding-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 50px;
}

.white-text {
  color: white;
  margin-left: 10px;
}

.form-border {
  border: 1px solid darkgray;

  margin-top: 20px;
  padding-bottom: 30px;
  margin-bottom: 50px;
}

.main-padding {
  padding-bottom: 50px;
  padding-right: 50px;
}

.main-page {
  margin-top: 10px;
  padding-right: 30px;
}

.top-margin {
  margin-top: 20px;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 0px !important;
}

.card-body {
  padding: 0px !important;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.fb-area {
  padding-top: 20px;
  border-radius: 10px;
}

.container-fluid {
  background-color: white;
  border-radius: 10px;
}

.space-left {
  margin-left: 8px;
}

.ref-field-input {
  width: 160px;
  padding-left: 0px;
}

.input-max-width {
  width: 100% !important;
  min-width: 300px;
}

.modal-padding {
  padding-left: 15px;
}

.upper-case {
  text-transform: uppercase;
}
</style>
