<template>
  <div class="w-100">
    <b-button
    class="w-100"
      block
      variant="primary btn-sm"
      @click="$bvModal.show('add_ll_modal')"
    >
      Appointment
    </b-button>
    <div>
      <b-modal
        id="add_ll_modal"
        size="xl"
        hide-footer
      >
        <template #modal-title>
          Book this patient for an appointment
        </template>
        <div>
          <new-appointment :patient-from-chart="selectedPatientDetails.name" />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import NewAppointment from '@/views/erp/records/appointment/BookAppointment.vue'

export default {
  name: 'NextAppointment',
  components: {
    NewAppointment,
  },
  data() {
    return {

    }
  },
  computed: {
    selectedPatientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
  },
  created() {
  },
}
</script>

<style></style>
