<template>
  <div v-if="currentFormName || loadedFormName">
    {{ currentFormName }}
    <form-render-viewx
      :parent="loadedFormName"
      :current-form-name="currentFormName"
      :selected-doctype-reference="formId"
      :response="response"
      :data-input="dataInput"
      :is-readonly="true"
    />
  </div>
</template>

<script>
import FormRenderViewx from '@/views/components/formbuilder/FormRenderViewx.vue'
import { getDoctypeByName } from './services'

export default {
  name: 'SpecificForm',
  components: { FormRenderViewx },
  props: {
    inputName: { type: String },
    formId: { type: String },
    loadedFormName: { type: String },
  },
  data() {
    return {
      dataInput: {},
      response: {},
      currentFormName: null,
      repoType: null,
      repoName: null,
    }
  },
  created() {
    this.getFormData()
  },
  methods: {
    getFormData() {
      const doctype = 'Form Repository'
      const name = this.formId
      this.currentFormName = this.loadedFormName
      getDoctypeByName({ name, doctype }).then(data => {
        this.response = data
        this.dataInput = JSON.parse(data.form_content)
      })
    },
  },
}
</script>

<style></style>
