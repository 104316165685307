import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const submitEncounter = (name = '', patient = '', service_unit = {}, to_bill = true, origin, isWalkIn) => {
  console.log(JSON.stringify({
    name,
    patient,
    doctype: 'Patient Encounter',
    service_unit,
    to_bill: to_bill ? 1 : 0,
    origin,
    is_walk_in: isWalkIn,
  }))
  return api({
    method: 'clinical.api.patient_encounter.patient_encounter.submit',
    args: {
      name,
      patient,
      doctype: 'Patient Encounter',
      service_unit,
      to_bill: to_bill ? 1 : 0,
      origin,
      is_walk_in: isWalkIn,
    },
  })
}

export const validatePFNumber = number => api({
  method: 'clinical.api.inpatient_drug_administration.medication_entry.validate_employee',
  freeze: false,
  args: {
    number: number.trim(),
  },
})

export const getEncounterItemAndPrice = (encounter, service_unit) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.get_encounter_billing_item_and_price',
  args: {
    encounter,
    service_unit,
  },
})
