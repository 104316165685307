<template>
  <div class="card w-100 p-1 ">
    <b-row class="w-100 ml-1 px-1 pt-1">
      <form-select
        class="w-100"
        :patient="patient"
      /> </b-row>
    <b-row class="w-100">
      <saved-forms
        class="w-100"
        :patient="patient"
      /></b-row>

  </div>
</template>

<script>
import FormSelect from './components/FormSelect.vue'
import SavedForms from './components/SavedForms.vue'

export default {
  name: 'FormsTab',
  components: {
    FormSelect,
    SavedForms,
  },
  props: {
    patient: { type: Object },
  },
}
</script>

<style scoped>
.card {
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%);
    transition: 0.0s;
    min-height: calc(100vh - 265px);
    border: 1px solid lightgray;
}

.tab-content-height {
  min-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 66vh !important;
}
.tab-content-height-expanded {
  min-height: 200px;
  overflow-y: scroll;
  scrollbar-width: thin;
  overflow-x: hidden;
  height: 66vh !important;
}
.spinner-grow-sm {
  width: 7px;
  height: 7px;
  margin-bottom: 3px;
}
.spinner-grow {
  display: inline-block;
  width: 7px;
  height: 7px;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 1;
  -webkit-animation: spinner-grow 0.75s linear 1;
  animation: spinner-grow 0.75s linear 1;
}
.scroll-to-top {
  position: absolute;
  right: 34px;
  bottom: 24px;
  z-index: 1000;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.1);
  font-size: large;
}

#button {
  display: inline-block;
  background-color: #ff9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  opacity: 1;
  visibility: hidden;
  z-index: 1000;
}
.primary {
  color: #ff9800;
}
</style>
