<template>
  <b-container class="side-bar-area aside-bottom-padding">
    <b-row>
      <service-unit-select
        v-show="!fullScreenWorkArea"
        @service-unit="setServiceUnit"
      />
    </b-row>

    <b-row>
      <doctype-link
        style="border-radius: 5px;"
        class="mx-1 my-1 w-80 bg-white"
        :doctype="'Clinical Procedure Template'"
        @selected="changeTemplate"
      />
    </b-row>
    <div class="content-area">
      <b-row>
        <b-col cols="12">

          <b-col
            v-show="!fullScreenWorkArea"
            cols="12"
            class="search-input no-border"
          >

            <div
              style="text-align:left;"
              class="title"
            >
              <p style="color:lightseagreen;">
                Selected Template:{{ selectedTemplate }}
              </p>

            </div>
          </b-col>

          <b-col
            v-show="inSearchMode && !fullScreenWorkArea"
            cols="12"
            class="no-results"
          >
            <span> {{ searchResultText }}</span>
            <b-button
              v-if="apiList.length === 0"
              @click="clearSearch"
            >clear
            </b-button>
          </b-col>

          <b-col
            cols="12"
            :class="fullScreenWorkArea ? closed : nothing"
          >
            <b-list-group style="margin-left: 3px;">
              <b-list-group-item
                v-for="item of apiList"
                :key="item.name"
                :style="`background: ${item.name === queryParams.template ? '#f3f5f6' : ''};`"
                class="mt-1 item-link"
                @click="selectTemplate(item.name)"
              >
                {{ item.name }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import PatientList from '@/views/erp/clinical/sidebar/PatientList.vue'
import SearchInput from '@/views/erp/clinical/sidebar/SearchInput.vue'
import ServiceUnitSelect from '@/views/erp/clinical/sidebar/ServiceUnitSelect.vue'
import procedure from '@/views/erp/state/clinical_procedures/procedures'
// import socketIo from 'socket-io'

export default {
  name: 'SideBar',
  components: {
    PatientList,
    SearchInput,
    ServiceUnitSelect,
  },
  props: {
    fullScreenWorkArea: {
      type: Boolean,
      default: false,
    },
    // apiList: {
    //   type: Array,
    //   default: []
    // },
    searchResultText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      placeholder: 'Search patient...',
      total: 50,
      // apiList: [],
      inSearchMode: false,
      currentServiceUnit: null,
      currentSelectedPatient: null,
      closed: 'closed',
      nothing: 'nothing',
      selectedTemplate: '',
      filterdList: [],
    }
  },
  computed: {
    apiList() {
      if (this.$store.getters['procedures/getProceduretemplates'].length > 0) {
        this.selectedTemplate = this.$store.getters['procedures/getProceduretemplates'][0].name
      }
      return this.$store.getters['procedures/getProceduretemplates']
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    queryParams() {
      return this.$store.getters['procedures/getQueryParams']
    },

  },
  watch: {
    selectedServiceUnit(unit) {
      console.log('Lecole', 'Service Unit changing')
      const payload = { serviceUnit: unit.service_unit, search: '' }
      const time = Date.now()

      const currentUrl = window.location.href
      let is_radiology = 0

      // Check if the URL contains the string "radiology"
      if (currentUrl.indexOf('radiology') !== -1) {
        is_radiology = 1
      }
      // this.$store.dispatch("patient/fetchPatients", payload)
      if (this.selectedServiceUnit.service_unit) {
        // this.$store.dispatch('procedures/setQueryParams', { service_unit: this.selectedServiceUnit.service_unit })
        this.$store.dispatch('procedures/fetchProcedureTemplates', { service_unit: this.selectedServiceUnit.service_unit, is_radiology })
        // this.$store.dispatch("procedures/fetchProcedureStatistics", { ...this.queryParams, service_unit: this.selectedServiceUnit.service_unit });
      }
    },
    selectedTemplate(template) {
      this.$store.dispatch('procedures/fetchProcedures', { service_unit: this.selectedServiceUnit.service_unit, template })
    },
    // queryParams() {
    //     console.log("Lycee","Params changing")
    //     // this.$store.dispatch("procedures/fetchProcedures", this.queryParams);
    //     // this.$store.dispatch("procedures/fetchProcedureTemplates", this.queryParams);
    //     // this.$store.dispatch("procedures/fetchProcedureStatistics", this.queryParams)
    // }
  },
  mounted() {
    this.makeFields()
  },
  created() {
    this.$store.registerModule('procedures', procedure)
    // this.$store.registerModule('serviceUnit', serviceUnit)
  },
  methods: {
    toggleFullView() {
      this.fullScreenWorkArea = !this.fullScreenWorkArea
      this.$emit('full-screen-work-area', this.fullScreenWorkArea)
    },
    handleSearch(search) {
      this.$emit('search', search)
    },

    patientSelected(patient) {
      this.currentSelectedPatient = patient
      this.$emit('selected-patient', patient)
    },
    emitPage(page) {
      this.$emit('current-page', page)
      // get next or previous page
    },
    getPatientList(serviceUnitName, search) {
      // const payload = { serviceUnit: serviceUnitName, search: search }
    },
    changeTemplate(template) {
      this.$store.dispatch('procedures/fetchProcedureTemplates', { service_unit: this.selectedServiceUnit.service_unit, search: template })
      this.selectedTemplate = template
    },
    selectTemplate(template) {
      this.selectedTemplate = template
    },
    setServiceUnit(serviceUnit) {
      this.$emit('selected-service-unit', serviceUnit)
    },
    clearSearch() {
      this.inSearchMode = false
      this.$emit('clearSearch')
    },
    makeFields() {
      const currentUrl = window.location.href
      let is_radiology = 0
      if (currentUrl.indexOf('radiology') !== -1) {
        is_radiology = 1
      }
    },
  },
}
</script>

<style scoped>
.no-sides {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.item-link {
    cursor: pointer;
}

.side-bar-area {
    background: white;
    width: 100% !important;
    padding-top: 20px;
    /* min-height: 500px; */
    height: 85vh;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: rgba(211, 211, 211, 0.423);
    border-radius: 5px 0px 0px 0px;

    padding-left: 11px !important;
    padding-right: 11px !important;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-weight: 900px;
    color: black !important;
    margin-left: 4px;
}

.name-header {
    /* background: #687178; */
    color: white;
    padding: 14px;
    border-radius: 0px 16px 16px 0px;
    width: 95%;
    font-weight: 800;
}

.search-input {
    margin-top: 5%;
}

.no-results {
    margin-top: 5%;
    padding-left: 7%;
}

.rounded {
    border-radius: 12px;
}

/* .top-margin-space {
    margin-top: 16px;
    padding-bottom: 16px;
} */

.no-border {
    border: 0px solid black !important;
}

.form-control {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #d8d6de;
    border-radius: 0.357rem;
    width: 100%;
    font-weight: 400;
    line-height: 1.45;
    font-size: 13px;
    color: #6e6b7b;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn-custom {
    height: 36px;
}

.menu-options {
    margin-left: 0px;
    font-size: 18px;
    margin-top: 12px;
    width: 100%;
}

.closed {
    padding-right: 0px !important;
    padding-left: 6px !important;
}

.content-area {
    height: 76vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    background: white;
    border-radius: 5px;
    margin-right: 4px;
    margin-top: 10px;
}

.psm {
    display: inline-block;
    border-radius: 50px;
    box-shadow: 0px 0px 2px #888;
    height: 40px;
    width: 40px;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
    position: relative;
}

#ico {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 15px;
    display: block;
}</style>
