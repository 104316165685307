<template>
  <b-container class="">
    <b-row class="mr-1 mb-1 pr-2">
      <b-col /> <b-button v-if="current != 1" size="sm" variant="outline-secondary" class="mx-1" @click="goBack()">
        Go Back({{ current }})
      </b-button> <b-button size="sm" variant="dark" @click="reloadPage()">
        Refresh
      </b-button>
    </b-row>

   
    <b-row v-if="current == 1" class="w-100" align-h="center">
      <b-list-group style="width: 100% !important">
        <b-list-group-item v-for="form in formList" :key="form" class="w-100">
          <b-row class="w-100 " align-h="between">

            <div>
              {{ form.form_name }}
              <b-badge variant="dark" pill>
                {{ form.count }}
              </b-badge>
            </div>

            <b-row>

              <b-button style="margin-right: 5px;" @click="selectForm(form.form_name)" size="sm" variant="dark"> <i
                  class="fa-solid fa-list"></i></b-button>

              <b-button @click="addForm(form.form_name)" size="sm" variant="dark"> <i
                  class="fa-solid fa-plus"></i></b-button>
            </b-row>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-row>

    <b-row v-if="current == 2">
      <specific-form-list :form="form" :patient="currentPatient" style="width: 100% !important"
        @selectForm="setFormSpecificType" />
    </b-row>

    <b-row v-if="current == 3" style="width: 100% !important">
      <specific-form :form-id="formId" :loaded-form-name="form" style="width: 100% !important" />
    </b-row>

    <b-modal :id="id" no-close-on-backdrop size="xl" :title="selectedDoctype">
      <b-row v-if="selectedDoctype != ''">
        <t-frm :current-form-name="selectedDoctype" :patient="patientData" :selected-doctype="referenceDoctype"
          :selected-doctype-reference="patientData.patient" />
      </b-row>

      <template #modal-footer="{ cancel, ok }">
        <span />
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import FormSelect from './FormSelect.vue'
import { getFormList } from './services'
import SpecificFormList from './SpecificFormList.vue'
import SpecificForm from './SpecificForm.vue'

export default {
  name: 'PatientInputForm',
  components: {
    FormSelect,
    SpecificForm,
    SpecificFormList,
  },
  props: {
    patient: { type: Object },
  },
  data() {
    return {
      formList: [],
      current: 1,
      form: null,
      formId: null,
      currentPatient: null,
      selectedDoctype: "",
      id: "1239848"
    }
  },
  computed: {
    patientData() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },
  watch: {
    patientData(val) {
      this.currentPatient = val.patient
      this.getList()
      this.current = 1
    },
  },
  created() {
    this.patient = this.currentPatient
    this.getList()
    this.current = 1
  },
  mounted() {
    this.getList()
    this.current = 1
  },
  methods: {
    async getList() {
      if (this.patientData) {
        const list = await getFormList(this.patientData.patient)
        this.formList = this.orderArray(list)
      }
    },

    goBack() {
      this.current -= 1
    },
    reloadPage() {
      this.getList()
      this.current = 1
      this.show_alert(`Fetching forms for ${this.patientData.patient_name}`)
    },
    selectForm(form) {
      this.form = form
      this.current = 2
    },
    setFormSpecificType(formId) {
      this.formId = formId
      this.current = 3
    },
    addForm(name) {
      this.selectedDoctype = name
      this.$bvModal.show(this.id)
    },
    orderArray(data) {
      // Desired order for specific form names
      const desiredOrder = ["Vital Signs", "Doctors notes", "Kardex Notes"];

      // Create a map for easy lookup
      const orderMap = new Map(desiredOrder.map((name, index) => [name, index]));

      // Custom sorting function
      data.sort((a, b) => {
        const orderA = orderMap.get(a.form_name);
        const orderB = orderMap.get(b.form_name);

        if (orderA === undefined) {
          return 1; // Move forms not in desiredOrder to the end
        } else if (orderB === undefined) {
          return -1; // Move forms not in desiredOrder to the end
        }

        return orderA - orderB;
      });

      // Insert missing forms with count 0
      desiredOrder.forEach(name => {
        const existingIndex = data.findIndex(item => item.form_name === name);
        if (existingIndex === -1) {
          data.push({ form_name: name, count: 0 });
        }
      });

      return data;
    }
  },
}
</script>

<style scoped>
.card {
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%);
  transition: 0s;
  min-height: calc(100vh - 265px);
}
</style>
