<template>
  <div>
    <b-container v-if="patientDocumentsItems.length > 0">
      <div>
        <b-row v-if="false">
          <div
            style="padding-top: 10px"
            class="mx-auto"
          >
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="btn-radios-1"
                v-model="selectedChart"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radio-btn-outline"
                button-variant="outline-primary"
                buttons
              />
            </b-form-group>
          </div>
        </b-row>
        <vitals-chart
          v-if="false"
          ref="VitalsChart"
          :patient="patient.patient"
        />
      </div>
      <b-row>
        <b-col
          cols="10"
          style="display: flex"
        >
          <b-form-datepicker
            id=""
            v-model="date_from"
            class="mb-2"
            placeholder="Select start date"
            @input="dateChanged"
          />
          <span
            class="label-area"
            style="padding: 8px"
          >-</span>
          <b-form-datepicker
            id=""
            v-model="date_to"
            class="mb-2"
            placeholder="Select end date"
            @input="dateChanged"
          />
        </b-col>

        <b-col cols="2">
          <b-button
            v-b-tooltip.hover
            v-b-toggle.sidebar-right
            title="Apply filters"
            variant="outline-secondary"
          >
            <i
              class="fa fa-sliders"
              style="width: 10px !important;"
              aria-hidden="true"
            />
          </b-button>
        </b-col>
      </b-row>

      <!-- sidebar for filters -->
      <b-sidebar
        id="sidebar-right"
        title="Settings"
        style="text-size: 14px"
        :z-index="zIndex"
        right
        shadow
      >
        <hr>
        <div class="px-3 py-2">
          <div class="form-group frappe-control input-max-width">
            <b-row><h4 class="ml-3">
              Timeline Filters
            </h4></b-row>
            <b-row style="margin-bottom:30px;"><i style="margin-top:1.5%;"><b><h5 class="ml-3">Clear All Filters</h5></b></i>
              <b-form-checkbox
                size="lg"
                switch
                @change="clearFilters()"
              />
            </b-row>
            <div
              v-for="(tab, index) in objectTabList"
              :key="tab.tab"
              class="checkbox ml-3"
              style="margin-bottom: 15px"
            >
              <b-row>
                <b-col
                  cols="9"
                  style="margin-top:8%;"
                >
                  <span style="font-size: 15px">{{ tab.tab }}</span></b-col>
                <b-col
                  cols="3"
                  class="pull-right"
                >
                  <b-form-checkbox
                    v-model="tab.isChecked"
                    size="lg"
                    switch
                    @change="onChecked($event, tab, index)"
                  /></b-col>
              </b-row>
            </div>
          </div>
        </div>

      </b-sidebar>

      <div
        v-for="patientDocumentsItem in patientDocumentsItems"
        id="crd-bdy"
        :key="patientDocumentsItem.name"
        class="card-body"
        style="background-color: #f4f5f6"
      >
        <!-- Timeline start -->
        <div class="timeline">
          <div class="timeline-row">
            <div class="timeline-time">
              <small>
                <i
                  data-v-17a8447b=""
                  class="octicon octicon-clock"
                  style="color: rgb(141, 153, 166)"
                /> {{ patientDocumentsItem.creation | filterDate }} at {{ patientDocumentsItem.creation | filterTime }} by {{ patientDocumentsItem.owner }}</small>
            </div>
            <div class="timeline-dot green-one-bg" />
            <div
              class="timeline-content green-one"
              style="color: black; background: white;"
            >
              <record-item
                :document-name="patientDocumentsItem.name"
                :record-id="patientDocumentsItem.reference_name"
                :record-type="patientDocumentsItem.reference_doctype"
                :subject="patientDocumentsItem.subject"
              />
            </div>
          </div>
        </div>
        <!-- Timeline end -->
      </div>
    </b-container>
    <b-container v-if="patientDocumentsItems.length < 1 && noItems">
      <b-row id="over">
        <img
          style="height: 300px; width: 300px"
          src="http://41.89.183.61/files/noitems.jpeg"
          alt="No Data"
        >
      </b-row>
      <b-row>
        <h3
          class="mx-auto"
          @click="testSocket()"
        >
          No Timeline data
        </h3>
      </b-row>
      <b-row><span class="mx-auto">All critical activities in relation to this patient will be shown
        here</span></b-row>
    </b-container>
  </div>
</template>

<script>
import ScrollTopArrow from '@/views/erp/clinical/working-area/components/patient-chart/components/scroll/ScrollTopArrow.vue'
import RecordItem from './RecordItem.vue'
import VitalsChart from './vitals-chart/VitalsChart.vue'
import { getOwnersName, getTimelineFeed, getMyPatientTabPermissionsForFilters } from './service'

export default {
  components: {
    RecordItem,
    VitalsChart,
    ScrollTopArrow,
  },
  props: {
    patient: Object,
    tabIndex: Number,
    isActive: Boolean,
    maxScrollPosition: Number,
    currentScrollPosition: Number,
    sourcePage: String,
  },
  data() {
    return {
      doctypes: {},
      date_from: '',
      date_to: '',
      patientDocumentsItems: [],
      // get todays date
      currentDate: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, '-')
        .concat(' 23:59:59.999'),
      filtersList: [],
      objectTabList: [],
      tabList: [],
      isVisible: 0,
      firstActive: 0,
      page: 0,
      lastLoaded: null,
      page_length: 5,
      noItems: false,
      zIndex: 10000,
      selectedChart: '1',
      options: [
        { text: 'Blood Pressure', value: '1' },
        { text: 'Respiration/Pulse', value: '2' },
        { text: 'Temperature', value: '3' },
        { text: 'BMI', value: '4' },
      ],
      isCleared: 0,
    }
  },
  computed: {
    socketData() {
      return this.$store.getters['socket/getSocketData']
    },
  },
  watch: {
    currentScrollPosition(val) {
      let percentage = (val / this.maxScrollPosition) * 100
      percentage = Math.floor(percentage)
      if (percentage === 100) {
        this.fetchNextPage()
      }
    },
    filtersList(newFiltersList) {
      // only make call if newFiltersList is not empty
      if (newFiltersList == '') {} else {
        this.getTimelineFeed(
          newFiltersList,
          this.patient.patient,
          '2012-01-01',
          this.currentDate,
          0,
          this.page_length,
        )

        // check if Vital Signs is in list to hide the chart
        if (newFiltersList.includes('Vital Signs')) {
          this.isVisible = 1
        } else {
          this.isVisible = 0
        }
      }
    },

    patient(current, old) {
      // Repopulate the filter list
      this.reset()
      this.filtersList = this.tabList
      // check all the checkboxes and dates
      this.objectTabList = this.tabList.map(tab => ({ tab, isChecked: true }))
      this.date_from = ''
      this.date_to = ''

      this.getTimelineFeed(
        this.filtersList,
        this.patient.patient,
        '2012-01-01',
        this.currentDate,
        0,
        this.page_length,
      )

      this.stopSocketListener(old)
      setTimeout(() => this.startSocketListener(current), 2000)
    },
    isActive() {
      if (this.isActive) {
        if (this.firstActive == 1) {
          this.getTimelineFeed(
            this.filtersList,
            this.patient.patient,
            '2012-01-01',
            this.currentDate,
            0,
            this.page_length,
          )
          this.$refs.VitalsChart.createBPChart()
        } else {
          this.$refs.VitalsChart.createBPChart()
        }
      } else {
        this.firstActive = 1
      }
    },
    selectedChart(val) {
      if (val === '1') {
        this.renderBPChart()
      } else if (val === '2') {
        this.renderRespPulseChart()
      } else if (val === '3') {
        this.renderTemperatureChart()
      } else if (val === '4') {
        this.renderBMIChart()
      }
    },
    patientDocumentsItems(timelineItems) {
      this.saveToStore(timelineItems)
    },
    socketData(data) {
      if (data.action === 'updateTimeline') {
        this.refreshTimeline()
      }
    },

  },

  created() {
    // fetch the filters list
    getMyPatientTabPermissionsForFilters().then(tabFilterList => {
      this.tabList = tabFilterList
      // Populate the filter list
      this.filtersList = this.tabList
      // check all the checkboxes
      this.objectTabList = this.tabList.map(tab => ({ tab, isChecked: true }))
      // make timeline feed call with returned list

      console.log('........souce Page', this.sourcePage)
      if (this.sourcePage == 'Clinical Procedure') {
        this.page_length = 40
      }
      this.getTimelineFeed(
        this.tabList,
        this.patient.patient,
        '2012-01-01',
        this.currentDate,
        this.page,
        this.page_length,
      )

      // check if Vital Signs is in list to hide the chart
      if (this.tabList.includes('Vital Signs')) {
        this.isVisible = 1
      } else {
        this.isVisible = 0
      }
    })

    console.log('Time line ccreat4ed')
  },
  mounted() {
    console.log('Time line mounted')
  },
  methods: {
    clearFilters() {
      if (this.isCleared == 0) {
        // uncheck all the checkboxes
        this.objectTabList = this.tabList.map(tab => ({ tab, isChecked: false }))
        this.filtersList = []
        this.isCleared = 1
      } else {
        this.objectTabList = this.tabList.map(tab => ({ tab, isChecked: true }))
        this.filtersList = this.tabList
        this.isCleared = 0
      }
    },
    saveToStore(timelineItems) {
      this.$store.dispatch('patient/setTimelineData', timelineItems)
    },
    sendSocketData(data) {
      this.$store.dispatch('socket/setSocketData', data)
    },
    startSocketListener(patient) {
      this.sendSocketData({
        action: 'on',
        event: patient.patient,
        messageType: 'subscriptionUpdate',
      })
    },
    stopSocketListener(patient) {
      this.sendSocketData({
        action: 'off',
        event: patient.patient,
        messageType: 'subscriptionUpdate',
      })
    },
    testSocket() {
      const { patient } = this
      this.sendSocketData({
        action: 'emit',
        event: patient.patient,
        data: { welcome: 'Hellow' },
        messageType: 'emit',
      })
    },
    reset() {
      this.currentScrollPosition = 0
      this.page = 0
      this.lastLoaded = null
      this.patientDocumentsItems = []
      this.noItems = false
      this.selectedChart = '1'
    },
    fetchNextPage() {
      this.getTimelineFeed(
        this.filtersList,
        this.patient.patient,
        '2012-01-01',
        this.currentDate,
        this.page,
        this.page_length,
        true,
      )
    },
    onChecked(e, t, index) {
      this.filtersList = this.objectTabList
        .filter(item => item.isChecked)
        .map(item => item.tab)
    },
    removeFromFilters(tab) {
      const isInArray = this.filtersList.includes(tab)
      if (!isInArray) {

      } else {
        this.filtersList = this.filtersList.filter(item => item !== tab)
      }
    },
    addToFilters(tab) {
      const isInArray = this.filtersList.includes(tab)
      if (isInArray) {

      } else {
        this.filtersList.push(tab)
      }
    },
    dateChanged() {
      this.getTimelineFeed(
        this.filtersList,
        this.patient.patient,
        this.validateDate('date_from', this.date_from),
        this.validateDate('date_to', this.date_to),
        0,
        this.page_length,
      )
    },
    notInList(name) {
      if (this.patientDocumentsItems.length === 0) {
        return true
      }
      const evaluation = this.patientDocumentsItems.filter(item => item.name === name)
        .length == 0
      alert(evaluation)
      return evaluation
    },
    getTimelineFeed(
      filter,
      patient,
      date_from,
      date_to,
      start,
      page_length,
      nextPage,
    ) {
      const payLoad = {
        filter: [...filter],
        patient,
        date_from,
        date_to,
        start,
        page_length,
      }

      getTimelineFeed({ payLoad }).then(data => {
        const timelineItems = data.data
        const ownerInfo = ''

        // timelineItems.forEach((item,index) => {
        //     getOwnersName({owner: item["owner"]}).then((data) => {
        //         ownerInfo = data;
        //         item['owner'] =  ownerInfo;
        //     });

        // });

        const fetchedPage = data.page

        if (fetchedPage == this.lastLoaded && nextPage) {
          return
        }

        if (timelineItems.length > 0) {
          let combinedFeed = [...this.patientDocumentsItems, ...timelineItems]
          if (!nextPage) {
            combinedFeed = timelineItems
            this.reset()
          }
          this.patientDocumentsItems = combinedFeed
          this.page = fetchedPage + 1
          this.lastLoaded = fetchedPage
          // call endpoint to get the owners name

          this.noItems = false
        } else {
          // this.patientDocumentsItems =[];
          if (this.date_from !== '' || this.date_to !== '') {
            this.msgprint({
              title: ('Notification'),
              indicator: 'red',
              message: ('There are no timeline records for the period you picked'),
            })
          }
          this.noItems = true
        }
      })
    },
    getOwnersName(owner) {
      getOwnersName({
        owner,
      }).then(data => {
        this.ownersName = data
      })
    },
    validateDate(date_type, the_date) {
      if (date_type == 'date_from' && the_date == '') {
        var set_date = '2012-01-01'
      } else if (date_type == 'date_to' && the_date == '') {
        set_date = this.currentDate
      } else if (date_type == 'date_to') {
        set_date = the_date.concat(' 23:59:59.999')
      } else {
        set_date = the_date.concat(' 00:00:00.000')
      }
      return set_date
    },
    renderBPChart() {
      this.$refs.VitalsChart.createBPChart()
    },
    renderRespPulseChart() {
      this.$refs.VitalsChart.getRespPulseData()
    },
    renderTemperatureChart() {
      this.$refs.VitalsChart.getTemperatureData()
    },
    renderBMIChart() {
      this.$refs.VitalsChart.getBMIData()
    },
    refreshTimeline() {
      this.reset()
      this.filtersList = this.tabList
      // check all the checkboxes and dates
      this.objectTabList = this.tabList.map(tab => ({ tab, isChecked: true }))
      this.date_from = ''
      this.date_to = ''

      this.getTimelineFeed(
        this.filtersList,
        this.patient.patient,
        '2012-01-01',
        this.currentDate,
        0,
        this.page_length,
      )
    },
  },
}
</script>

<style scoped>
svg {
    width: 10px !important;
    max-width: 20px !important;
}
#crd {
    background-color: #f9fafa;
}

#crd-bdy {
    margin-bottom: -30px;
}

.timeline {
    position: relative;
    padding: 10px;
    margin: 0 auto;
    overflow: hidden;
    color: #ffffff;
}

.timeline:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0%;
    margin-left: -3px;
    border-right: 2px dashed #c4d2e2;
    height: 100%;
    display: block;
}

.timeline-row {
    padding-left: 0%;
    position: relative;
    margin-bottom: 0px;
}

.timeline-row .timeline-time {
    position: absolute;
    right: 50%;
    top: 31px;
    text-align: right;
    margin-right: 20px;
    color: #000000;
    font-size: 1.5rem;
}

.timeline-row .timeline-time small {
    display: block;
    font-size: 0.8rem;
    color: #8796af;
}

.timeline-row .timeline-content {
    position: relative;
    padding: 20px 30px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.timeline-row .timeline-content:after {
    content: "";
    position: absolute;
    top: 20px;
    height: 3px;
    width: 40px;
}

.timeline-row .timeline-content:before {
    content: "";
    position: absolute;
    top: 20px;
    right: -50px;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    z-index: 100;
    background: #f4f5f6;
    border: 2px dashed #c4d2e2;
}

.timeline-row .timeline-content h4 {
    margin: 0 0 20px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 150%;
}

.timeline-row .timeline-content p {
    margin-bottom: 30px;
    line-height: 150%;
}

.timeline-row .timeline-content i {
    font-size: 1rem;
    line-height: 100%;
    padding: 10px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    margin-bottom: 10px;
    display: inline-block;
}

.timeline-row .timeline-content .thumbs {
    margin-bottom: 10px;
}

.timeline-row .timeline-content .thumbs img {
    margin-bottom: 10px;
}

.timeline-row .timeline-content {

    margin-left: 40px;
    text-align: left;
}

.timeline-row .timeline-content:after {
    left: -39px;
    border-right: 18px solid #ffffff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}

.timeline-row .timeline-content:before {
    left: -50px;
    right: initial;
}

.timeline {
    padding: 10px 10px;
}

.timeline:after {
    left: 28px;
}

.timeline .timeline-row {
    padding-left: 0;
    margin-bottom: 0px;
}

.timeline .timeline-row .timeline-time {
    position: relative;
    right: auto;
    top: 0;
    text-align: left;
    margin: 0 0 6px 56px;
}

.timeline .timeline-row .timeline-time strong {
    display: inline-block;
    margin-right: 10px;
}

.timeline .timeline-row .timeline-icon {
    top: 52px;
    left: -2px;
    margin-left: 0;
}

.timeline .timeline-row .timeline-content {
    padding: 15px;
    margin-left: 56px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    position: relative;
}

.timeline .timeline-row .timeline-content:after {
    right: auto;
    left: -39px;
    top: 32px;
}

.timeline .timeline-row {
    padding-right: 0;
}

.timeline .timeline-row .timeline-time {
    position: relative;
    right: auto;
    left: auto;
    top: 0;
    text-align: left;
    margin: 0 0 6px 56px;
}

.timeline .timeline-row .timeline-content {
    margin-right: 0;
    margin-left: 55px;
}

.timeline .timeline-row .timeline-content:after {
    right: auto;
    left: -39px;
    top: 32px;
    border-right: 18px solid #ffffff;
    border-left: inherit;
}

.timeline.animated .timeline-row:nth-child(odd) .timeline-content {
    left: 20px;
}

.timeline.animated .timeline-row.active:nth-child(odd) .timeline-content {
    left: 0;
}

#over {
    width: 100%;
}

#over img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child),
div#driver-popover-item .driver-popover-footer .btn-group>button:not(:first-child) {
    margin-left: 0px;
}

/* .btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    border: 0.5px solid #2490ef;
    border-radius: 7px;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child), div#driver-popover-item .driver-popover-footer .btn-group > .btn-group:not(:first-child) > button, div#driver-popover-item .driver-popover-footer .btn-group > button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 0.5px solid #2490ef;
} */
</style>
