<template>
  <div>
    <!-- Add a button or trigger element for opening the modal -->
    <b-button
      variant="primary"
      size="sm"
      @click="openModal"
    ><i
      class="fa fa-plus-circle"
      aria-hidden="true"
    />
      Add</b-button>

    <b-modal
      v-model="modalVisible"
      :title="`Add ${serviceName}`"
      @ok="submitForm"
    >

      <b-form @submit.prevent="submitForm">
        <b-form-group
          :label="serviceName"
          label-for="procedure"
        >
          <DoctypeLink
            :doctype="'Clinical Procedure Template'"
            :filters="{custom_is_imaging_service:getState() }"
            class="w-100 linker"
            @selected="onProcedureSelected"
          />
        </b-form-group>

        <b-form-group
          label="Comments"
          label-for="comments"
        >
          <b-form-textarea v-model="comments" />
        </b-form-group>

      </b-form>
    </b-modal>
  </div>
</template>

<script>
import DoctypeLink from '@/views/components/doctype-link/DoctypeLink.vue'

export default {
  components: { DoctypeLink },
  props: {
    encounter: { type: Object },
    isRadiology: Boolean,
  },
  data() {
    return {
      modalVisible: false,
      serviceName: this.isRadiology ? 'Radiology' : 'Clinical Procedure',
      procedure: null,
      procedureOptions: [],
      comments: '',
    }
  },
  computed: {
    procedureValid() {
      return this.procedure !== null
    },
  },
  watch: {
    fields() {
      this.$emit('fields-change', this.fields)
    },
  },
  methods: {
    onProcedureSelected(val) {
      this.procedure = val
    },
    getState() {
      return this.isRadiology ? 1 : 0
    },
    openModal() {
      this.procedureOptions = []
      this.modalVisible = true
    },
    submitForm() {
      this.$emit('procedure', { procedure: this.procedure, comments: this.comments })
    },
  },
}
</script>
<style scoped>
svg {
    width: 14px !important;
}
</style>
