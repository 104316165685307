<template>
  <div>
    <i
    class="fa-regular fa-note-sticky"
      aria-hidden="true"
      style="color: #ad2b4a; border: 0px solid #ad2b4a"
    />
    <span> {{ recordType }} - {{ recordId }}</span>
    <span
      class="document-id"
    ><br>
      <u><b>SYMPTOMS</b></u>
      <div v-html="subjectiveNotesHtmlString" />
      <u><b> EXAMINATION</b></u>
      <div v-html="objectiveNotesHtmlString" />
      <u><b>ASSESSMENT</b></u>
      <div v-html="assessmentNotesHtmlString" />
      <u><b>PLAN</b></u>
      <div v-html="planNotesHtmlString" />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    recordItems: {
      type: Object,
      default: null,
    },
    recordId: {
      type: String,
      default: '',
      required: true,
    },
    recordType: {
      type: String,
      default: '',
      required: true,
    },
    subjectiveNotesHtmlString: {
      type: String,
      default: '',
    },
    objectiveNotesHtmlString: {
      type: String,
      default: '',
    },
    assessmentNotesHtmlString: {
      type: String,
      default: '',
    },
    planNotesHtmlString: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
