<template>
  <div class="body1">
    <b-modal
      v-model="showAdmin"
      size="xl"
      title="Administer Procedure Notes"
      hide-footer
      no-close-on-backdrop
    >
      <ProcedureAdministration :procedure-name="procedureName" />
    </b-modal>

    <b-tabs
      card
      fill
      content-class="mt-0"
    >
      <b-tab title="SCHEDULED PROCEDURES">
        <procedure-table
          action-text="Start"
          :procedures="scheduledProcedures"
          :fields="fields"
          @rescheduleClicked="info"
          @buttonClicked="startProcedure"
        />
      </b-tab>
      <b-tab title="UN-SCHEDULED PROCEDURES">
        <div style="overflow: scroll">
          <procedure-table
            class="w-100"
            action-text="Schedule"
            :procedures="unscheduledProcedures"
            :fields="unScheduledFields"
            @buttonClicked="info"
          />
        </div>
      </b-tab>
    </b-tabs>
    <b-modal
      :id="infoModal.id"
      :title="infoModal.title"
      :visible="visible"
      @ok="onSubmit"
      @hide="resetInfoModal"
    >
      <template #modal-title>
        <h3>{{ infoModal.content.procedure_template }} - {{ infoModal.content.patient_name ||
          infoModal.content.patient }}</h3>
      </template>
      <b-form-datepicker
        v-model="text"
        :min="min"
        locale="en-US"
        @context="onContext"
      />
      <div
        v-if="!!validationMessage"
        style="margin-top: 10px; font-weight: bold;"
      >Procedures with patients below have
        already been scheduled for {{ text }}</div>
      <pre v-html="validationMessage" />
      <template #modal-ok> Confirm Schedule
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import ProcedureTable from './procedureTable.vue'
import { scheduleProcedure } from '../../service'

import ProcedureAdministration from './procedureAdministration.vue'

export default {
  name: 'ProcedureList',
  components: {
    ProcedureTable,
    ProcedureAdministration,
  },
  data() {
    return {
      fields: ['patient', 'patient_name', 'procedure_template', 'status', {
        label: 'Scheduled',
        key: 'start_date',
        formatter: (value, key, item) => moment(`${item.start_date} ${item.start_time}`).fromNow(),
      }, {
        key: 'is_paid',
        label: 'Can Start',
        formatter: (value, key, item) => ((item.proceed_without_pay || item.is_paid) ? 'Yes' : 'No'),
      }, { key: 'action', label: 'Actions' }],
      unScheduledFields: ['patient', 'patient_name', 'procedure_template', 'status', 'creation', 'Theatre', { key: 'action', label: 'Actions' }],
      visible: false,
      validationMessage: '',
      min: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      text: '',
      infoModal: {
        id: 'info-modal',
        title: '',
        data: {},
        content: {},
      },
      procedureName: null,
      showAdmin: false,
    }
  },
  computed: {
    unscheduledProcedures() {
      // :disabled="!row.item.is_paid"
      // :variant="row.item.status === 'Completed' ? 'success' : row.item.status === 'Pending' ? 'danger' : 'primary'"

      return this.$store.getters['procedures/getUnscheduledProcedures']
    },
    scheduledProcedures() {
      const scheduled = this.$store.getters['procedures/getScheduledProcedures']
      const toReturn = scheduled.map(e => ({
        ...e, disabled: !e.proceed_without_pay && !e.is_paid || e.status === 'Completed', variant: e.status === 'Completed' ? 'success' : e.status === 'Pending' ? 'danger' : 'primary', buttonText: e.status === 'Completed' ? 'Completed' : undefined,
      }))
      return toReturn
    },
    queryParams() {
      return this.$store.getters['procedures/getQueryParams']
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit'].name ? this.$store.getters['serviceUnit/getSelectedServiceUnit'] : (this.serviceUnits.length && this.serviceUnits[0])
    },
  },
  methods: {
    unScheduledAction(ev) {
      console.log(ev)
    },
    info({ item: { item }, event: button }) {
      console.log(item)
      this.visible = true
      this.infoModal.title = 'Schedule Procedure'
      this.infoModal.content = item
      // this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    resetInfoModal() {
      this.visible = false
    },
    onContext(data) {
      if (data.selectedYMD) {
        scheduleProcedure({
          procedure_id: this.infoModal.content.name,
          date: data.selectedYMD,
          service_unit: this.selectedServiceUnit.service_unit,
        })
          .then(message => {
            this.validationMessage = message
          })
      }
    },
    startProcedure({ item: { item: { name } } }) {
      // console.log(this.$router)
      const { pathname } = window.location
      this.procedureName = name
      this.showAdmin = true
      // this.$router.push(pathname + '/administer/' + name)
    },
    onSubmit() {
      scheduleProcedure({
        procedure_id: this.infoModal.content.name,
        date: this.text,
        to_save: true,
        service_unit: this.selectedServiceUnit.service_unit,
        theatre_schedule: this.infoModal.content.theatre_info?.[0]?.name || 'Null',

      })
        .then(message => {
          this.visible = false
          this.$store.dispatch('procedures/fetchProcedures', { service_unit: this.selectedServiceUnit.service_unit, template: this.infoModal.content.procedure_template })
        })
    },
  },
}
</script>

<style >
.card-header {
    padding: 0px !important
}

.body1 {
    margin-top: 16px;
}</style>
