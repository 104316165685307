<template>
  <b-container>
    <b-row>
      <i
        class="fa fa-folder-open"
        aria-hidden="true"
        style="color: orange; border-radius: 100;"
      />
      <span class="mx-1"> {{ formName }}</span>
    </b-row>

    <b-row class="mt-1">
      <b-button
        size="sm"
        variant="secondary"
        @click="openModal()"
      >
      <span class="mr-1">  {{ showForm ? "Hide Form" : "View Form" }}</span>
      </b-button>
    </b-row>
    <b-modal
      :id="id"
      :title="formName"
      centered
      size="lg"
    >
      <b-row v-if="formConfig">
        <form-render-view
          :current-form-name="formName"
          :patient="patient"
          :data-input="formData"
          :parent="parent"
          :is-readonly="true"
        />
      </b-row>

      <template #modal-footer="{ ok, cancel }">
        <span />
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import FormRenderView from '@/views/components/formbuilder/Frm.vue'

export default {
  components: {
    FormRenderView,
  },
  props: {
    formConfig: { type: Object, default: null },
  },
  data() {
    return {
      formData: null,
      formName: null,
      parent: null,
      showForm: false,
      id: Math.random(),

    }
  },
  mounted() {
    let conf = this.formConfig[0].form_content
    // this.formName = this.formConfig[0].form_name.split("-")[1].toUpperCase();
    this.formName = this.formConfig[0].form_name
    this.parent = this.formConfig[0].name
    conf = JSON.parse(conf)
    this.formData = conf
  },
  methods: {
    openModal() {
      this.$bvModal.show(this.id)
    },
  },

}
</script>
<style></style>
