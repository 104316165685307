<template>
  <div>

    <!-- First Row -->
    <b-row>
      <b-col md="6">
        <b-form-group
          label-cols="12"
          label-align="right"
        >
          <b-form-label class="w-100">
            Start Date
          </b-form-label>
          <b-form-datepicker
            v-model="fields.start_date"
            class="w-100"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label-cols="12"
          label-align="right"
        >
          <b-form-label class="w-100">
            Drug
          </b-form-label>
          <DoctypeLink
            :doctype="'Item'"
            :filters="drugFilters"
            class="w-100 linker"
            @selected="onDrugSelect"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Second Row -->
    <b-row>
      <b-col md="6">
        <b-form-group
          label-cols="12"
          label-align="right"
        >
          <b-form-label class="w-100">
            Dose
          </b-form-label>
          <b-form-input
            v-model="fields.doctor_dose"
            class="w-100"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label-cols="12"
          label-align="right"
        >
          <b-form-label class="w-100">
            Route of Administration
          </b-form-label>
          <DoctypeLink
            :doctype="'Dosage Form'"
            class="w-100 linker"
            @selected="onDosageForm"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Third Row -->
    <b-row>
      <b-col md="6">
        <b-form-group
          label-cols="12"
          label-align="right"
        >
          <b-form-label class="w-100">
            Period
          </b-form-label>
          <DoctypeLink
            :doctype="'Prescription Duration'"
            class="w-100 linker"
            @selected="onDuration"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label-cols="12"
          label-align="right"
        >
          <b-form-label class="w-100">
            Frequency
          </b-form-label>
          <DoctypeLink
            :doctype="'Prescription Dosage'"
            class="w-100 linker"
            @selected="onFrequencyItem"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Fourth Row -->
    <b-row>
      <b-col md="6">
        <b-form-group
          label-cols="12"
          label-align="right"
        >
          <b-form-label class="w-100">
            Instructions
          </b-form-label>
          <b-form-textarea
            v-model="fields.instructions"
            class="w-100"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label-cols="12"
          label-align="right"
        >
          <b-form-label class="w-100">
            Is Discharge Medication
          </b-form-label>
          <b-form-checkbox
            v-model="fields.is_discharge_medication"
            class="w-100"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import DoctypeLink from '@/views/components/doctype-link/DoctypeLink.vue'
import { getDrugGroups, getDrugMappings, getMatchedItems } from './service'

export default {
  name: 'AddPrescription',
  components: { DoctypeLink },
  components: { DoctypeLink, DoctypeLink },
  data() {
    return {
      matched: [],
      itemGroups: [],
      routeOptions: [],
      durationOptions: [],
      frequencyOptions: [],
      drugFilters: { is_stock_item: 1, has_variants: 1 },
      fields: {
        start_date: '',
        drug: '',
        drug_name: '',
        doctor_dose: '',
        route_of_administration: '',
        duration: '',
        frequency: '',
        instructions: '',
        is_discharge_medication: false,
      },
    }
  },
  watch: {
    fields() {
      this.$emit('fields-change', this.fields)
    },
  },
  mounted() {
    getDrugGroups().then(data => {
      this.itemGroups = data
      // Fetch and set other options here if needed
      this.makeFields()
    })
  },
  methods: {
    async onDrugSelect(drug) {
      this.onChange('drug', drug)
      const response = await this.docGetValue('Item', drug, 'item_name')
      this.onChange('drug_name', response.message.item_name)
    },
    onFrequencyItem(frequency) {
      this.onChange('frequency', frequency)
    },
    onDosageForm(f) {
      this.onChange('route_of_administration', f)
    },
    onDuration(f) {
      this.onChange('duration', f)
    },
    onChange(field, value) {
      this.fields = { ...this.fields, [field]: value }
      if (field === 'drug') {
        getMatchedItems({
          drug: value,
          strength: undefined,
          warehouse: 'Shoe 4 Africa Pharmacy - MTRH',
          patient: '',
          filters: { item: ['!=', ''] },
        }).then(value => {
          this.matched = value
        })
        getDrugMappings({ drug: value }).then(data => {
          // Handle data if needed
        })
      }
      // ... other logic for onChange
    },
    makeFields() {
      // Set default date value
      const date = new Date()
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      this.fields.start_date = `${day}/${month}/${year}`
    },
  },
}
</script>
  <style>
.linker {
    margin-left: 0px !important;
}
</style>
