<template>
  <b-container
    class="main-container watu"
    style="border: 1px solid lightgrey; border-radius: 10px;padding-left:0px"
    fluid
  >
    <b-row>
      <b-col
        :sm="sideBarNormalSize[0]"
        :md="sideBarNormalSize[1]"
        :lg="sideBarNormalSize[2]"
        class="no-left-padding no-right-padding hidden-sm-down"
      >
        <side-bar class="side-bar" />

      </b-col>
      <b-col
        :sm="wordkingAreaNormalSize[0]"
        class="no-left-padding"
        :md="wordkingAreaNormalSize[1]"
        :lg="wordkingAreaNormalSize[2]"
      >
        <Procedures class="working-area" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import SideBar from './sidebar/Sidebar.vue'
import Procedures from './working-area/procedures/Procedures.vue'

export default {
  name: 'Main',
  components: {
    SideBar,
    Procedures,
  },
  data() {
    return {
      title: 'Records',
      selectedPatient: {},
      encounter: {},
      medicationOrder: {},
      unSubmittedProcedures: [],
      sideBarNormalSize: [12, 3, 3],
      wordkingAreaNormalSize: [12, 9, 9],
      fullScreenWorkArea: false,
      apiList: [],
      socket: null,
    }
  },
  computed: {},

  watch: {
    fullScreenWorkArea(fullWorkSpace) {
      if (fullWorkSpace) {
        this.maximize()
      } else {
        this.minimize()
      }
    },

    // $route() {
    //   frappe.router.current_route = frappe.router.parse();
    //   frappe.breadcrumbs.update();
    // },
  },
  created() {

  },

  methods: {
    toggleWorkingAreaSpace(value) {
      this.fullScreenWorkArea = value
    },
    maximize() {
      this.sideBarNormalSize = [12, 1, 1]
      this.wordkingAreaNormalSize = [12, 11, 11]
    },
    minimize() {
      this.sideBarNormalSize = [12, 3, 3]
      this.wordkingAreaNormalSize = [12, 9, 9]
    },
  },
}
</script>
  <style>

  @media only screen and (min-width: 767px) {
      .watu {
          overflow: hidden !important;

      }
  }
  .main-container {
    height: 85vh;
    overflow-x: hidden;
    /* font-family: "Nunito", sans-serif; */
    /* font-size: smaller; */
  }

  .top-menu {
    background: white;
    padding: 20px;
  }
  .no-left-padding {
    padding-left: 0px !important;
  }

  .side-bar {
    background: rgba(0, 0, 0, 0.03);
    /* box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2); */
    border-right: 1px solid lightgray;
    transition: 0.3s;
  }
  .working-area {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 80px);
    width: 100%;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: lightgray transparent;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .layout-main {
    height: calc(100vh - 60px);
  }
  .layout-main-section-wrapper {
    margin-bottom: 0px !important;
  }

  div::-webkit-scrollbar {
    width: 0px; /* width of the entire scrollbar */
  }

  div::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  div::-webkit-scrollbar-thumb {
    background-color: grey; /* color of∏ the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 0px solid orange; /* creates padding around scroll thumb */
  }
  </style>
